import {useContext, useEffect, useState} from "react";
import Menu from "../../components/Menu";
import {Fieldset} from "primereact/fieldset";
import axios from "axios";
import {UserContext} from "../../contexts/UserContext";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Checkbox} from "primereact/checkbox";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";

export default function PageManageAddOns() {

  const [rowId, setRowId] = useState(false)
  const [name, setName] = useState('')
  const [type, setType] = useState('')

  const [addons, setAddons] = useState([]);
  const [types, setTypes] = useState([]);
  const [modes, setModes] = useState([]);
  const user = useContext(UserContext)

  useEffect(() => {
    axios.get('/allyourz/manage-addons').then(res => {
      setData(res)
    })
  }, [user.language])

  const update = (id, value, type) => {
    axios.post('/allyourz/manage-addons', {id, value, type}).then(res => {
      setData(res)
    })
  }

  const setData = (res) => {
    setAddons(res.data.addons)
    setTypes(res.data.types)
    setModes(res.data.modes)
  }

  const edit = (row, type) => {
    setRowId(row.id)
    setName(row.name)
    setType(type)
  }


  if(!user.loaded) return null;

  return <>

    {/* ================== Dialog ================== */}

    <Dialog header="Wijzig naam"
            position="top"
            visible={!!rowId}
            footer={<>
              <Button
                className="p-button-warning"
                onClick={() => {
                  setName('')
                  setRowId(false)
                }}
                style={{float: 'left'}}
                label="Cancel"/>
              <Button
                label="Save"
                onClick={() => {
                  update(rowId,name,type)
                  setName('')
                  setRowId(false)
                }}
              />
            </>}
            onHide={() => {
              setName('')
              setRowId(false)
            }}>
      <div style={{width: '20rem'}}>
        <InputText style={{width: '100%'}} value={name} onChange={
          e => {
            setName(e.target.value)
          }
        }/>
      </div>
    </Dialog>

    {/* ================== The page ================== */}

    <div className="page manage-addons">
    <Menu/>
    <Fieldset legend="Add Ons">
      <div className="grid">
        <div className="col-4 text-center">
          <h3>Add On</h3>
          <DataTable value={addons} size="small" stripedRows>
            <Column field="id" header="Id"/>
            <Column
              field="name"
              header="Naam"
              body={row => {
                return <div className="link" onClick={() => {
                  edit(row, 'addons');
                }}>{row.name}</div>
              }}
            />
            <Column
              field="ay_hide"
              header="Show"
              className="text-center"
              body={row => <Checkbox
                checked={!row.ay_hide}
                onChange={e => update(row.id, e.checked, 'addons')}
              />}
            />
          </DataTable>
        </div>
        <div className="col-4 text-center">
          <h3>Type</h3>
          <DataTable value={types} size="small" stripedRows>
            <Column field="id" header="Id"/>
            <Column
              field="name"
              header="Naam"
              body={row => {
                return <div className="link" onClick={() => {
                  edit(row, 'types');
                }}>{row.name}</div>
              }}
            />
            <Column
              field="ay_hide"
              header="Show"
              className="text-center"
              body={row => <Checkbox
                disabled={!user.hasRole('sysadmin')}
                checked={!row.ay_hide}
                onChange={e => update(row.id, e.checked, 'types')}
              />}
            />
          </DataTable>

        </div>
        <div className="col-4 text-center">
          <h3>Mode</h3>
          <DataTable value={modes} size="small" stripedRows>
            <Column field="id" header="Id"/>
            <Column
              field="name"
              header="Naam"
              body={row => {
                return <div className="link" onClick={() => {
                  edit(row, 'modes');
                }}>{row.name}</div>
              }}
            />
            <Column
              field="ay_hide"
              header="Show"
              className="text-center"
              body={row => <Checkbox
                checked={!row.ay_hide}
                disabled={!user.hasRole('sysadmin')}
                onChange={e => update(row.id, e.checked,'modes')}
              />}
            />
          </DataTable>
        </div>
      </div>
    </Fieldset>
  </div></>


}
