import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {InputMask} from "primereact/inputmask";
import {Checkbox} from "primereact/checkbox";

export default function Particulier({data, setData, countries}) {
  return <div className="grid mt-1">
    <div className="col-8">
      <div>
        {/*Voor en achternaam*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Voor- en achternaam</div>
          <div className="col-8">
            <InputText
              id={'i_name'}
              value={data.i_name || ''}
              onChange={(e) => {

                setData({...data, i_name: e.target.value})
              }}
              placeholder={'Volledig wettelijke naam'}
              className="w100"/>
          </div>
        </div>
        {/*Hoofdverblijfadres*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Hoofdverblijfadres + land</div>
          <div className="col-6">
            <InputText
              id={'i_address'}
              value={data.i_address || ''}
              onChange={(e) => {

                setData({...data, i_address: e.target.value})
              }}
              placeholder={'Hoofdverblijfadres'}
              className="w100"/>
          </div>
          <div className="col-2">
            <Dropdown
              id={'i_address_country'}
              options={countries}
              value={data.i_address_country || 'NL'}
              onChange={(e) => {

                setData({...data, i_address_country: e.value})
              }}/>
          </div>
        </div>
        {/*Geboortedatum*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Geboortedatum</div>
          <div className="col-8">
            <InputMask
              id={'i_birthdate'}
              value={data.i_birthdate}
              onChange={(e) => {

                setData({...data, i_birthdate: e.value})
              }}
              mask="9999-99-99"
              placeholder="YYYY-MM-DD"/>
          </div>
        </div>
        {/*Geboortestad*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Geboorte stad + land</div>
          <div className="col-6">
            <InputText
              id={'i_birth_city'}
              value={data.i_birth_city || ''}
              onChange={(e) => {

                setData({...data, i_birth_city: e.target.value})
              }}
              placeholder={'Geboorte stad'}
              className="w100"/>
          </div>
          <div className="col-2">
            <Dropdown
              id={'i_birth_city_country'}
              options={countries}
              value={data.i_birth_city_country || 'NL'}
              onChange={(e) => {

                setData({...data, i_birth_city_country: e.value})
              }}/>
          </div>
        </div>
        {/*Btw nummer*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Btw nummer</div>
          <div className="col-6">
            <InputText
              id={'i_btw_no'}
              disabled={!!data.i_no_bwt_no}
              value={data.i_btw_no || ''}
              onChange={(e) => {

                setData({...data, i_btw_no: e.target.value})
              }}
              placeholder={'Btw nummer indien van toepassing'}
              className="w100"/>
          </div>
          <div className="col-2 flex align-items-center">
            <label htmlFor="i_no_bwt_no" className="p-checkbox-label pointer">Geen nummer</label>&nbsp;
            <Checkbox
              style={{float: 'right'}}
              inputId="i_no_bwt_no"
              checked={!!data.i_no_bwt_no}
              onChange={e => setData({...data, i_no_bwt_no: e.checked})}/>
          </div>
        </div>
        {/*Banknummer*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Banknummer</div>
          <div className="col-8">
            <InputText
              id={'i_bank_no'}
              value={data.i_bank_no || ''}
              onChange={(e) => {

                setData({...data, i_bank_no: e.target.value})
              }}
              placeholder={'IBAN bankrekening nummer'}/>
          </div>
        </div>
        {/*Naam rekeninghouder*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Naam rekeninghouder</div>
          <div className="col-8">
            <InputText
              id={'i_bank_no_name'}
              value={data.i_bank_no_name || ''}
              onChange={(e) => {

                setData({...data, i_bank_no_name: e.target.value})
              }}
              placeholder={'Naam rekeninghouder'}/>
          </div>
        </div>
        {/*Fiscaal nummer*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Fiscaal nummer</div>
          <div className="col-6">
            <InputText
              id={'i_fiscal_no'}
              value={data.i_fiscal_no || ''}
              onChange={(e) => {

                setData({...data, i_fiscal_no: e.target.value})
              }}
              placeholder={'Fiscaal identificatienummer (BSN nummer)'}/>
          </div>
          <div className="col-2 flex align-items-center">
            <Dropdown
              id={'i_fiscal_no_country'}
              options={countries}
              value={data.i_fiscal_no_country || 'NL'}
              onChange={(e) => {

                setData({...data, i_fiscal_no_country: e.value})
              }}/>
          </div>
        </div>
        {/*Kadaster nummer*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Kadaster nummer *</div>
          <div className="col-8">
            <InputText
              id={'i_kadaster_no'}
              value={data.i_kadaster_no || ''}
              onChange={(e) => {

                setData({...data, i_kadaster_no: e.target.value})
              }}
              placeholder={'Kadaster nummer'}/>
          </div>
        </div>
        <div className="grid my-1">
          <div className="col-12">
            <Checkbox
              inputId="truth"
              checked={!!data.truth}
              onChange={e => setData({...data, truth: e.checked})}/>
            <label htmlFor="truth"
                   className="font-bold p-checkbox-label pointer ml-2 truth">Ik heb dit formulier correct en naar waarheid ingevuld</label>&nbsp;
        </div>
      </div>
    </div>
  </div>
  <div className="col-4">

  </div>
</div>
}