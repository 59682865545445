import jsonPrettyPrint from "../../services/jsonPrettyPrint";

export default function NextpaxPayload({data}){

  return <>
    <div className="grid">
      <div className="col-4">Payload</div>
      <div className="col-4">Payload Adjusted</div>
      <div className="col-4">Quote</div>
    </div>
    <div className="grid postman">
      <div className="col-4">
        <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(data.payload)}}/>
      </div>
      <div className="col-4">
        <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(data.payload_adjusted)}}/>
      </div>
      <div className="col-4">
        <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(data.payload_quote)}}/>
      </div>
    </div>
  </>

}
