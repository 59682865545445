import Menu from "../../components/Menu";
import {Fieldset} from "primereact/fieldset";
import {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import axios from "axios";
import {Column} from "primereact/column";
import {TabView, TabPanel} from 'primereact/tabview';

export default function PageSchedules() {
  const [data, setData] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0);
  const [schedules, setSchedules] = useState([])
  const [poll, setPoll] = useState(0)

  const [lazy, setLazy] = useState({
    first: 0,
    rows: 15,
    page: 1,
    sortField: ['bookable_name'],
    sortOrder: [-1]
  });

  const rowClicked = (data) => {
  }

  useEffect(() => {
    const handle = setInterval(() => {
      setPoll(poll + 1)
    }, 1000 * 60);
    axios.get('/allyourz/schedules-schedules').then(res => {
      setSchedules(res.data)
    })
    return () => clearInterval(handle);
  }, [poll]);

  useEffect(() => {
    const loadLazyLogData = () => {
      const myLazy = Object.assign({}, lazy)

      delete myLazy.filters
      delete myLazy.sortField
      delete myLazy.sortOrder

      axios.post('/allyourz/schedules-logs', {lazy: myLazy}).then(res => {
        setData(res.data.data)
        setTotalRecords(res.data.totalRecords)
      })
    }

    if (activeIndex === 1) {
      loadLazyLogData()
    }
  }, [lazy, activeIndex]);


  return <div className="page schedules">
    <Menu/>
    <Fieldset legend="Schedules">

      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Schedules">
          <DataTable value={schedules}>
            <Column field={'name'} header="Name"/>
            <Column field={'cron_expression'} header="Cron"/>
            <Column field={'last_started_at'} header="Started"/>
            <Column field={'last_finished_at'} header="Finished"/>
            <Column field={'last_failed_at'} header="Failed"/>
            <Column field={'last_skipped_at'} header="Skipped"/>

          </DataTable>

        </TabPanel>
        <TabPanel header="Log Table">
          <DataTable value={data}
                     paginator
                     lazy
                     first={lazy.first}
                     rows={lazy.rows}
                     totalRecords={totalRecords}
                     dataKey="id"
                     onPage={(e) => setLazy(e)}
                     sortMode="multiple"
                     removableSort={true}

                     onSort={(e) => {
                       setLazy(e)
                     }}
                     onRowClick={e => {
                       rowClicked(e.data)
                     }}
                     rowHover={true}>
            <Column field={'id'} header="ID"/>
            <Column field={'name'} header="Name"/>
            <Column field={'type'} header="Type"/>
            <Column field={'meta'} header="Meta"/>
            <Column field={'created_at'} header="Created"/>
            <Column field={'updated_at'} header="Updated"/>

          </DataTable>
        </TabPanel>
      </TabView>
    </Fieldset>
  </div>
}