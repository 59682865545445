import {useEffect, useState} from "react";
import axios from "axios";
import getYmd from "../../../services/getYmd";
import {Checkbox} from "primereact/checkbox";
import money from "../../../services/money";
import {Button} from "primereact/button";
import {saveAs} from 'file-saver';

export default function Report({timeslot_id, selectedDates}) {

  const [allTimeslots, setAllTimeslots] = useState(false)
  const [timeslots, setTimeslots] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!selectedDates.length) return null;
    axios.get('allyourz/activity-get-detail-by-timeslot-id-and-date', {
      params: {
        timeslot_id,
        date: getYmd(selectedDates[0]),
      }
    }).then(res => {
      setTimeslots(res.data)
    })
  }, [
    timeslot_id, selectedDates
  ])

  const download = (timeslot_time) => {
    setLoading(true)
    const date = getYmd(selectedDates[0])
    axios.get(
      'allyourz/download-date-timeslot',
      {
        params: {
          date,
          timeslot_id,
          timeslot_time
        },
        responseType: "blob"
      }
    ).then(res => {
      saveAs(res.data, 'activiteit-' + date + (timeslot_time ? '-' + timeslot_time.replace(':', '-') : '') + '.xls');
      setLoading(false)
    })
  }

  const getPrice = (count, price) => {
    return count ? (count + ' x ' + money.toEuro(price)) : ''
  }

  return <div className="grid">
    <div className="col-6">
      <label htmlFor="all-timeslots" className="pointer">Alle aanvangstijden </label>
      <Checkbox inputId="all-timeslots" checked={allTimeslots} onChange={e => setAllTimeslots(e.checked)}/>
    </div>

    <div className="col-12">
      {timeslots.map((timeslot, i) => {
        if (timeslot.id === timeslot_id || allTimeslots) {

          return <div key={i} className="pointer">
            {/* HEADER (!trick to run once in here! time = e.timeslot) */}
            <div className="flex mt-2 mb-2 p-1 border-round" style={{backgroundColor: '#f4f4f4', border: 'solid 1px #c8c8c8'}}>
              <div className="w-2 font-bold">{timeslot.timeslot}</div>
              <div className="w-1 text-right pr-2">{timeslot.total_count}</div>
              <div className="w-3 text-right pr-3">{money.toEuro(timeslot.total_rate, true)}</div>

              <div className="w-2 text-center font-bold">Normaal</div>
              <div className="w-2 text-center font-bold">Middel</div>
              <div className="w-2 text-center font-bold">Laag</div>
            </div>
            {/*NAME*/}
            <div className="border-1 border-round border-gray-200">
              {timeslot.reservations.map((res, j) => {
                return <div key={j} className="flex">
                  <div className="w-6 p-1">{res.first_name} {res.last_name}</div>
                  <div className="w-2 p-1 text-center">{getPrice(res.count, res.rate)}</div>
                  <div className="w-2 p-1 text-center">{getPrice(res.count_medium, res.rate_medium)}</div>
                  <div className="w-2 p-1 text-center">{getPrice(res.count_low, res.rate_low)}</div>
                </div>
              })}
              {!allTimeslots &&
                <Button className="ml-1 mb-1"
                        label={"Download " + timeslot.timeslot}
                        onClick={() => download(timeslot.timeslot)}/>}
            </div>
          </div>
        } else {
          return null
        }
      })}

      {allTimeslots && timeslots.length ? <div className="border-1 border-round border-gray-200 mt-2 mb-2 p-1">
          <Button label="Download" loading={loading} onClick={() => download()}/>
        </div> :
        <div>@todo</div>
      }

    </div>

  </div>
}
