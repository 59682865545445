import { InputTextarea } from "primereact/inputtextarea";
import { useState, useContext, useEffect } from "react";
import { FileUpload } from "primereact/fileupload";
import { TabView, TabPanel } from "primereact/tabview";
import { UserContext } from "../../contexts/UserContext";
import axios from "axios";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

const Menu = ({ administration_id, data, setData, setDirty }) => {
  const user = useContext(UserContext);
  const [menus, setMenus] = useState({});

  useEffect(() => {
    axios
      .get("/allyourz/get-menus?administration_id=" + administration_id)
      .then((res) => {
        setMenus(res.data);
      });
  }, [administration_id, user.language]);

  const onBeforeSend = (req) => {
    let api_token = sessionStorage.getItem("api_token");
    // Set auth!
    req.xhr.setRequestHeader("Authorization", "Bearer " + api_token);
    req.xhr.setRequestHeader("allyourz-language", user.language);
    return req;
  };

  const deleteMenu = (id) => {
    axios
      .delete(
        `/allyourz/delete-menu?administration_id=${administration_id}&document_id=${id}`
      )
      .then((res) => {
        setMenus(res.data);
      });
  };

  const myNameAndDelete = (type) => {
    const hasPdf = !!menus["menu_" + type];
    return (
      <>
        <div className="grid">
          <div className="col-10" style={{ paddingTop: "1rem" }}>
            {hasPdf ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  process.env.REACT_APP_DOCUMENTS + menus["menu_" + type].url
                }
              >
                {menus["menu_" + type].name}
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="col-2 text-right">
            {hasPdf && (
              <Button
                outlined
                rounded
                icon="pi pi-times"
                severity="danger"
                onClick={() => {
                  deleteMenu(menus["menu_" + type]?.id);
                }}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  const myFo = (type) => {
    const disabled = !!menus["menu_" + type];
    return (
      <FileUpload
        onBeforeSend={onBeforeSend}
        disabled={disabled}
        name="pdf"
        chooseLabel={
          disabled
            ? "Menukaart vervangen? Gooi eerst de oude weg."
            : "Upload (als PDF)"
        }
        mode="basic"
        accept="application/pdf"
        maxFileSize={8000000}
        onUpload={(res) => {
          setMenus(JSON.parse(res.xhr.response));
        }}
        onError={(err) => {
          console.log(err);
        }}
        url={
          process.env.REACT_APP_API +
          `/allyourz/restaurant-menu-upload-and-set-document?administration_id=${administration_id}&menu_type=${type}`
        }
      />
    );
  };

  return (
    <div className="grid">
      <div className="col-12">
        <InputText
          value={data.menu_header || ""}
          onChange={(e) => {
            setDirty(true);
            setData({...data, menu_header: e.target.value});
          }}
          placeholder="Menu header"
        />
      </div>
      <div className="col-12">
        <InputTextarea
          value={data.menu_text || ""}
          onChange={(e) => {
            setDirty(true);
            setData({...data, menu_text: e.target.value});        
          }}
          placeholder="Menu message"
          rows={5}
          cols={30}
        />
      </div>
      <div className="col-12">
        <TabView>
          <TabPanel header="Menukaart">
            {myNameAndDelete("main")}
            {myFo("main")}
          </TabPanel>
          <TabPanel header="Lunchkaart">
            {myNameAndDelete("lunch")}
            {myFo("lunch")}
          </TabPanel>
          <TabPanel header="Drank/wijnkaart">
            {myNameAndDelete("drinks")}
            {myFo("drinks")}
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default Menu;
