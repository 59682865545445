import Menu from "../../components/Menu";

import {Fieldset} from "primereact/fieldset";
import Entrepreneurs from "../../components/Entrepreneurs";


export default function PageEntrepreneurs() {

  return (
    <div className="page entrepreneurs">

      <Menu/>

      <Fieldset style={{marginTop: '-0.4rem', borderColor: 'transparent'}}
                legend={<span
                  style={{
                    backgroundColor: 'white',
                    padding: '0.4rem',
                    borderRadius: '0.25rem',
                  }}>Ondernemers</span>}>
        <Entrepreneurs/>
      </Fieldset>
    </div>
  )

}
