import {useEffect, useRef, useState} from "react";

import {Button} from "primereact/button";
import {Dialog} from 'primereact/dialog';

import {InputTextarea} from "primereact/inputtextarea";
import axios from "axios";
import InfoButton from "./InfoButton";
import getYmd from "../../services/getYmd";

/**
 * data needs the Laravel Model\Remark::getRemarksWhenAllowed(id)
 *
 * You can move in the data yourself (use the data prop) or let the component
 * do it for you (use the id prop)
 */
const Remarks = ({id, style, data, rows, onSave, readOnly, reload}) => {

  const ref = useRef()
  const [vis, setVis] = useState(false)
  const [remarks, setRemarks] = useState('')  // remarks from the data
  const [remark, setRemark] = useState('')    // the new remark (in the popup)

  const moveInThemRemarks = (data) => {
    setRemarks(data.map(r => {
      return `-- ${r.user.name} -- ${getYmd(new Date(r.created_at.replace(' ', 'T') + 'Z'), true)}\n${r.remark}\n`
    }).join("\n"))
  }

  useEffect(() => {
    if(!id) return

    if (data) {
      moveInThemRemarks(data)
    } else {
      axios.get('/allyourz/get-remarks', {params: {id}}).then(res => {
        moveInThemRemarks(res.data)
      })
    }
  }, [data, id, reload])


  const myStyle = {fontSize: '0.9rem', width: '100%', ...style}

  const addRemarkButtClicked = () => {
    if (readOnly) return
    setVis(true)
  }

  const saveClicked = () => {
    axios.post('allyourz/add-remark', {id, remark: remark}).then((res) => {
      setVis(false)
      moveInThemRemarks(res.data)
      setRemark('')
      if (onSave) {
        onSave(id)
      }
    })
  }

  return <>
    <Dialog
      header="Voeg een opmerking toe"
      visible={vis} style={{width: '25vw'}}
      //onShow={() => ref.current.target.focus()}
      onShow={() => {
        ref.current.focus();
      }}
      onHide={() => {
        setRemark('')
        setVis(false)
      }}>
      <div className="grid">
        <div className="col-12">
          <InputTextarea
            ref={ref}
            style={{width: '100%'}}
            rows={6}
            value={remark} onChange={(e) => setRemark(e.target.value)}/>
        </div>
        <div className="col-6">
          <Button
            className="p-button-warning"
            label="Cancel"
            onClick={() => {
              setRemark('')
              setVis(false)
            }}
          />
        </div>
        <div className="col-6 text-right">
          <Button
            className="p-button-success float-right"
            label="Save"
            onClick={() => {
              saveClicked()
            }}
          />
        </div>
      </div>
    </Dialog>

    <div style={{position: 'relative'}}>
      <InputTextarea
        value={remarks}
        style={myStyle}
        readOnly
        onDoubleClick={addRemarkButtClicked}
        rows={rows || 6}
      />
      {!readOnly && <InfoButton
        icon="pi pi-bolt"
        text="Klik hier of dubbel klik om een opmerking toe te voegen"
        onClick={addRemarkButtClicked}
        style={{position: 'absolute', right: '0.25rem', top: '0.25rem'}}/>}
    </div>
  </>
}

export default Remarks
