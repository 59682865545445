export default function shortenText(str, n, useWordBoundary) {
  if(!str) {
    return '';
  }
  if (str.length <= n) {
    return str;
  }
  const subString = str.substring(0, n - 1); // the original check
  return (useWordBoundary
    ? subString.substring(0, subString.lastIndexOf(' '))
    : subString) + '…';
};
