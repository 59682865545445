import Menu from "../../components/Menu";

import {Fieldset} from "primereact/fieldset";

import Bookings from "../../components/bookings/Bookings";
import {useParams} from "react-router-dom";

/**
 * The reason we have the entrepreneur_id here is because when
 * log in as entrepreneur, we have a page bookings as well.
 */
export default function PageBookings() {
  const params = useParams()
  return (
    <div className="page all-bookings">
      <Menu/>
      <Fieldset legend="Boekingen">
        <Bookings entrepreneur_id={params.entrepreneur_id}/>
      </Fieldset>
    </div>
  )

}
