import React from "react";
import {useContext} from "react";
import {Link} from "react-router-dom";
import {BreadcrumbContext} from "../contexts/BreadcrumbContext";


export default function BreadCrumbs() {

  const breadcrumbs = useContext(BreadcrumbContext)


  return <>
    <nav className="p-breadcrumb p-component" aria-label="Breadcrumb" style={{marginTop: '0.5rem'}}>
      <ul className="my-breadcrumbs">
        <li className="p-breadcrumb-home">
          <Link to="/allyourz/entrepreneurs" className="p-menuitem-link">
            <span className="p-menuitem-icon pi pi-building"></span>
          </Link>
        </li>

        {breadcrumbs.model.map((e, i) => {
          return <React.Fragment key={i}>
            <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>
            <li>
              {e.url ?
                <Link to={e.url} className="p-menuitem-link">
                  <span className="p-menuitem-text">{e.label}</span>
                </Link>
                :
                <div className="p-menuitem-link">
                  <span className="p-menuitem-text">{e.label}</span>
                </div>
              }
            </li>
          </React.Fragment>
        })}
      </ul>
    </nav>
  </>
}
