import {useEffect, useState} from "react";
import axios from "axios";
import jsonPrettyPrint from "../../services/jsonPrettyPrint";
import {Button} from "primereact/button";

export default function PostmanBookable({bookable_id}) {

  const [rates, setRates] = useState('')
  const [avail, setAvail] = useState('')
  const [fees, setFees] = useState('')
  const [load, setLoad] = useState(false)

  useEffect(() => {
    if (!load) return
    axios.get(`allyourz/bookable-postman-by-id?id=${bookable_id}`).then(res => {
      setRates(res.data.rates)
      setAvail(res.data.avail)
      setFees(res.data.fees)
      setLoad(false);
    })

  }, [load, bookable_id]);



  return <>
    <div className="grid">
      <div className="col-2">
        <Button
          label="Get Data..."
          loading={load}
          onClick={() => setLoad(true)}/>
      </div>
      <div className="col-3 text-2xl">{rates && 'Pricing'}&nbsp;</div>
      <div className="col-3 text-2xl">{avail && 'Availability'}&nbsp;</div>
      <div className="col-3 text-2xl">{fees && 'Fees'}&nbsp;</div>
    </div>

    <div className="grid postman">
      <div className="col-2">
      </div>
      <div className="col-3">
        {rates !== '' && <>
          <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(rates)}}/>
        </>}
      </div>
      <div className="col-3">
        {avail !== '' && <>
          <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(avail)}}/>
        </>}
      </div>
      <div className="col-3">
        {fees !== '' && <>
          <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(fees)}}/>
        </>}
      </div>
    </div>
  </>
}
