import {useEffect, useRef, useState} from 'react'

import {Fieldset} from "primereact/fieldset";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import axios from "axios";
import {InputText} from "primereact/inputtext";
import Menu from "../../components/Menu";
import {Toast} from "primereact/toast";
import {useNavigate} from "react-router-dom";

const defaultUser = {id: null, name: '', email: '', password: '', google2fa_secret: '', roles: ''}

export default function PageUsers({type}) {
  const nav = useNavigate()
  const toast = useRef()
  const [state, setState] = useState([])


  useEffect(() => {
    axios.get('/allyourz/user/all?type=' + type).then(res => {
      let {data} = res
      data.push(defaultUser)
      setState({data: res.data})
    })

  }, [type])


  /**
   *  Inline Editor
   */
  let originalRows = {};

  const textEditor = (options) => {
    return <InputText
      type="text"
      value={options.value}
      onChange={(e) => options.editorCallback(e.target.value)}/>;
  }

  const onRowEditComplete = (e) => {
    axios.post('/allyourz/user/save', e.newData).then(res => {
      let {data} = res
      data.push(defaultUser)
      setState({data: res.data})
      toast.current.show({
        life: 2000,
        severity: 'success',
        summary: data.name,
        detail: 'User succesvol bijgewerkt!'
      });
    }).catch(e => {
      if(e.response.data.message === '-format-') {
        nav('/-format-')
      } else {
        toast.current.show({
          sticky: true,
          closable: false,
          severity: 'error',
          summary: 'Er ging iets mis',
          detail: e.response.data.message
        });
      }
    })

  }

  const onRowEditInit = (e) => {
    originalRows[e.index] = {...state.data[e.index]};
  }

  const onRowEditCancel = (e) => {
    let products = [...state.data];
    products[e.index] = originalRows[e.index];
    delete originalRows[e.index];
    setState({data: products});
  }

  return (
    <><Toast ref={toast} position="top-left"/>
      <div className="page users">
        <Menu/>
        <Fieldset legend={type === 'entrepreneur' ? 'Ondernemers' : 'Allyourz'}>
          <p>Voor een nieuw Google 2Fa token maak je het 'Google 2Fa' leeg. De gebruiker krijgt dan een nieuwe QR code te zien.</p>
          <DataTable value={state.data}
                     editMode="row"
                     dataKey="id"
                     rows={10}
                     sortMode="multiple"
                     removableSort
                     filterDisplay="row"
                     paginator={true}
                     onRowEditInit={onRowEditInit}
                     onRowEditCancel={onRowEditCancel}
                     onRowEditComplete={onRowEditComplete}
                     style={{marginTop: '5px'}}>
            {/*=======================================================*/}
            <Column field="id" header="ID" style={{width: '5%'}}/>
            <Column field="name" header="Naam" sortable filter filterMatchMode={"contains"} style={{width: '25%'}} editor={(options) => textEditor(options)}/>
            <Column field="email" header="Email" sortable filter filterMatchMode={"contains"} style={{width: '25%'}} editor={(options) => textEditor(options)}/>
            <Column field="google2fa_secret" header="Google 2Fa" style={{width: '15%'}} editor={(options) => textEditor(options)}/>
            <Column field="roles" header="Rollen" editor={(options) => textEditor(options)}/>
            <Column rowEditor headerStyle={{width: '7rem'}} bodyStyle={{textAlign: 'center'}}/>
          </DataTable>
        </Fieldset>
      </div>
    </>
  )


}
