import {useEffect, useRef, useState} from "react";
import axios from "axios";
import money from "../../services/money";
import {Calendar} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {SelectButton} from "primereact/selectbutton";
import {Checkbox} from "primereact/checkbox";
import {RadioButton} from "primereact/radiobutton";
import {Toast} from "primereact/toast";
import getYmd from "../../services/getYmd";
import {classNames} from "primereact/utils";
import Preview from "./Preview";
import {horizontalSelect,verticalSelect} from "../../services/dateRangeHelpers";

const defaultRange = {
  quantity: null,
  arrivalAllowed: null,
  departureAllowed: null,
  blocked: null,
  minStay: null,
  maxStay: null,
  single_rate: null,
  rate: null
}

export default function AllrezAvailability({src, bookable_id, min_persons, max_persons}) {

  // All selected dates of the datepicker
  const [selectedDates, setSelectedDates] = useState([])
  // The start of the range
  const [startRange, setStartRange] = useState('')
  // The data we will use for them update
  const [updates, setUpdates] = useState(defaultRange)

  // The data from the server
  const [dataAvailable, setDataAvailable] = useState({})

  // The UX status
  const [status, setStatus] = useState('wait_for_start_date_range')

  // The select buttons
  // eslint-disable-next-line
  const [singleOption, setSingleOption] = useState([
    {label: 'Single', value: 'single'}])
  // eslint-disable-next-line
  const [rangeOptions, setRangeOptions] = useState([
    {label: 'Van', value: 'from'},
    {label: 'Tot', value: 'to', disabled: true}
  ])
  // eslint-disable-next-line
  const [blockOptions, setBlockOptions] = useState([
    {label: 'Van', value: 'from'},
    {label: 'Tot', value: 'to', disabled: true}
  ])
  const [selectedRange, setSelectedRange] = useState(['from'])
  const [selectedBlock, setSelectedBlock] = useState([])
  const [selectedSingle, setSelectedSingle] = useState([])

  const [waiting, setWaiting] = useState(false)

  const [viewDate, setViewDate] = useState(new Date())

  const [preview, setPreview] = useState(false)

  const toast = useRef()

  /**
   * This one checks if all the values are the same of the fields in the selectedDates
   */
  useEffect(() => {
    let result = null
    selectedDates.forEach(e => {
      const data = {...dataAvailable[getYmd(e)]}
      if (!result) {
        result = data
        delete result.bookable_id
        delete result.date
      } else {
        Object.entries(result).forEach((d) => {
          if (result[d[0]] !== data[d[0]]) {
            result[d[0]] = null
          }
        })
      }
    })

    if (result) {
      if (result.arrivalAllowed !== null) {
        result.arrivalAllowed = !!result.arrivalAllowed
      }
      if (result.departureAllowed !== null) {
        result.departureAllowed = !!result.departureAllowed
      }
      if (result.blocked !== null) {
        result.blocked = !!result.blocked
      }

      result.rate = (!!result.rate === false ? '' : money.toEuro(result.rate))
      result.single_rate = (!!result.single_rate === false ? '' : money.toEuro(result.single_rate))

      setUpdates(result)
    }


  }, [selectedDates, dataAvailable])


  /**
   * Handle data
   */
  function handleData(res) {
    const dbAvail = {}
    res.data.forEach(e => {
      dbAvail[e.date] = e
    })
    setDataAvailable(dbAvail)
    initialState()
  }

  const iCalRefresh = () => {
    setWaiting(true)
    axios.get('allyourz/bookable-ical-refresh-by-id?id=' + bookable_id).then(res => {

      handleData(res)

      setWaiting(false)
    })
  }

  useEffect(() => {
    axios.get('allyourz/bookable-get-availability-by-id?id=' + bookable_id).then(res => {
      handleData(res)
    })
    // eslint-disable-next-line -- should be fine I guess, although the user might click a tab
  }, [bookable_id])

  const save = () => {
    const asYmd = selectedDates.map(d => getYmd(d))

    axios.post('allyourz/bookable-update-availability', {
      bookable_id,
      selectedDates: asYmd,
      updates
    }).then(res => {
      handleData(res)
      toast.current.show({life: 1000, severity: 'success', summary: 'Kalender', detail: 'Bijgewerkt!'})
    }).catch(e => {
      toast.current.show({
        life: 5000, severity: 'error', summary: 'Onjuiste invoer',
        detail: <div dangerouslySetInnerHTML={{__html: e.response.data.map(item => item.error).join("<br/>")}}/>
      });
    })
  }


  /**
   * UX
   */
  const uxClicked = (butt, value) => {

    switch (butt) {
      case 'datepicker_change':
        switch (status) {
          case 'single_select':
            // Do nothin
            break;
          default:
        }
        break;

      case 'datepicker_select':

        /**
         * DATEPICKER SELECT
         */
        switch (status) {
          case 'wait_for_start_date_range':
            setStartRange(getYmd(value.value))
            setSelectedRange(['to'])
            doOption('rangeOptions', 0, 'disabled', true)
            doOption('blockOptions', 0, 'disabled', true)
            doOption('singleOption', 0, 'disabled', true)
            setStatus('wait_for_end_date_range')
            break;
          case 'wait_for_end_date_range':
            setSelectedRange([])
            setSelectedDates(horizontalSelect(startRange, getYmd(value.value)))
            doOption('singleOption', 0, 'disabled', false)
            setStatus('single_select')
            setSelectedSingle(['single'])
            break;
          case 'wait_for_start_date_block':
            setStartRange(getYmd(value.value))
            setSelectedBlock(['to'])
            doOption('rangeOptions', 0, 'disabled', true)
            doOption('blockOptions', 0, 'disabled', true)
            doOption('singleOption', 0, 'disabled', true)
            setStatus('wait_for_end_date_block')
            break;
          case 'wait_for_end_date_block':
            setSelectedBlock([])
            setSelectedDates(verticalSelect(startRange, getYmd(value.value), dataAvailable))
            doOption('singleOption', 0, 'disabled', false)
            setStatus('single_select')
            setSelectedSingle(['single'])
            break;
          case 'single_select':
            doOption('rangeOptions', 0, 'disabled', true)
            doOption('blockOptions', 0, 'disabled', true)
            break;
          default:
        }

        break;

      /**
       * BUTTON CLICKED
       */
      case 'range':
        setStatus('wait_for_start_date_range')
        setSelectedSingle([])
        setSelectedBlock([])
        break;
      case 'block':
        setStatus('wait_for_start_date_block')
        setSelectedSingle([])
        setSelectedRange([])
        break;
      case 'single':
        setStatus('single_select');
        setSelectedBlock([])
        setSelectedRange([])
        break;
      default:
    }
  }

  const doOption = (options, index, key, value) => {
    const clone = eval('[...' + options + ']') // eslint-disable-line
    clone[index][key] = value
    const setterName = 'set' + options.charAt(0).toUpperCase() + options.slice(1)
    eval(setterName + '(clone)') // eslint-disable-line
  }


  const initialState = () => {
    setSelectedDates([])
    doOption('rangeOptions', 0, 'disabled', false)
    doOption('blockOptions', 0, 'disabled', false)
    doOption('singleOption', 0, 'disabled', false)

    setSelectedRange(['from'])
    setSelectedBlock([])
    setSelectedSingle([])
    setStatus('wait_for_start_date_range')

    setUpdates(defaultRange)
  }

  const setNullCkecbox = (field, callback) => {
    return <Checkbox
      style={{float: 'right', marginTop: '0.25rem'}}
      checked={updates[field] !== null}
      onChange={e => {
        if (e.checked) {
          // Checking the checkbox
          // Field will be '' so updated on the server
          if (!['arrivalAllowed', 'departureAllowed', 'blocked'].includes(field)) {
            const clone = {...updates}
            clone[field] = ''
            setUpdates(clone)
          }
        } else {
          // Unchecking the checkbox set to NULL... server will skip
          const clone = {...updates}
          clone[field] = null
          setUpdates(clone)
        }
      }}
    />
  }
  const dateTemplate = (d) => {

    const day = dataAvailable[getYmd(d)];
    const q = day ? day.quantity : null
    const arr = day?.arrivalAllowed ? '↓' : null
    const dep = day?.departureAllowed ? '↑' : null

    return <div className={classNames('datefield', {blocked: day?.blocked})}>
      <div className="sm lt">{arr}{dep}</div>

      <div className={classNames('sm rt', {zero: q === 0})}>{q}</div>
      <div className="sm ml">{day?.minStay}</div>
      <div className="sm lb">{day?.maxStay}</div>
      <div className="sm rb">{day?.rate && money.toEuro(day?.rate)}</div>
      <div className="day">{d.day}</div>
    </div>

  }

  /**
   *
   * BELOW THE HTML
   *
   */

  const legendaLeft = () => {
    return <div className="col-8">
      <div className="legenda grid">
        <div className="col-1 text-right mt-2">Reeks</div>
        <div className="col-2 text-center">
          <SelectButton
            value={selectedRange}
            options={rangeOptions}
            onChange={(e) => {
              setSelectedRange(e.value)
              uxClicked('range', e.value)
            }}
            multiple
          />
        </div>

        <div className="col-1 text-right mt-2">Blok</div>
        <div className="col-2 ">
          <SelectButton
            value={selectedBlock}
            options={blockOptions}
            onChange={(e) => {
              setSelectedBlock(e.value)

              uxClicked('block', e.value)
            }}
            multiple
          />
        </div>

        <div className="col-2 text-center">
          <SelectButton
            value={selectedSingle}
            options={singleOption}
            onChange={(e) => {
              setSelectedRange(e.value)
              setSelectedSingle(['single'])
              uxClicked('single', e.value)
            }}
            multiple
          />
        </div>

        <div className="col-2">
          Dagen &nbsp;
          <InputText readOnly
                     className="w-6 text-right"
                     value={selectedDates.length}/>
          &nbsp;
        </div>
        <div className="col-1">
          <Button label="Clear"
                  className="p-button-warning"
                  onClick={() => {
                    initialState()
                  }}/>

        </div>
      </div>
    </div>
  }
  const legendaRight = () => {
    return <>
      <div className="col-2">
        {!preview && src === 'allrez' && <Button
          label="iCal refresh"
          icon={waiting ? 'pi pi-spin pi-spinner' : ''}
          disabled={waiting}
          className="p-button-success"
          onClick={() => iCalRefresh()}
          style={{float: 'left'}}
        />}
      </div>
      <div className="col-2">
        <Button
          label={preview ? 'Terug naar bewerken' : 'Preview'}
          onClick={() => setPreview(v => !v)}
          style={{float: 'right'}}
        />
      </div>
    </>
  }
  const previewOnViewDateChange = (e) => {
    setViewDate(e)
  }
  const calendar = () => {
    return <div className="col-8">
      <Calendar value={selectedDates}
                selectionMode="multiple"
                inline={true}
                viewDate={viewDate}
                onViewDateChange={e => setViewDate(e.value)}
                onSelect={d => {
                  uxClicked('datepicker_select', d)
                }}
                onChange={(e) => {
                  uxClicked('datepicker_change', e.value)
                  setSelectedDates(e.value)
                }}
                dateTemplate={dateTemplate}
                numberOfMonths={2}>
      </Calendar>
    </div>
  }
  const theform = () => {
    return <div className="col-4 manage-availability">
      <div className="grid mb-2">
        <div className="col-10 line-height-4">Geef hieronder de prijs/beschikbaarheid op</div>
        <div className="col-2 text-right line-height-4">
          Update
        </div>
      </div>
      {/*QUANTITY*/}
      <div className="grid">
        <div className="col-6 label">Beschikbaarheid</div>
        <div className="col-4">
          <InputText
            className="text-right"
            value={updates.quantity === null ? '' : updates.quantity}
            onChange={e => setUpdates({...updates, quantity: e.target.value})}
          />
        </div>
        <div className="col-2">
          {setNullCkecbox('quantity')}
        </div>
      </div>
      {/*RATE*/}
      <div className="grid">
        <div className="col-6 label">Prijs</div>
        <div className="col-4">
          <InputText
            className="text-right"
            value={updates.rate === null ? '' : updates.rate}
            onChange={e => setUpdates({...updates, rate: e.target.value})}
          />
        </div>
        {/*======================================*/}
        <div className="col-2 checkbox">
          {setNullCkecbox('rate')}
        </div>
      </div>
      {/*MINSTAY*/}
      <div className="grid">
        <div className="col-6 label">Minimaal verblijf</div>
        <div className="col-4">
          <InputText
            className="text-right"
            value={updates.minStay || ''}
            onChange={e => setUpdates({...updates, minStay: e.target.value})}
          />
        </div>
        <div className="col-2 checkbox">
          {setNullCkecbox('minStay')}
        </div>

      </div>
      {/*MAXSTAY*/}
      <div className="grid">
        <div className="col-6 label">Maximaal verblijf</div>
        <div className="col-4">
          <InputText
            className="text-right"
            value={updates.maxStay || ''}
            onChange={e => setUpdates({...updates, maxStay: e.target.value})}
          />
        </div>
        {/*======================================*/}
        <div className="col-2 checkbox">
          {setNullCkecbox('maxStay')}
        </div>

      </div>
      {/*ARRIVAL ALLOWED*/}
      <div className="grid">
        <div className="col-6 label">Mag aankomen</div>
        <div className="col-2 checkbox">
          <label htmlFor="arrivalAllowed_true">Ja</label>&nbsp;
          <RadioButton
            inputId="arrivalAllowed_true"
            name="arrivalAllowed"
            checked={updates.arrivalAllowed === true}
            onChange={() => setUpdates({...updates, arrivalAllowed: true})}
          />
        </div>
        <div className="col-2 checkbox">
          <label htmlFor="arrivalAllowed_false">Nee</label>&nbsp;
          <RadioButton
            inputId="arrivalAllowed_false"
            name="arrivalAllowed"
            checked={updates.arrivalAllowed === false}
            onChange={() => setUpdates({...updates, arrivalAllowed: false})}
          />
        </div>
        <div className="col-2 checkbox">
          {setNullCkecbox('arrivalAllowed')}
        </div>

      </div>
      {/*DEPARTURE ALLOWED*/}
      <div className="grid">
        <div className="col-6 label">Mag vertrekken</div>
        <div className="col-2 checkbox">
          <label htmlFor="departureAllowed_true">Ja</label>&nbsp;
          <RadioButton
            inputId="departureAllowed_true"
            name="departureAllowed"
            checked={updates.departureAllowed === true}
            onChange={() => setUpdates({...updates, departureAllowed: true})}
          />
        </div>
        <div className="col-2 checkbox">
          <label htmlFor="departureAllowed_false">Nee</label>&nbsp;
          <RadioButton
            inputId="departureAllowed_false"
            name="departureAllowed"
            checked={updates.departureAllowed === false}
            onChange={() => setUpdates({...updates, departureAllowed: false})}
          />
        </div>
        <div className="col-2 checkbox">
          {setNullCkecbox('departureAllowed')}
        </div>
      </div>
      {/*SAVE*/}
      <div className="grid" style={{marginTop: '2rem'}}>
        <div className="col-3 col-offset-6">
          <Button label="Save" onClick={() => save()}/>
        </div>
        <div className="col-3">
          <Button label="Clear"
                  style={{float: 'right'}}
                  className="p-button-warning"
                  onClick={() => {
                    initialState()
                  }}/>
        </div>
      </div>
    </div>
  }

  return <>
    <Toast ref={toast} position="top-left"/>
    {preview ?
      <>
        <div className="availability grid" style={{height: '3.6rem'}}>
          <div className="col-8"/>
          {legendaRight()}
        </div>
        <div className="comp availability grid">
          <Preview bookable_id={bookable_id}
                   initialViewDate={viewDate}
                   parentOnViewDateChange={previewOnViewDateChange}
                   min_persons={min_persons}
                   max_persons={max_persons}
          />
        </div>
      </> :
      <>
        <div className="availability grid">
          {legendaLeft()}
          {legendaRight()}
        </div>
        <div className="comp availability grid">
          {calendar()}
          {theform()}
        </div>
        <div className="grid">
          <div className="col-8">
            <div className="text-center font-bold" style={{width:'92.6%'}}>
              <div className="p-1">
                Via iCal worden boekingen tussen verschillende kanalen gesynchroniseerd.
              </div>
              <div
                className="p-1"
                style={{backgroundColor:'#FD7577', color:'#fff'}}>
                ! Let op: Blokkeringen moeten handmatig per kanaal worden ingesteld, deze worden niet gesynchroniseerd !
              </div>
            </div>
          </div>
        </div>
      </>}
  </>
}
