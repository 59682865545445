import xmlPrettyPrint from "../../services/xmlPrettyPrint";
import Highlight from 'react-highlight'
export default function QennerPayload({data}) {

  return <>
    <div style={{margin: '0.25rem', marginTop: '1rem'}}>Payload</div>
    <Highlight className="xml">
      {xmlPrettyPrint(data.payload)}
    </Highlight>
  </>

}
