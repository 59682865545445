import Menu from "../../components/Menu";
import {Fieldset} from "primereact/fieldset";
import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {JSONEditor} from 'vanilla-jsoneditor'
import {UserContext} from "../../contexts/UserContext";
import {Button} from "primereact/button";
import getObjectPaths from "../../services/getObjectPaths";
import mergeRecursive from "../../services/mergeRecursive";
import {confirmDialog} from 'primereact/confirmdialog';
import {Toast} from "primereact/toast";

export default function PageLocalbooker() {
  const toast = useRef(null)
  const user = useContext(UserContext)
  const refContainerReadOnly = useRef(null)
  const refContainer = useRef(null)

  const [editor, setEditor] = useState(null)
  const [editorReadOnly, setEditorReadOnly] = useState(null)

  const [data, setData] = useState(null)
  const [templateData, setTemplateData] = useState(null)

  const [compareResult, setCompareResult] = useState(false)
  const [activeEditor, setActiveEditor] = useState(null)
  const language = user.language.toUpperCase()

  /**
   * Key press
   */

  useEffect(() => {
    const keyDown = (e) => {
      if (e.ctrlKey && e.key === 's') {
        e.preventDefault()
        switch (activeEditor) {
          case 'locale':
            saveClicked();
            break;
          case 'template':
            saveTemplateClicked();
            break;
          default:
        }
      }
    }

    /**
     * Shoul be added only once
     */
    document.body.addEventListener("keydown", keyDown)
    return () => {
      document.body.removeEventListener("keydown", keyDown)
    }
    // eslint-disable-next-line
  }, [activeEditor])

  /**
   * Init editors
   */
  useEffect(() => {
    // We use user roles here... need to be there
    if (!user.loaded || editor || editorReadOnly) return

    if (refContainerReadOnly.current && refContainer.current) {
      setEditor(new JSONEditor({
        target: refContainer.current,
        props: {
          onFocus: () => {
            setActiveEditor('locale')
          }
        }
      }))
      setEditorReadOnly(new JSONEditor({
        target: refContainerReadOnly.current,
        props: {
          readOnly: !user.field('sys.lb_edit_locale_template').edit,
          onFocus: () => {
            setActiveEditor('template')
          }
        }
      }))
    }
    // eslint-disable-next-line
  }, [refContainerReadOnly, refContainer, user.loaded])

  /**
   * Load
   */
  useEffect(() => {
    if (editor && editorReadOnly) {
      axios.get('/allyourz/lb-locale', {params: {locale: 'tp'}}).then(res => {
        setTemplateData(res.data)
      })
      axios.get('/allyourz/lb-locale', {params: {locale: user.language}}).then(res => {
        setData(res.data)
      })

    }
  }, [editor, editorReadOnly, user.language])

  /**
   * When data or templateData changes (re)populate editors
   */
  useEffect(() => {
    if (data && templateData) {
      setCompareResult(compare(data, templateData, true))
      editor.updateProps({content: {json: data}})
      editorReadOnly.updateProps({content: {json: templateData}})
    }
    // eslint-disable-next-line
  }, [data, templateData])


  const saveTemplateClicked = () => {
    const json = toJson(editorReadOnly)
    if (!json) return
    axios.post('/allyourz/lb-locale', {
      json, locale: 'tp'
    }).then(res => {
      toast.current.show({
        life: 1000, severity: 'success', summary: 'Succes', detail: `Template succesvol bijgewerkt!`
      });
      setTemplateData(res.data)
    })
  }

  const saveClicked = () => {
    const json = toJson(editor)
    if (!json) return

    /**
     * Check if all keys are in sync
     */
    if (compare(json, toJson(editorReadOnly))) {
      axios.post('/allyourz/lb-locale', {
        json, locale: user.language
      }).then(res => {
        toast.current.show({
          life: 2000, severity: 'success', summary: 'Succes', detail: `Locale ${language} succesvol bijgewerkt!`
        });
        setData(res.data)
      })
    }
  }

  const compare = (leftJson, rightJson, compareOnly) => {
    let left = getObjectPaths(leftJson)
    let right = getObjectPaths(rightJson)

    const allKeysAreTheSame = left.length === right.length && left.every((x, i) => x === right[i])

    if (compareOnly) {
      return allKeysAreTheSame
    }

    if (!allKeysAreTheSame) {
      const rightHasNot = left.filter(x => !right.includes(x));
      const leftHasNot = right.filter(x => !left.includes(x));
      confirmDialog({
        header: 'Fout opgetreden!', message: <>
          De structuur van de {language} vertaling is niet (meer) gelijk aan die van de template.

          {leftHasNot.length !== 0 && <>
            <div className="text-bold mt-3">{language} mist de volgende velden.</div>
            <ul className="mt-3">{leftHasNot.map((e, i) => <li key={i}>{e}</li>)}</ul>
          </>}

          {rightHasNot.length !== 0 && <>
            <div className="text-bold mt-3">{language} heeft de volgende velden teveel.</div>
            <ul className="mt-3">{rightHasNot.map((e, i) => <li key={i}>{e}</li>)}</ul>
          </>}
        </>,
        acceptLabel: 'Ok',
        rejectLabel: 'Cancel'
      })
      return false
    }
    return true
  }

  /**
   * Convert the editor always to its JSON content
   * @param edt
   * @returns {any|null}
   */
  const toJson = (edt) => {
    const edit = edt.get()
    try {
      return edit.text ? JSON.parse(edit.text) : edit.json
    } catch (e) {
      toast.current.show({
        sticky: true, severity: 'error', summary: 'Fout opgetreden', detail: e.message
      });
      return null
    }

  }

  return (<div className="page localbooker">
    <Menu/>
    <Toast ref={toast} position="top-center"/>
    <Fieldset legend="Localbooker">

      <div className="grid">
        <div className="col-5">{user.language.toUpperCase()}</div>
        <div className="col-2"></div>
        <div className="col-5">TEMPLATE</div>
      </div>

      <div className="grid">

        {/*EDITOR*/}
        <div className="col-5">
          <div ref={refContainer}/>
        </div>

        <div className="col-2">
          {/*User butt*/}
          <Button icon="pi pi-arrow-left" onClick={() => saveClicked()} label={'Save'}/>

          {/* !!Template butts only for sysadmin!! */}
          {user.field('sys.lb_edit_locale_template').edit && <>
            <Button icon="pi pi-arrow-right" iconPos="right" style={{float: 'right'}}
                    onClick={() => saveTemplateClicked()} label="Save"/>
            <div style={{paddingTop: '100px'}}>
              <Button icon="pi pi-arrow-left"
                      className="p-button-warning"
                      disabled={compareResult}
                      iconPos="left" style={{width: '100%'}}
                      onClick={() => {
                        setData(mergeRecursive(templateData, data))
                      }}
                      label={<>
                        Werk bij vanaf template<br/>
                        <span style={{fontSize: '12px', color: '#666666'}}>Wel nog zelf op save klikken!</span>
                      </>}
              />
            </div>
          </>}

        </div>

        {/*TEMPLATE*/}
        <div ref={refContainerReadOnly} className="col-5">
          <div ref={refContainerReadOnly}/>
        </div>

      </div>
    </Fieldset>

  </div>)

}
