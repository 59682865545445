import {Button} from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";
import axios from "axios";
import { useState } from "react";
import {classNames} from "primereact/utils";

export default function TryAgainBooking({data, closeMe, onResolved, reload}) {

  const [submitting, setSubmitting] = useState(false)

  const tryBooking = () => {
    confirmDialog({
      position: 'top',
      header: 'Boeking opnieuw inschieten.',
      message: <><p>Door op 'Ja' te klikken schiet je deze boeking opnieuw in.
        <br/>
        <br/>
        Weet je dit zeker?
      </p></>,
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Nee',
      acceptLabel: 'Ja',
      accept: () => {
        setSubmitting(true)
        axios.post('/allyourz/bookings-try-again', {
          product_id: data.id
        }).then(res => {
          onResolved() // Update table (Bookings)
          reload()     // Update the BookPayload part
        }).catch(res => {
          onResolved() // Update table (Bookings)
          reload()     // Update the BookPayload part
        })
      }
    });
  }

  return <>
    <div className="grid mt-2">
      <div className="col-12">
        <p>Deze boeking is mislukt. Zie het tabje "Response".</p>
        <p><b>Nadat</b> het probleem is opgelost kan je hier proberen de boeking opnieuw in te schieten.</p>
      </div>
      <div className="col-12">
        <Button className="p-button-success"
                disabled={submitting}
                icon={classNames({'pi pi pi-spin pi-spinner': submitting})}
                onClick={() => tryBooking()}
                label="Deze Boeking opnieuw inschieten"/>
        <Button className="p-button-warning ml-4"
                onClick={closeMe}
                label="Cancel"/>

      </div>
    </div>
  </>
}
