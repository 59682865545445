import {InputMask} from "primereact/inputmask";

export default function LegendaRight({timeslot, setTimeslot, reportMode}){

  return <div className="col-2">
    <div className="legenda grid ml-1">
      <div className="col-6">
        <div className="mt-2 text-lg">Aanvang</div>
      </div>

      <div className="col-6">
        <InputMask
          disabled={reportMode}
          className="text-center"
          value={timeslot.timeslot}
          onChange={(e) => {
            setTimeslot({...timeslot, timeslot: e.value})
          }}
          mask="99:99"
        />
      </div>
    </div>
  </div>

}
