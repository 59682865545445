import {useEffect, useState} from "react";
import axios from "axios";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dialog} from "primereact/dialog";
import Highlight from "react-highlight";
import xmlPrettyPrint from "../../services/xmlPrettyPrint";
import {Checkbox} from "primereact/checkbox";


export default function CubilisReadLog({bookable_id, external_id}) {



  const [data, setData] = useState([])

  const [errors_only, setErrorsOnly] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [dialogContent, setDialogContent] = useState('')
  const [dialogHeader, setDialogHeader] = useState('')

  useEffect(() => {
    setLoading(true)
    axios.get('allyourz/cubilis-log-by-bookable-id', {
      params: {
        errors_only,
        bookable_id:bookable_id || '',
        external_id:external_id || ''
      }
    }).then(res => {
      setLoading(false);
      setData(res.data)
    })
  }, [errors_only,bookable_id,external_id])

  const showXml = (row) => {

    axios.get('allyourz/cubilis-log-by-id', {
      params: {id: row.id}
    }).then(res => {
      setDialogContent(res.data.xml)
      setDialogHeader(res.data.id)
      setShowDialog(true)
    })
  }

  return <>
    <Dialog visible={showDialog}
            className="xml-dialog"
            header={dialogHeader}
            position="top"
            onHide={() => setShowDialog(false)}
            style={{width: '60%'}}>
      <Highlight className="xml">
        {xmlPrettyPrint(dialogContent)}
      </Highlight>
    </Dialog>

    <div className="grid">
      {/*SHOW ERRORS*/}
      <div className="col-4">
        <label htmlFor="show_errors" className="pointer">
          Toon enkel fouten: <Checkbox
          inputId="show_errors"
          checked={errors_only}
          onChange={(e) => {setErrorsOnly(e.checked)}}
        />
        </label>
      </div>

      {/*THE DATA*/}
      <div className="col-12">
        <DataTable value={data}
                   loading={loading}
                   paginator={true}
                   onRowClick={row => {
                     showXml(row.data)
                   }}
                   rows={10}
                   rowHover={true}>
          <Column className="pointer" header="ID" field="id"/>
          <Column className="pointer" header="Type" field="name"/>
          <Column className="pointer" header="XML request" body={() => 'Click me!'}/>
          <Column className="pointer" header="Status"
                  field="error"
                  body={(row) => {
                    return row.error === 1 ? <span style={{color:'red'}}>Fout opgetreden!</span> : <span style={{color:'darkgreen'}}>OK</span>
                  }}
          />
          <Column className="pointer" header="UTC Timestamp" field="created_at"/>
        </DataTable>
      </div>
    </div>
  </>


}