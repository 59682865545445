import {useContext, useEffect, useRef, useState} from "react";
import {UserContext} from "../../contexts/UserContext";

import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";
import {Checkbox} from "primereact/checkbox";
import {Toast} from "primereact/toast";
import {classNames} from "primereact/utils";
import Remarks from "../atoms/Remarks";
import {InputMask} from "primereact/inputmask";
import InfoButton from "../atoms/InfoButton";
import {Divider, InputNumber} from "primereact";
import {Dropdown} from "primereact/dropdown";


export default function Data({activityData, reload, save}) {
  const user = useContext(UserContext)
  const [data, setData] = useState({})
  const [saving, setSaving] = useState(false)
  const [tree, setTree] = useState([])
  const toast = useRef()

  useEffect(() => {
    setSaving(false)
    setData(activityData.ac)
    setTree(activityData.tree)
  }, [activityData])

  return <>
    <Toast ref={toast} position="top-left"/>
    <div className="grid">
      <div className="col-2 fieldname">Naam</div>
      <div className="col-10">
        <InputText value={data.name || ''} onChange={(e) => setData({...data, name: e.target.value})}/>
      </div>
      <div className="col-2 fieldname">
        <div>
          Omschrijving
        </div>
        <div style={{paddingTop: '1rem'}}>
          <Button label="Save"
                  disabled={saving}
                  icon={classNames({'pi pi pi-spin pi-spinner': saving})}
                  onClick={() => {
                    setSaving(true)
                    save(data, () => {
                      setSaving(false)
                    })
                  }}
          />
        </div>
      </div>
      <div className="col-10">
        <InputTextarea value={data.description || ''}
                       onChange={(e) => setData({...data, description: e.target.value})}
                       rows={6}
                       autoResize/>
      </div>
    </div>
    {user.field('book.is_active').view &&
      <div className="grid">
        <div className="col-10 col-offset-2">
          <Checkbox inputId="cb1"
                    onChange={(e) => setData({...data, is_active: e.checked})}
                    checked={!!data.is_active}></Checkbox>
          <label style={{marginLeft: '0.5rem'}} htmlFor="cb1" className="p-checkbox-label">Is Active</label>
        </div>
      </div>}
    {user.field('book.is_checked').view &&
      <div className="grid">
        <div className="col-10 col-offset-2">
          <Checkbox inputId="cb2"
                    onChange={(e) => setData({...data, is_checked: e.checked})}
                    checked={!!data.is_checked}></Checkbox>
          <label style={{marginLeft: '0.5rem'}} htmlFor="cb2" className="p-checkbox-label">Is Checked</label>
        </div>
      </div>}
    {user.field('book.is_published').view &&
      <div className="grid">
        <div className="col-10 col-offset-2">
          <Checkbox inputId="cb3"
                    onChange={(e) => setData({...data, is_published: e.checked})}
                    checked={!!data.is_published}></Checkbox>
          <label style={{marginLeft: '0.5rem'}} htmlFor="cb3" className="p-checkbox-label">Is Published</label>
        </div>
      </div>}
    <div className="grid">
      <div className="col-2 fieldname">One-liner</div>
      <div className="col-10">
        <InputText value={data.oneliner || ''} onChange={(e) => setData({...data, oneliner: e.target.value})}/>
      </div>
    </div>
    <div className="grid">
      <div className="col-2 fieldname">Locatie</div>
      <div className="col-6">
        <InputText value={data.location || ''} onChange={(e) => setData({...data, location: e.target.value})} />
      </div>
    </div>
    <div className="grid">
      <div className="col-2 fieldname">Lat / Long</div>
      <div className="col-2">
        <InputNumber value={data.lat || undefined} onChange={e => setData({...data, lat: e.value})}
                     maxFractionDigits={5}
                     placeholder="Lat" />
      </div>
      <div className="col-2 fieldname text-center">/</div>
      <div className="col-2">
        <InputNumber value={data.lon || undefined} onChange={e => setData({...data, lon: e.value})}
                     maxFractionDigits={5}
                     placeholder="Long" />
      </div>
      <div className="col-1">
        <Button className="p-button-rounded p-button-secondary"
                tooltip="Wanneer lat & long zijn ingevuld, zal de locatie klikbaar zijn en een pin op de kaart tonen"
                tooltipOptions={{position: 'top'}}
                icon="pi pi-info"
                style={{width: '1.5rem', height: '1.5rem'}}
        />
      </div>
    </div>
    <div className="grid">
      <div className="col-2 fieldname">Min Aantal Deelnemers</div>
      <div className="col-2">
        <InputText value={data.min_persons || ''} onChange={(e) => setData({...data, min_persons: e.target.value})}/>
      </div>
      <div className="col-2 fieldname text-right">Max Aantal Deelnemers</div>
      <div className="col-2">
        <InputText value={data.max_persons || ''} onChange={(e) => setData({...data, max_persons: e.target.value})}/>
      </div>
      <div className="col-2 fieldname text-right">Duur Van De Activiteit</div>
      <div className="col-2">
        <InputMask value={data.duration || ''}
                   className="text-center"
                   mask="99:99"
                   onChange={(e) => setData({...data, duration: e.target.value})}/>
      </div>
    </div>

    <div className="grid">
      <div className="col-2 fieldname">
        Omschrijving Prijs
        <InfoButton text="De omschrijving van de prijs, kan zijn kinderen, senioren e.t.c. Laat leeg om niet te tonen."
                    style={{float: 'right', marginTop: '-0.2rem'}}/>
      </div>
      <div className="col-2">
        <InputText placeholder="Standaard prijs"
                   value={data.name_price || ''}
                   onChange={(e) => setData({...data, name_price: e.target.value})}/>
      </div>
      <div className="col-2 fieldname text-right">Omschrijving Prijs Laag</div>
      <div className="col-2">
        <InputText value={data.name_price_low || ''}
                   onChange={(e) => setData({...data, name_price_low: e.target.value})}/>
      </div>
      <div className="col-2 fieldname text-right">Omschrijving Prijs Middel</div>
      <div className="col-2">
        <InputText value={data.name_price_medium || ''}
                   onChange={(e) => setData({...data, name_price_medium: e.target.value})}/>
      </div>
    </div>
    <div className="grid">
      <div className="col-2 fieldname">Categorie</div>
      <div className="col-10">
        <Dropdown
          value={data.category_id}
          options={tree}
          onChange={(e) => setData({...data, category_id: e.target.value})}
          optionLabel="label"
          optionGroupLabel="label"
          optionGroupChildren="items"
          optionGroupTemplate={(option) => option.label}
          style={{width: '100%'}}
          placeholder="Selecteer een activiteit"/>
      </div>
    </div>

    <div className="grid">
      <div className="col-2 fieldname">Notitie(s)
        <InfoButton text="Notities hier zijn voor intern gebruik en niet zichtbaar op allyourz."
                    style={{float: 'right', marginTop: '-0.2rem'}}/>

      </div>
      <div className="col-10">
        <InputTextarea value={data.note || ''}
                       onChange={(e) => setData({...data, note: e.target.value})}
                       rows={6}
                       autoResize/>
      </div>
    </div>
    <Divider className="mb-4"/>
    {user.field('sys.remark').view &&
      <div className="grid">
        <div className="col-2 fieldname">Opmerkingen</div>
        <div className="col-10">
          <Remarks
            data={data.remarks}
            id={data.id}
            onSave={() => reload()}
          />
        </div>
      </div>}

  </>

}
