import Menu from "../../components/Menu";

import {Fieldset} from "primereact/fieldset";

import Registrations from "../../components/registrations/Registrations";

export default function PageAanmeldingen() {

  return (
    <div className="page aanmeldingen">

      <Menu/>

      <Fieldset legend="Alle Registrations">
        <Registrations />
      </Fieldset>
    </div>
  )

}
