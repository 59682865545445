import {BrowserRouter, Route, Routes} from "react-router-dom";
import "highlight.js/styles/vs2015.css"
import "./theme/allyourz-theme.css" // is this one but colors changed: import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"
import "./scss/index.scss"

import {UserContextProvider} from "./contexts/UserContext";
import {CosmeticContextProvider} from "./contexts/CosmeticContext";

import PageTest from "./pages/PageTest";
import PageLogin from "./pages/PageLogin";
import PageGuests from "./pages/backend/PageGuests";
import PageUsers from "./pages/backend/PageUsers";
import PageAdministrationTypes from "./pages/backend/PageAdministrationTypes";
import PageAdministrationType from "./pages/backend/PageAdministrationType";
import PageFacilityOrder from "./pages/backend/PageFacilityOrder";
import PageEntrepreneur from "./pages/backend/PageEntrepreneur";
import PageEntrepreneurs from "./pages/backend/PageEntrepreneurs";
import PageBookings from "./pages/backend/PageBookings";
import PageAanmeldingen from "./pages/backend/PageAanmeldingen";
import PageReviews from "./pages/backend/PageReviews";
import PageBookablesAll from "./pages/backend/PageBookablesAll";
import PageUser from "./pages/backend/PageUser";
import PageForgotPassword from "./pages/PageForgotPassword";
import PageResetPassword from "./pages/PageResetPassword";
import PageReports from "./pages/backend/PageReports";
import PageLocalbooker from "./pages/backend/PageLocalbooker";
import {ConfirmDialog} from "primereact/confirmdialog";
import {BreadcrumbContextProvider} from "./contexts/BreadcrumbContext";
import PageManageAddOns from "./pages/backend/PageManageAddOns";
import PageBookable from "./pages/backend/pageBookable";
import PageFormat from "./pages/backend/PageFormat";

import PageDAC7 from "./pages/backend/PageDAC7";

import PageManageQennerAddons from "./pages/backend/PageManageQennerAddons";
import PageSchedules from "./pages/backend/PageSchedules";


const api_token = sessionStorage.getItem('api_token');

const first = document.location.pathname.split('/')[1];

const allowed = ['', 'forgot-password', 'reset-password']
if (!api_token && !allowed.includes(first)) {
  document.location.href = '/'
}

function App() {

  if (!api_token && !allowed.includes(first)) return null
  return (
    <div className="App">

      <BrowserRouter>
        <CosmeticContextProvider>
          <UserContextProvider>
            <BreadcrumbContextProvider>
              <ConfirmDialog/>
              <Routes>
                <Route key="000" exact path="/test" element={<PageTest/>}/>
                <Route key="000" exact path="/-format-" element={<PageFormat/>}/>
                <Route key="010" exact path="/" element={<PageLogin/>}/>
                <Route key="009" exact path="/forgot-password" element={<PageForgotPassword/>}/>
                <Route key="011" exact path="/forgot-password/:email" element={<PageForgotPassword/>}/>
                <Route key="012" exact path="/reset-password" element={<PageResetPassword/>}/>
                <Route key="020" exact path="/users/allyourz" element={<PageUsers type={'allyourz'}/>}/>
                <Route key="021" exact path="/users/entrepreneurs" element={<PageUsers type={'entrepreneur'}/>}/>
                <Route key="022" exact path="/allyourz/user" element={<PageUser/>}/>
                <Route key="030" exact path="/allyourz/facility-order" element={<PageFacilityOrder/>}/>
                <Route key="040" exact path="/allyourz/administrations" element={<PageAdministrationTypes/>}/>
                <Route key="041" exact path="/allyourz/administrations/:entrepreneur_id"
                       element={<PageAdministrationTypes/>}/>
                <Route key="050" exact path="/allyourz/administration/:id" element={<PageAdministrationType/>}/>
                <Route key="090" exact path="/allyourz/entrepreneurs" element={<PageEntrepreneurs/>}/>
                <Route key="100" exact path="/allyourz/entrepreneur/:entrepreneur_id" element={<PageEntrepreneur/>}/>
                <Route key="110" exact path="/allyourz/boekingen" element={<PageBookings/>}/>
                <Route key="111" exact path="/allyourz/boekingen/:entrepreneur_id" element={<PageBookings/>}/>
                <Route key="110" exact path="/allyourz/reviews" element={<PageReviews/>}/>
                <Route key="110" exact path="/allyourz/reviews:entrepreneur_id" element={<pageReviews/>}/>
                <Route key="120" exact path="/allyourz/aanmeldingen" element={<PageAanmeldingen/>}/>
                <Route key="130" exact path="/allyourz/bookables" element={<PageBookablesAll/>}/>
                <Route key="130" exact path="/allyourz/bookable/:bookable_id/:src" element={<PageBookable/>}/>
                <Route key="140" exact path="/allyourz/bookables/:entrepreneur_id" element={<PageBookablesAll/>}/>
                <Route key="160" exact path="/allyourz/reports" element={<PageReports/>}/>
                <Route key="170" exact path="/allyourz/manage-addons" element={<PageManageAddOns/>}/>
                <Route key="175" exact path="/allyourz/manage-qenner-addons" element={<PageManageQennerAddons/>}/>
                <Route key="180" exact path="/allyourz/localbooker" element={<PageLocalbooker/>}/>
                <Route key="190" exact path="/guests" element={<PageGuests/>}/>
                <Route key="200" exact path="/dac7" element={<PageDAC7/>}/>
                <Route key="210" exact path="/allyourz/schedules" element={<PageSchedules/>}/>
              </Routes>
            </BreadcrumbContextProvider>
          </UserContextProvider>
        </CosmeticContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
