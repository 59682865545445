import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {OverlayPanel} from 'primereact/overlaypanel';
import jsonPrettyPrint from "../../services/jsonPrettyPrint";

export default function OpenGDSreadLog({propertyId, accommodationId}) {
  const ref = useRef()
  const [data, setData] = useState([])
  const [json, setJson] = useState('')
  const type = propertyId ? 'property' : 'accommodation'

  /**
   * propertyId || accommodationId :]
   */
  const id = propertyId || accommodationId

  useEffect(() => {
    const keyDown = e => {
      if (e.key === 'Escape') {
        ref.current.toggle(ref.current)
      }
    }

    axios.get('allyourz/opengds-get-by-type-and-id', {params: {type, id}}).then(res => {
      setData(res.data)
    })
    document.addEventListener("keydown", keyDown);
    return () => {
      document.removeEventListener("keydown", keyDown);
    }

  }, [id,type])


  /**
   * Removes all updates except the accommodation update (only used when accommodation)
   */
  const removeUnused = (todo) => {
    const clone = JSON.parse(JSON.stringify(todo))
    todo.forEach((t, i) => {
      clone[i].accommodations = []
      todo[i].accommodations.forEach(e => {
        if (e.accom_id === parseInt(accommodationId)) {
          clone[i].accommodations.push(e)
        }
      })
    })
    return clone
  }

  return <>
    <OverlayPanel ref={ref}>
      {type === 'accommodation' && <div style={{color:"white",backgroundColor:"#333333",padding:'0.2rem',fontSize:'0.8rem'}}>
        Dit laat enkel de updates zien voor deze bookable<br/>
        en is dus niet de gehele OpenGDS response
      </div>}
      <div className="postman" style={{width: '400px', height: '400px', overflowY: 'auto'}}>
        <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(json)}}/>
      </div>
    </OverlayPanel>

    <DataTable value={data} paginator rows={15} filterDisplay="row" stripedRows rowHover>
      <Column field="id" header="ID"/>
      <Column field="is_init" filter header="Is Init"/>
      <Column field="processed" filter header="Processed"/>
      <Column field="created_at" filter filterMatchMode={"contains"} header="Created At"/>
      <Column style={{width: '50%', padding: 0}}
              body={(row) => {
                return <span style={{cursor: 'pointer', padding: '0.35rem'}} onClick={(e) => {
                  // When Entrepreneur show all, when accom just show the accom data
                  setJson(type === 'accommodation' ? removeUnused(row.data) : row.data)
                  ref.current.toggle(e)
                }}>Click Me!</span>
              }}
      />

    </DataTable>
  </>
}
