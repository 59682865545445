import xmlPrettyPrint from "../../services/xmlPrettyPrint";
import Highlight from 'react-highlight'
export default function CubilisResponse({data}) {

  return <>
  <div style={{margin: '0.25rem', marginTop: '1rem'}}>Channel Manager Response</div>
  <Highlight className="xml">
    {xmlPrettyPrint(data.payload_response)}
  </Highlight>
  </>

}
