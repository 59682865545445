
export default function getYmd(d, withHoursMins) {

  if (!d) return null

  if (d.constructor.name === 'Date') {
    const new_d = {
      year: d.getFullYear(),
      month: d.getMonth(),
      day: d.getDate(),
      H: d.getHours(),
      i: d.getMinutes(),
      s: d.getSeconds()
    }
    d = new_d
  }
  const pad = (e)=> (e < 10 ? '0' : '') + e

  const m = d.month + 1
  const ymd = d.year + '-' + pad(m) + '-' + pad(d.day)
  if (withHoursMins) {
    return ymd + ' ' + pad(d.H) + ':' + pad(d.i)
  } else {
    return ymd
  }
}
