import {useContext, useEffect, useRef, useState} from "react";
import Menu from '../../components/Menu';
import {Fieldset} from 'primereact/fieldset';
import {Button} from 'primereact/button';
import {Tree} from 'primereact/tree';
import axios from "axios";

import {UserContext} from "../../contexts/UserContext";
import {Toast} from "primereact/toast";
import {confirmDialog} from 'primereact/confirmdialog';
import {Checkbox} from "primereact/checkbox";
import {Dialog} from "primereact/dialog";

export default function PageFacilityOrder() {
  const [working, setWorking] = useState(false)
  const [pageData, setPageData] = useState([])
  const [sortedBy, setSortedBy] = useState('')
  const [priorityTree, setPriorityTree] = useState([])
  const [priorityId, setPriorityId] = useState('')
  const [currentNode, setCurrentNode] = useState({})
  const [disabledKeys, setDisabledKeys] = useState([])
  const context = useContext(UserContext)
  const toast = useRef()

  useEffect(() => {
    axios.get(`/allyourz/facility-get-by-priority-id?priority_id=${priorityId}`).then(res => {
      setPageData(res.data.data)
      setSortedBy(res.data.sorted_by)
    })
  }, [context.language, priorityId])
  useEffect(() => {
    axios.get(`/allyourz/facility-get-priority-tree`).then(res => {
      setPriorityTree(res.data)
      setDisabledKeys([...res.data.filter(e => e.key === 'page')[0].children.map(k => k.key), 'page', 'sbi', 'category'])
    })
  }, [context.language])

  /**
   * Removes current priority
   */
  const remove = () => {

    confirmDialog({
      header: 'Prioriteit verwijderen',
      position: 'top',
      rejectLabel: 'Nee',
      acceptLabel: 'Ja',
      message: <>
        Je staat op het punt de prioriteit van <strong>{getNodeByKey(sortedBy).node.data}</strong> te verwijderen.<br/><br/>
        Weet je dit zeker?
      </>,

      accept: () => {
        axios.post('/allyourz/facility-remove-priority', {priorityId}).then(res => {
          setPageData(res.data.data)
          setSortedBy(res.data.sorted_by)
          setTreeIcon(priorityId, false)
        })
      }
    });
  }

  const move = (type, index, direction, groupIndex) => {
    if (priorityId === '') {
      toast.current.show({
        position: 'top',
        severity: 'info',
        summary: 'Geen prioriteit geselecteerd',
        detail: 'Selecteer in de browser een prioriteit.'
      });
      return null;
    }

    let prio = 0, ids = [];
    switch (type) {
      case 'group':
        ids = pageData.map((e) => {
            prio += 10;
            return {
              id: e.group.id,
              priority: prio
            }
          }
        )
        break;
      case 'facility':
        ids = pageData[groupIndex].items.map((e) => {
            prio += 10;
            return {
              id: e.id,
              priority: prio
            }
          }
        )
        break;
      default:
    }

    switch (direction) {
      case 'right':
      case 'down':
        ids[index].priority += 10;
        ids[index + 1].priority -= 10;
        break;
      case 'left':
      case 'up':
        ids[index].priority -= 10;
        ids[index - 1].priority += 10;
        break;
      default:
    }
    const data = {priorityId, ids}
    if (sortedBy !== priorityId) {
      setTreeIcon(priorityId, true)
    }
    axios.post(`/allyourz/facility-move`, {data}).then(res => {
      setPageData(res.data.data)
      setSortedBy(res.data.sorted_by)
    })
  }


  const getNodeByKey = (key) => {

    let thenode, theparent
    const getNode = (root, key, parent) => {

      for (let node of root) {

        if (node.key === key) {
          thenode = node
          theparent = parent
        }
        if (node.children) {
          getNode(node.children, key, node)
        }
      }
    }
    getNode(priorityTree, key)
    return {node: thenode, parent: theparent}
  }

  /**
   * Horror to change some in the tree
   */
  const setTreeIcon = (key, fill) => {
    const getNode = (root, key) => {
      for (let node of root) {
        if (node.key === key) {
          if (fill) {
            node.icon = node.icon + '-fill'
          } else {
            node.icon = node.icon.substring(0, node.icon.length - 5)
          }
        }
        if (node.children) {
          getNode(node.children, key)
        }
      }
    }

    const clone = JSON.parse(JSON.stringify(priorityTree));
    getNode(clone, key)
    setPriorityTree(clone)
  }


  const hideOnContext = (value, category_group_id) => {
    setWorking(true)
    const hide = !value
    const data = {priorityId, category_group_id, hide}
    axios.post(`/allyourz/facility-hide-on-context`, {data}).then(res => {
      setPageData(res.data.data)
      setSortedBy(res.data.sorted_by)
      setWorking(false)
    })
  }


  return <>
    <Dialog header="Working..."
            position="top"
            style={{width: '10rem', textAlign: 'center'}}
            closable={false}
            closeOnEscape={false}
            visible={working}>
      <div style={{'fontSize': '2em'}}>
        <i className="pi pi-spin pi-spinner text-4xl"/>

      </div>

    </Dialog>

    <Toast ref={toast} position="top-left"/>
    <div className="page  facilities">
      <Menu/>
      <Fieldset legend={<>Facility prioriteiten</>}>
        <div className="grid">
          <div className="col-4"></div>
          <div className="col-7">{
            sortedBy ? <>Gesorteerd op: <strong>{currentNode.node.data}</strong></> : 'Niet gesorteerd'}
          </div>
          <div className="col-1">
            <Button
              className="removebutt p-button-warning"
              onClick={() => {
                remove()
              }}
              style={{float: 'right'}}
              title="Verwijder deze sortering..."
              disabled={sortedBy !== priorityId}
              icon="pi pi-times"/>
          </div>
        </div>
        <div className="grid">
          <div className="col-4">
            <Tree value={priorityTree}
                  selectionMode="single"
                  selectionKeys={priorityId}
                  onSelectionChange={e => {
                    // root keys should be like 01, 02 etc...
                    // value: '' For nothin
                    const key = e.value.length > 2 ? e.value : ''
                    setPriorityId(key)
                    setCurrentNode(getNodeByKey(key))
                  }}
            />
          </div>
          <div className="col-8">
            <div className="grid" style={{marginTop: '0px', padding: '10px', border: '1px #c8c8c8 solid', borderRadius: '3px'}}>
              {pageData.map((item, groupIndex) => {

                const zichtbaarChecked = !item.group.hide_on_context.includes(priorityId)

                const parentIsHidden = item.group.hide_on_context.includes(currentNode?.parent?.key)
                return <div key={groupIndex} className="col-4">
                  <div style={{
                    fontWeight: 'bold',
                    marginBottom: '0.5rem',
                    borderRadius: '3px',
                    border: '1px solid #c8c8c8',
                    backgroundColor: '#FFE682'
                  }} className="grid">

                    <div className="col-1">
                      <Button disabled={groupIndex === 0 || priorityId === ''}
                              className="groupbutt"
                              onClick={() => {
                                move('group', groupIndex, 'left', null)
                              }}
                              icon="pi pi-angle-left"/>
                    </div>
                    <div className="col-1">
                      <Button disabled={groupIndex === pageData.length - 1 || priorityId === ''}
                              className="groupbutt"
                              onClick={() => {
                                move('group', groupIndex, 'right', null)
                              }}
                              icon="pi pi-angle-right"/>
                    </div>
                    <div className="col-10">
                      <span style={{display: 'inline-block', marginTop: '0.2rem', marginLeft: '0.25rem'}}>
                        {item.group.name}
                      </span>

                      {
                        priorityId &&
                        !disabledKeys.includes(priorityId) &&
                        <Checkbox style={{float: 'right'}}
                                  tooltip={!priorityId ?
                                    null :
                                    (parentIsHidden ?
                                        `Niet Zichtbaar. Zie ${currentNode.parent.data.split('/').pop()}` :
                                        (zichtbaarChecked ? 'Zichtbaar' : 'Niet Zichtbaar')
                                    )
                                  }
                                  onChange={(e) => hideOnContext(e.checked, item.group.id)}
                                  disabled={parentIsHidden}
                                  checked={zichtbaarChecked}
                        />}
                    </div>

                  </div>
                  {item.items.map((el, facilityIndex) => {
                    return <div key={el.id}>
                      {/* THE NAME */}
                      <div style={{display: 'flex'}}>
                        <Button disabled={facilityIndex === 0 || priorityId === ''}
                                className="butt"
                                onClick={() => {
                                  move('facility', facilityIndex, 'up', groupIndex)
                                }}
                                icon="pi pi-angle-up"/>
                        <Button disabled={facilityIndex === item.items.length - 1 || priorityId === ''}
                                className="butt"
                                onClick={() => {
                                  move('facility', facilityIndex, 'down', groupIndex)
                                }}
                                icon="pi pi-angle-down"/>
                        <div className="facility-item">
                          {el.name}
                        </div>
                      </div>
                    </div>
                  })}

                </div>
              })}

            </div>

          </div>
        </div>

      </Fieldset>
    </div>
  </>
}
