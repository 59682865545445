import Menu from "../../components/Menu";
import Entrepreneur from "../../components/Entrepreneur";
import {useParams} from "react-router-dom";
import {TabPanel, TabView} from "primereact/tabview";
import {useContext, useEffect, useRef, useState} from "react";
import AdministrationTypes from "../../components/AdministrationTypes";
import PostmanEntrepreneur from "../../components/postman/PostmanEntrepreneur";
import BreadCrumbs from "../../components/BreadCrumbs";
import Bookings from "../../components/bookings/Bookings";
import {Button} from "primereact/button";
import axios from "axios";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {UserContext} from "../../contexts/UserContext";
import {Toast} from "primereact/toast";

import {BreadcrumbContext} from "../../contexts/BreadcrumbContext";
import OpenGDSreadLog from "../../components/log/OpenGDSreadLog";
import CubilisReadLog from "../../components/log/CubilisReadLog";
import Dac7form from "../../components/dac7/Dac7form";


export default function PageEntrepreneur() {
  const toast = useRef()
  const params = useParams()
  const user = useContext(UserContext)
  const breadcrumbs = useContext(BreadcrumbContext)
  const [activeIndex, setActiveIndex] = useState(0)
  const [data, setData] = useState({})

  useEffect(() => {
    axios.get('/allyourz/entrepreneur-by-id', {
      params: {entrepreneur_id: params.entrepreneur_id}
    }).then(res => {
      setData(res.data)
      // Set breadCrumbs
      breadcrumbs.setEntrepreneur(res.data.id, res.data.name)
    })
    // eslint-disable-next-line
  }, [user.language, params.entrepreneur_id])

  const save = (data) => {
    axios.put('/allyourz/entrepreneur-by-id', data
    ).then(res => {
      setData(res.data)
      toast.current.show({
        life: 2000,
        severity: 'success',
        summary: data.name,
        detail: 'is succesvol bijgewerkt!'
      });
    }).catch(e => {
      toast.current.show({
        life: 5000,
        severity: 'error',
        summary: 'Invoer fout',
        detail: e.response.data.message
      });
    })

  }

  const reload = () => {
    axios.get('/allyourz/entrepreneur-by-id', {
      params: {entrepreneur_id: params.entrepreneur_id}
    }).then(res => {
      setData(res.data)
    })
  }


  // *****************************************************************
  const [newAdmDialog, setNewAdmDialog] = useState(false)
  const [newAdmName, setNewAdmName] = useState('')
  const newEntSave = () => {
    axios.put('/allyourz/administration-new', {
        entrepreneur_id: params.entrepreneur_id,
        name: newAdmName
      }
    ).then(res => {
      setNewAdmName('')
      setNewAdmDialog(false)
    })
  }
  const newEntDialogHtml = () => {
    return <div className="grid">
      <div className="col-12">
        Naam
      </div>
      <div className="col-12">
        <InputText style={{width: '100%'}} value={newAdmName} onChange={e => setNewAdmName(e.target.value)}/>
      </div>
      <div className="col-6">
        <Button label="Cancel"
                className="p-button-warning"
                onClick={() => {
                  setNewAdmName('')
                  setNewAdmDialog(false)
                }}
        />
      </div>
      <div className="col-6">
        <Button label="OK"
                onClick={() => newEntSave()}
                style={{float: 'right'}}/>
      </div>
    </div>
  }
  // *****************************************************************

  return <>
    <Toast ref={toast} position="top-left"/>
    <Dialog header="Nieuwe Administratie"
            position="top"
            visible={newAdmDialog}
            style={{width: '30vw'}}
            onHide={() => setNewAdmDialog(false)}>{newEntDialogHtml()}
    </Dialog>

    <div className="page entrepreneur">
      <Menu/>
      <BreadCrumbs/>
      <TabView style={{marginTop: '0.5rem'}}
               activeIndex={activeIndex}
               onTabChange={(e) => {
                 setActiveIndex(e.index)
               }}>

        <TabPanel header="DAC7" disabled={!user.field('dac.*').view}>
          <Dac7form  entrepreneur_id={params.entrepreneur_id}/>
        </TabPanel>

        <TabPanel contentStyle={{padding: '0.5rem'}} header={data.name}>
          <Entrepreneur entrepreneurData={data} reload={reload} save={save}/>
        </TabPanel>

        <TabPanel contentStyle={{padding: '0.5rem'}} header={`Administraties van ${data.name}`}>
          <AdministrationTypes entrepreneur_id={params.entrepreneur_id}/>
        </TabPanel>

        {user.hasRole('admin') && <TabPanel contentStyle={{padding: '0.5rem'}} header={`Boekingen`}>
          <Bookings entrepreneur_id={params.entrepreneur_id} administration_id={null}/>
        </TabPanel>}

        {user.field('sys.call_postman').view &&
          <TabPanel contentStyle={{padding: '0.5rem'}} header={`Postman`}>
            <PostmanEntrepreneur entrepreneur_id={params.entrepreneur_id}/>
          </TabPanel>
        }
        {user.field('sys.opengds_view_log').view && data.src === 'opengds' &&
          <TabPanel contentStyle={{padding: '0.5rem'}} header="OpenGDS log">
            <OpenGDSreadLog propertyId={data.external_id} />
          </TabPanel>
        }
        {user.field('sys.cubilis_view_log').view && data.src === 'cubilis' &&
          <TabPanel contentStyle={{padding: '0.5rem'}} header="Cubilis log">
            <CubilisReadLog external_id={data.external_id} />
          </TabPanel>
        }


        {user.field('sys.add_entrepreneur').view &&
          <TabPanel
            style={{marginLeft: '4rem'}}
            headerTemplate={
              <div style={{marginTop: '0.25rem'}}>
                <Button icon="pi pi-home"
                        title="Nieuwe Administratie"
                        onClick={() => setNewAdmDialog(true)}
                        className="p-button-success"/>
              </div>
            }>
          </TabPanel>}


      </TabView>
    </div>
  </>
}
