import Menu from "../../components/Menu";
import {Fieldset} from "primereact/fieldset";
import ManageDac7 from "../../components/dac7/ManageDac7";

export default function PageDAC7() {
  return <div className="page manage-dac7">
    <Menu/>
    <Fieldset legend="DAC7 data">
      <ManageDac7 />
    </Fieldset>
  </div>
}


