/**
 *
 * @param obj
 * @returns {[string, unknown]}
 *
 * Creates an array aof strings representing the paths of of object
 * Includes array indexes
 */
export default function getObjectPaths(obj) {

  const isObject = (val) => val && typeof val === 'object'

  const addDelimiter = (a, b) => a ? `${a}.${b}` : b

  const paths = (obj = {}, head = '') => {
    return Object.entries(obj).reduce((product, [key, value]) => {
      let fullPath = addDelimiter(head, key)
      return isObject(value) ? product.concat(paths(value, fullPath)) : product.concat(fullPath)
    }, []);
  }

  return paths(obj)

}
