import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {Accordion, AccordionTab} from 'primereact/accordion';
import BookableObject from "./BookableObject";
import {UserContext} from "../../contexts/UserContext";
import {Dialog} from "primereact/dialog";

import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {BreadcrumbContext} from "../../contexts/BreadcrumbContext";
import {Checkbox} from "primereact/checkbox";



export default function BookableObjects({administration_id, administration, save_administration}) {
  const user = useContext(UserContext)
  const breadcrumbs = useContext(BreadcrumbContext)
  const [data, setData] = useState([])
  const [activeIndex, setActiveIndex] = useState(null)
  const [newBookableDialog, setNewBookableDialog] = useState(false)
  const [newBookableName, setNewBookableName] = useState('')

  const manualSort = administration.at.manual_sort

  const newEntSave = () => {
    axios.put('/allyourz/bookable-new', {
        administration_id,
        name: newBookableName
      }
    ).then(res => {
      setNewBookableName('')
      setNewBookableDialog(false)
      setData(res.data)
    })
  }

  /**
   * This one is here to remove the bookable breadcrumb when all arr closed
   * Can not do exactly this in <Bookable/> with an unmount or so
   * cuz unmount fires when the old one is fully closed
   * but the new one is loaden then already
   */
  useEffect(() => {
    if (activeIndex === null) {
      breadcrumbs.clearBookable()
    }
    // eslint-disable-next-line
  }, [activeIndex])

  const tabHeader = (up, down, name, sort, butt, act, che, pub) => {
    return <div className="tab-header">
      {(down || up) && <div className="mt up">{up}</div>}
      {down && <div className="mt down">{down}</div>}
      <div className="mt spacer"></div>
      <div className="mt name">{name}</div>
      <div className="mt sort">{sort}</div>
      <div className="mt butt">{butt}</div>
      <div className="mt act">{act}</div>
      <div className="mt che">{che}</div>
      <div className="mt pub">{pub}</div>
    </div>
  }

  const legenda = () => {
    /**
     * Some primereact classnames to mimic primereact
     */
    return <div className="legenda">
      {tabHeader(
        <div style={{minWidth:'200px', textAlign:'left', marginTop:'0.4rem'}}>
          <label htmlFor="ms_cb" style={{cursor:'pointer'}}>Manual Sort</label>&nbsp;&nbsp;
          <Checkbox inputId="ms_cb"
                    checked={!!manualSort}
                    onChange={(e) => {
                      administration.at.manual_sort = e.checked
                      save_administration(administration.at)
                    }
          }
          />
        </div>,
        null,
        null,
        null,
        user.field('sys.add_bookable').view && <Button icon="pi pi-book" title="Nieuwe Bookable" onClick={() => setNewBookableDialog(true)} className="p-button-success"/>,
        'Act.',
        'Check.',
        'Pub.'
      )}
    </div>

  }
  const bookabelsTab = (bo, i) => {
    return <AccordionTab key={bo.id} headerTemplate={
      tabHeader(
        (i !== 0 && !!manualSort) && <i className="pi pi-caret-up text-2xl" onClick={(e) => sort(e, i, 'up')} style={{color: '#aaa'}}/>,
        (i !== (data.length - 1) && !!manualSort) && <i className="pi pi-caret-down text-2xl" onClick={(e) => sort(e, i, 'down')} style={{color: '#aaa'}}/>,
        bo.name,
        null,
        null,
        getActiveNotActiveIcon(bo.is_active),
        getActiveNotActiveIcon(bo.is_checked),
        getActiveNotActiveIcon(bo.is_published)
      )
    }>
      <BookableObject id={bo.id} src={bo.src} min_persons={bo.min_persons} max_persons={bo.max_persons} administration_id={administration_id} onBookableChanged={onBookableChanged}/>
    </AccordionTab>
  }

  const newEntDialogHtml = () => {
    return <div className="grid">
      <div className="col-12">
        Naam
      </div>
      <div className="col-12">
        <InputText style={{width: '100%'}} value={newBookableName} onChange={e => setNewBookableName(e.target.value)}/>
      </div>
      <div className="col-6">
        <Button label="Cancel"
                className="p-button-warning"
                onClick={() => {
                  setNewBookableName('')
                  setNewBookableDialog(false)
                }}
        />
      </div>
      <div className="col-6">
        <Button label="OK"
                onClick={() => newEntSave()}
                style={{float: 'right'}}/>
      </div>
    </div>
  }

  const getActiveNotActiveIcon = (state) => {
    switch (state) {
      case 0:
        return <i className="pi pi-times-circle" style={{color: 'red'}}></i>
      case 1:
        return <i className="pi pi-check-circle" style={{color: 'green'}}></i>
      default:
    }
  }

  const onBookableChanged = () => {
    axios.get('/allyourz/bookable-objects-by-administration-id', {params: {administration_id}}).then(res => {
      setData(res.data)
    })
  }

  const sort = (e, index, direction) => {
    e.stopPropagation()
    axios.get('/allyourz/bookable-objects-sort', {
      params: {administration_id, index, direction}
    }).then(res => {
      setData(res.data)
    })

  }

  useEffect(() => {
    axios.get('/allyourz/bookable-objects-by-administration-id', {params: {administration_id}}).then(res => {
      setData(res.data)
    })
  }, [administration_id, user.language])

  /**
   *
   */
  return <>
    <Dialog header="Nieuwe Bookable" position="top" visible={newBookableDialog} style={{width: '30vw'}} onHide={() => setNewBookableDialog(false)}>{newEntDialogHtml()}</Dialog>
    <div className="bookable-objects" style={{position: "relative"}}>
      {legenda()}
      <Accordion activeIndex={activeIndex} onTabChange={(e) => {
        setActiveIndex(e.index)
      }}>

        {data.map((bo, i) => {
          return bookabelsTab(bo, i)
        })}
      </Accordion>
    </div>
  </>
}
