
import jsonPrettyPrint from "../../services/jsonPrettyPrint";

export default function NextpaxDecisionAndResponse({data}) {


  return <>
    <div style={{margin: '0.25rem', marginTop: '1rem'}}>Channel Manager Response</div>
    <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(data.payload_response)}}/>

    <div style={{margin: '0.25rem'}}>Decisioin</div>
      <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(data.payload_decision)}}/>
    </>
}
