import {useContext, useEffect, useRef, useState} from "react";
import {TabPanel, TabView} from "primereact/tabview";
import MediaItems from "../MediaItems";
import Facilities from "../facilities/Facilities";
import {CosmeticContext} from "../../contexts/CosmeticContext";
import AddOns from "../AddOns";
import Properties from "../Properties";
import Bookable from "./Bookable";
import PostmanBookable from "../postman/PostmanBookable";
import SiteAvailability from "../availability/SiteAvailability";
import DebugBookable from "./DebugBookable";
import AllrezAvailability from "../availability/AllrezAvailability";
import {UserContext} from "../../contexts/UserContext";
import Ical from "./Ical";
import axios from "axios";
import {BreadcrumbContext} from "../../contexts/BreadcrumbContext";
import {Toast} from "primereact/toast";
import OpenGDSreadLog from "../log/OpenGDSreadLog";
import CubilisReadLog from "../log/CubilisReadLog";
import QennerBookable from "../postman/QennerBookable";

export default function BookableObject(
  {
    id,
    administration_id,
    onBookableChanged,
    src,
    min_persons,
    max_persons,
  }) {
  const toast = useRef()
  const user = useContext(UserContext)
  const breadcrumbs = useContext(BreadcrumbContext)
  const [data, setData] = useState({})
  const cosmetic = useContext(CosmeticContext)
  const [activeIndex, setActiveIndex] = useState(null)

  useEffect(() => {
    axios.get('/allyourz/bookable-object-by-id', {params: {id}}).then(res => {
      setData(res.data)
      /**
       * Do breadcrumbs
       */
      const bo = res.data.bo
      breadcrumbs.setBookable(
        bo.entrepreneur_id, bo.entrepreneur_name,
        bo.administration_id, bo.administration_name,
        bo.name
      )

    })
    // eslint-disable-next-line
  }, [id, user.language]);


  useEffect(() => {
    setActiveIndex(cosmetic.bookableTabIndex)
  }, [cosmetic, id])

  const save = (data) => {

    axios.post('/allyourz/bookable-object-by-id', {
      data
    }).then(res => {
      setData(res.data)
      onBookableChanged()
      toast.current.show({
        life: 1000,
        severity: 'success',
        summary: data.name,
        detail: 'updated successfully!'
      });
    }).catch(e => {
      toast.current.show({
        life: 5000,
        severity: 'error',
        summary: 'An error occured',
        detail: e.response.data.message
      });
    })
  }

  const reload = () => {
    axios.get('/allyourz/bookable-object-by-id', {params: {id}}).then(res => {
      setData(res.data)
    })
  }

  if (!Object.keys(data).length) return null

  return <>
    <Toast ref={toast} position="top-left"/>
    <div className="grid">
      <div className="col-12">
        <TabView
          style={{margin: '0 0 0 0'}}
          activeIndex={activeIndex}
          onTabChange={(e) => {
            cosmetic.setBookableTabIndex(e.index)
            setActiveIndex(e.index)
          }}>

          <TabPanel header="Gegevens">
            <Bookable bookableData={data}
                      onBookableChanged={onBookableChanged}
                      src={src}
                      reload={reload}
                      save={save}/>
          </TabPanel>
          <TabPanel header="Add-Ons">
            <AddOns entity_id={id} src={src}/>
          </TabPanel>
          <TabPanel header="Media">
            <MediaItems entityId={id} administration_id={administration_id}/>
          </TabPanel>
          <TabPanel header="Eigenschappen">
            <Properties entity_id={id}/>
          </TabPanel>
          <TabPanel header="Faciliteiten">
            <Facilities entityId={id} type="bookable"/>
          </TabPanel>

          {/******************/}
          {/* Calendar stuff */}
          {/******************/}
          {['nextpax', 'cubilis', 'opengds', 'qenner'].includes(src) &&
            <TabPanel header="Kalender">
              <SiteAvailability bookable_id={id} src={src} min_persons={min_persons} max_persons={max_persons}/>
            </TabPanel>}
          {['allrez'].includes(src) &&
            <TabPanel header="Beschikbaarheid">
              <AllrezAvailability src={src} bookable_id={id} min_persons={min_persons} max_persons={max_persons}/>
            </TabPanel>
          }
          {src === 'allrez' &&
            <TabPanel header="iCal">
              <Ical id={id}/>
            </TabPanel>
          }
          {/******************/}
          {/*   Debug stuff  */}
          {/******************/}
          {src === 'nextpax' && user.field('sys.call_postman').view &&
            <TabPanel header="Postman">
              <PostmanBookable bookable_id={id}/>
            </TabPanel>
          }
          {src === 'qenner' && user.field('sys.call_postman').view &&
            <TabPanel header="Postman">
              <QennerBookable bookable_id={id}/>
            </TabPanel>
          }
          {src === 'opengds' && user.field('sys.opengds_view_log').view &&
            <TabPanel contentStyle={{padding: '0.5rem'}} header="OpenGDS log">
              <OpenGDSreadLog accommodationId={data.bo.external_id}/>
            </TabPanel>
          }
          {src === 'cubilis' && user.field('sys.cubilis_view_log').view &&
            <TabPanel contentStyle={{padding: '0.5rem'}} header="Cubilis log">
              <CubilisReadLog bookable_id={data.bo.id}/>
            </TabPanel>
          }
          {user.field('sys.show_debug').view &&
            <TabPanel header="Debug">
              <DebugBookable bookable_id={id}/>
            </TabPanel>
          }


        </TabView>
      </div>
    </div>
  </>
}
