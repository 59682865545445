import getYmd from "./getYmd";

const horizontalSelect = (startDate, endDate) => {

  if (typeof startDate === 'string') startDate = new Date(startDate)
  if (typeof endDate === 'string') endDate = new Date(endDate)

  const myRange = []

  for (let d = new Date(startDate.getTime()); d <= endDate; d.setDate(d.getDate() + 1)) {
    const newDate = new Date(d)
    myRange.push(newDate);
  }
  return myRange
}
const verticalSelect = (startDate, endDate, dataAvailable) => {
  // Convert to Date
  if (typeof startDate === 'string') startDate = new Date(startDate)
  if (typeof endDate === 'string') endDate = new Date(endDate)

  // Now convert to 1st and last of Month
  let firstDay = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
  let lastDay = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);

  // Day range
  const dayFrom = startDate.getDay()
  const dayTo = endDate.getDay()
  const range = [];
  if (dayFrom === 6 && dayTo === 0) {
    range.push(6)
    range.push(0)
  } else if (dayFrom < dayTo)
    for (let i = dayFrom; i <= dayTo; i++)
      range.push(i)
  else if (dayFrom > dayTo) {
    for (let i = dayFrom; i <= 6; i++)
      range.push(i)
    for (let i = 0; i <= dayTo; i++)
      range.push(i)
  } else range.push(dayFrom)

  const myRange = []
  let avail = null
  for (let d = new Date(firstDay.getTime()); d <= lastDay; d.setDate(d.getDate() + 1)) {
    const newDate = new Date(d)

    if (range.includes(d.getDay())) {
      const Ymd = getYmd(newDate)
      if (avail === null) {
        avail = dataAvailable[Ymd]
      } else {
        if (avail !== dataAvailable[Ymd]) {
          avail = '@dunno!'
        }
      }
      myRange.push(newDate);
    }
  }

  return myRange
}

export {horizontalSelect, verticalSelect}
