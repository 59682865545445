import {useEffect, useState} from "react";
import axios from "axios";
import jsonPrettyPrint from "../../services/jsonPrettyPrint";
import {Button} from "primereact/button";



export default function QennerBookable({bookable_id}) {

  const [rates, setRates] = useState('')
  const [fees, setFees] = useState('')
  const [basic, setBasic] = useState('')
  const [load, setLoad] = useState(false)

  useEffect(() => {
    if (!load) return
    axios.get(`allyourz/bookable-postman-by-id?id=${bookable_id}`).then(res => {
      setRates(res.data.rates.data)
      setFees(res.data.fees.data)
      setBasic(res.data.basic.data)
      setLoad(false);
    })
  }, [load, bookable_id]);

  return <>
    <div className="grid">
      <div className="col-2">
        <Button
          label="Get Data..."
          loading={load}
          onClick={() => setLoad(true)}/>
      </div>
    </div>

    <div className="grid">
      <div className="col-4 text-2xl">{rates && 'Pricing'}&nbsp;</div>
      <div className="col-4 text-2xl">{fees && 'Fees'}&nbsp;</div>
      <div className="col-4 text-2xl">{basic && 'Info'}&nbsp;</div>
    </div>

    <div className="grid postman">
      <div className="col-4">
        {rates !== '' && <>
          <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(rates)}}/>
        </>}
      </div>
      <div className="col-4">
        {fees !== '' && <>
          <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(fees)}}/>
        </>}
      </div>
      <div className="col-4">
        {basic !== '' && <>
          <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(basic)}}/>
        </>}
      </div>

    </div>
  </>
}
