import {useEffect, useState} from "react";

let dotcount= 1
export default function PageFormat() {
  const [dots, setDots] = useState('.')
  useEffect(() => {
    const i = setInterval(function () {
      setDots(new Array(dotcount++).join( '. ' ))
    }, 100)

    function go_full_screen() {
      var elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    }

    document.body.style.backgroundColor = 'black'
    document.body.style.color = '#999'
    go_full_screen()
    return () => {
      clearInterval(i)
    }
  }, []);

  return (
    <>
      <div style={{marginTop:'15%', fontSize:'1.2rem', fontFamily: 'courier'}}>
        Formatting the allyourz server, all data will be lost {dots}
      </div>
    </>
  )
}