import Menu from "../../components/Menu";

import {Fieldset} from "primereact/fieldset";

import {useParams} from "react-router-dom";
import BookableObject from "../../components/bookable/BookableObject";
import {useContext} from "react";
import {BreadcrumbContext} from "../../contexts/BreadcrumbContext";
import BreadCrumbs from "../../components/BreadCrumbs";

export default function PageBookable() {
  const bread = useContext(BreadcrumbContext)
  const params = useParams()

  return (
    <div className="page entrepreneur">

      <Menu/>
      <BreadCrumbs/>
      <Fieldset legend={`Bookable: ${bread.model.length < 2 ? '' : bread.model[2].label}`}>
        <BookableObject id={params.bookable_id} src={params.src}/>
      </Fieldset>
    </div>
  )

}
