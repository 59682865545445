import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {Fieldset} from "primereact/fieldset";
import {FileUpload} from "primereact/fileupload";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Image} from "primereact/image";
import {UserContext} from "../contexts/UserContext";
import {confirmDialog} from "primereact/confirmdialog";
import {Dialog} from "primereact/dialog";
import MediaPicker from "./MediaPicker";
import {Toast} from "primereact/toast";

export default function Quote({administration_id}) {
  const context = useContext(UserContext)
  const toast = useRef();
  const [quote, setQuote] = useState(null)
  const [ayQuote, setAyQuote] = useState(null)
  const [showImagePicker, setShowImagePicker] = useState(false)
  const [image, setImage] = useState(null)
  const [dirty, setDirty] = useState(null)
  const [showMedia, setShowMedia] = useState(null)

  useEffect(() => {
    load()
    // eslint-disable-next-line
  }, [administration_id, context.language])
  const onBeforeSend = (req) => {
    let api_token = sessionStorage.getItem('api_token');
    // Set auth!
    req.xhr.setRequestHeader('Authorization', 'Bearer ' + api_token)
    req.xhr.setRequestHeader('allyourz-language', context.language)
    return req;
  }
  const saveMediaTitleOrAlt = (id, value, field) => {
    axios.post('/allyourz/' + quote.id + '/media-by-entityid', {
      action: 'save',
      id,
      value,
      field
    }).then(res => {
      // Do not reload
    })
  }
  const save = () => {
    axios.post('/allyourz/quote-by-admin-id', {quote, ay_quote: ayQuote, administration_id}).then(res => {
      setQuote(res.data.quote)
      setAyQuote(res.data.ay_quote)
      setDirty(false)
      toast.current.show({
        life: 2000,
        severity: 'success',
        summary: 'De Quote',
        detail: 'is succesvol bijgewerkt!'
      });
    })
  }

  const removeImage = (id) => {
    confirmDialog({
      header: 'Afbeelding verwijderen',
      message: <>De afbeelding wordt enkel van de site verwijderd maar blijft in het portaal aanwezig.</>,
      acceptLabel: 'OK',
      rejectLabel: 'Cancel',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const clone = {...quote}
        clone.media_id = null
        setQuote(clone)
        axios.post('/allyourz/quote-by-admin-id', {quote: clone, administration_id}).then(res => {
          load()
        })
      }
    });
  }

  const load = () => {
    axios.get('/allyourz/quote-by-admin-id', {params: {administration_id}}).then(res => {
      setQuote(res.data.quote)
      setAyQuote(res.data.ay_quote)
      setImage(res.data.media)
      setDirty(false)
    })
  }

  const addExistingImages = (ids) => {
    const clone = {...quote}
    clone.media_id = ids[0]
    setQuote(clone)
    axios.post('/allyourz/quote-by-admin-id', {quote: clone, administration_id}).then(res => {
      load()
    })
  }

  return <>
    <Toast ref={toast} position="top-left"/>
    <Dialog
      visible={!!showMedia}
      style={{width: '80%'}}
      onHide={() => setShowMedia(false)}>
      <Image
        src={process.env.REACT_APP_MEDIA + showMedia}
        alt="Image"
        width="100%"
      />
    </Dialog>

    <Dialog header="Bestaande plaatjes"
            position={"top"}
            visible={showImagePicker}
            style={{width: '50vw'}}
            onHide={() => setShowImagePicker(false)}>
      <MediaPicker
        administration_id={administration_id}
        onSave={ids => {
          addExistingImages(ids)
          setShowImagePicker(false)
        }}/>
    </Dialog>

    <div className="field grid">
      <div className="col-2">
        <label htmlFor="person_name" className="col-fixed">Naam</label>
      </div>
      <div className="col-10">
        <InputText
          id="person_name"
          value={quote?.person_name || ''}
          onChange={e => {
            setDirty(true)
            setQuote({...quote, person_name: e.target.value})
          }}/>
      </div>
    </div>
    <div className="field grid">
      <div className="col-2">
        <label htmlFor="quote" className="col-fixed">Quote</label>
      </div>
      <div className="col-10">
        <InputTextarea
          id="quote"
          value={quote?.quote || ''} rows={6}
          onChange={e => {
            setDirty(true)
            setQuote({...quote, quote: e.target.value})
          }}/>
      </div>
    </div>
    {
      context.field('adm.ay_quote').edit &&
      <div className="field grid">
        <div className="col-2">
          <label htmlFor="quote" className="col-fixed">Allyourz Quote</label>
        </div>
        <div className="col-10">
          <InputTextarea
            id="quote"
            value={ayQuote || ''} rows={6}
            maxLength={250}
            onChange={e => {
              setDirty(true)
              setAyQuote(e.target.value)
            }}/>
        </div>
      </div>
    }
    <div className="field grid">
      <div className="col-2"/>

      <div className="col-10">
        <Button
          label="Save"

          disabled={!dirty}
          onClick={() => {
            save()
          }}
        />
      </div>
    </div>

    <div className="field grid">
      <div className="col-2">
        {image && <>
          <div onClick={() => {
            setShowMedia(`/${administration_id.toLowerCase()}/${image.id.toLowerCase()}.jpg`)
          }}>
            <Image
              src={`${process.env.REACT_APP_MEDIA}/${administration_id.toLowerCase()}/thumbs/${image.id.toLowerCase()}.jpg`}
              alt="Image"
              width="100%"
              style={{cursor: 'pointer'}}
            />
          </div>
          <InputText value={image.title || ''}
                     onChange={(e) => {
                       setImage({...image, title: e.target.value})
                     }}
                     onBlur={(e) => {
                       saveMediaTitleOrAlt(image.id, e.target.value, 'title')
                     }}
                     placeholder="Titel"/>
          <InputText value={image.description || ''}
                     onChange={(e) => {
                       setImage({...image, description: e.target.value})
                     }}
                     onBlur={(e) => {
                       saveMediaTitleOrAlt(image.id, e.target.value, 'description')
                     }}
                     style={{marginTop: '0.3rem'}}
                     placeholder="Omschrijving"/>
          <div className="grid" style={{marginTop: '-0.2rem'}}>
            <div className="col-3 right">
              <Button
                onClick={() => {
                  removeImage(image.id)
                }}
                icon="pi pi-times"
                className="p-button-text p-button-danger"/>
            </div>
          </div>
        </>}
      </div>
      <div className="col-4">
        <Fieldset legend="Kies een bestaande afbeelding">
          <Button label="Kies afbeelding(en)" onClick={() => {
            setShowImagePicker(true)
          }}/>
        </Fieldset>
      </div>

      <div className="col-4 upload">
        <Fieldset legend="Of upload eerst een nieuwe afbeelding">
          <FileUpload
            onBeforeSend={onBeforeSend}
            name="images[]"
            accept="image/x-png,image/jpeg,image/jpg"
            maxFileSize={25000000}
            auto={false}
            onUpload={(res) => {
              load()
            }}
            url={process.env.REACT_APP_API + '/allyourz/media-upload?administration_id=' + administration_id}
            multiple/>
        </Fieldset>
      </div>
    </div>
  </>
}
