import {Button} from "primereact/button";

export default function InfoButton({text, style, onClick, icon, className, size}) {

  let defaultStyle = size ? {width: size, height: size} : {width: '1.5rem', height: '1.5rem'}
  if (style) {
    defaultStyle = {...defaultStyle, ...style}
  }
  return <Button style={defaultStyle}
                 tooltipOptions={{position: "top"}}
                 tooltip={text || null}
                 onClick={onClick || null}
                 className={`p-button p-component p-button-rounded p-button-secondary p-button-icon-only ${className}`}
                 icon={icon || 'pi pi-info'}/>;

}
