import React, {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom";
const CosmeticContext = React.createContext({});

const CosmeticContextProvider = ({children}) => {

  const [bookableTabIndex, setBookableTabIndex] = useState(0)
  const [entTabIndex, setEntTabIndex] = useState(0)
  const location = useLocation();
  const [reloadMenu, setReloadMenu] = useState(null)

  // string contains
  useEffect(() => {
    document.body.className = location.pathname.indexOf('entrepreneur') !== -1 ? 'entrepreneur' : ''
  }, [location]);

  const doReloadMenu = () => {
    setReloadMenu(!reloadMenu)
  }
  return <CosmeticContext.Provider value={{

    // admin Tab index feels not natural when implemented
    bookableTabIndex,
    entTabIndex,
    reloadMenu,

    // setters
    setBookableTabIndex,
    setEntTabIndex,
    doReloadMenu  // this is a function

  }}>
    {children}
  </CosmeticContext.Provider>
}

export {CosmeticContext, CosmeticContextProvider}
