
export default function AllrezResponse({data}) {
  return <>
    <div className="grid">
      <div className="col-4">Reserverings nummer</div>
    </div>
    <div className="grid postman">
      <div className="col-4">
        {data.reservation_number}
      </div>
    </div>

  </>
}
