import money from "../../services/money";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import Remarks from "../atoms/Remarks";
import {useNavigate} from "react-router-dom";
import {Dialog} from "primereact/dialog";


import BookableObject from "../bookable/BookableObject";
import {UserContext} from "../../contexts/UserContext";
import {Button} from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";

export default function Booking({reservation_id}) {
  const user = useContext(UserContext)
  const [data, setData] = useState()
  const [bookVis, setBookVis] = useState(false)
  const [path, setPath] = useState('')
  const [a_id, setA_id] = useState(null)
  const [b_id, setB_id] = useState(null)
  const [src, setSrc] = useState(null)
  const [remReload, setRemReload] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    axios.post('allyourz/booking-by-id', {reservation_id}).then(res => {
      setData(res.data)
    })
  }, [reservation_id])

  const reload = () => {
    axios.post('allyourz/booking-by-id', {reservation_id}).then(res => {
      setData(res.data)
    })
  }

  const getFees = (name) => {

    if (!data.price_detail[name]) return null

    return data.price_detail[name].map((f, i) => {
      return <tr key={i}>
        <th>
          {f.name}
        </th>
        <td className="text-right">
          {money.toEuro(f.price.unit, true)}
        </td>
        <td>
          {f.chargingDescription}
        </td>
        <td className="text-right">
          {money.toEuro(f.price.total, true)}
        </td>
      </tr>
    })
  }


  if (!data) return null
  return <>
    <Dialog header={path}
            className="dialog-bookables-all"
            position={"top"}
            visible={bookVis}
            style={{width: '80%'}}
            onHide={() => setBookVis(false)}>
      <div className="bookable-objects">
        <BookableObject
          onBookableChanged={() => {
          }}
          id={b_id}
          administration_id={a_id}
          src={src}
        />
      </div>
    </Dialog>


    <div className="booking-detail">
      <div className="flex">
        <div style={{width: '80%'}}>
          <h3 style={{cursor: 'pointer'}}
              onClick={() => navigate('/allyourz/administration/' + data.administration_id)}>
            {data.administration_name} <i className="pi pi-home"/>
          </h3>
        </div>
        <div className="text-center" style={{width: '20%'}}>
          {data.request_for_review_sent !== null && <Button
            tooltipOptions={{position: 'left'}}
            disabled={data.request_for_review_sent === 0}
            style={{marginTop: '0.75rem'}}
            severity="warning"
            label="Review"
            icon="pi pi-send"
            onClick={() => {
              confirmDialog({
                position: 'top',
                header: 'Request For Review.',
                message: <><p>Door op 'Ja' te klikken wordt opnieuw een
                  <br/>review verzoek gestuurd naar het email adres
                  <br/><br/>
                  <strong>{data.email}</strong>
                  <br/><br/>
                  Weet je dit zeker?
                </p></>,
                icon: 'pi pi-exclamation-triangle',
                rejectLabel: 'Nee',
                acceptLabel: 'Ja',
                accept: () => {
                  axios.post('/allyourz/request-for-review', {reservation_id: data.id}).then(() => {
                    reload()
                    setRemReload(!remReload)
                  })
                }
              });
            }}
          />}
        </div>
      </div>

      <div style={{fontSize: '1.2rem', cursor: 'pointer'}}
           onClick={() => {
             setPath(`${data.entrepreneur_name} / ${data.administration_name} / ${data.bookable_name}`)
             setA_id(data.administration_id)
             setB_id(data.bookable_id)
             setSrc(data.e_src)
             setBookVis(true)
           }}>
        {data.bookable_name} <i className="pi pi-book"/>
      </div>

      <table style={{marginTop: '1.2rem'}}>
        <tbody>
        {user.field('ent.pms_name').view &&
          <tr>
            <th>PMS</th>
            <td>{data.pms_name}</td>
          </tr>}
        <tr>
          <th>Partner ID</th>
          <td>
            <div style={{display: 'flex', alignItems: "center"}}>
              <img
                alt=""
                title={data.pid}
                style={{width: '16px', height: '16px', borderRadius: '50%', cursor: 'pointer'}}
                src={`/media/icons/${data.pid === 'allyourz' ? 'allyourz' : 'localbooker'}.png`}/>
              <span style={{marginLeft: '4px'}}>{data.pid}</span>
            </div>

          </td>
        </tr>

        <tr>
          <th>Boekings Datum</th>
          <td>{data.created_at}</td>
        </tr>
        <tr>
          <th>No.</th>
          <td>{data.reservation_number}</td>
        </tr>
        <tr>
          <th>Voornaam</th>
          <td>{data.first_name}</td>
        </tr>
        <tr>
          <th>Achternaam</th>
          <td>{data.last_name}</td>
        </tr>
        <tr>
          <th>Email</th>
          <td>{data.email}</td>
        </tr>
        <tr>
          <th>Telefoon</th>
          <td>{data.phone_number}</td>
        </tr>
        <tr>
          <th>Postcode</th>
          <td>{data.zip_code}</td>
        </tr>
        <tr>
          <th>huisnummer</th>
          <td>{data.house_number}</td>
        </tr>
        <tr>
          <th>Straatnaam</th>
          <td>{data.street}</td>
        </tr>
        <tr>
          <th>Stad</th>
          <td>{data.city}</td>
        </tr>
        <tr>
          <th>Land</th>
          <td>{data.country}</td>
        </tr>
        <tr>
          <th>Extra Bericht</th>
          <td>{data.extra_message}</td>
        </tr>
        <tr>
          <th>Check In</th>
          <td>{data.check_in}</td>
        </tr>
        <tr>
          <th>Check Out</th>
          <td>{data.check_out}</td>
        </tr>
        <tr>
          <th>Aantal Nachten</th>
          <td>{data.price_detail.totals.stay.days}</td>
        </tr>

        <tr>
          <th>Volwassenen</th>
          <td>{data.adults}</td>
        </tr>
        <tr>
          <th>Kinderen</th>
          <td>{data.children}</td>
        </tr>
        <tr>
          <th>Babies</th>
          <td>{data.babies}</td>
        </tr>
        <tr>
          <th>Huisdieren</th>
          <td>{data.pets}</td>
        </tr>
        </tbody>
      </table>

      {/***************************/}
      {/*         PRICING         */}
      {/***************************/}
      <table className="mt-4 pr">
        <tbody>
        <tr>
          <th>Prijs</th>
          <td></td>
          <td></td>
          <td className="text-right">{money.toEuro(data.price_stay, true)}</td>
        </tr>
        {getFees('mandatoryFees')}
        {getFees('optionalFees')}
        {getFees('depositFees')}
        <tr>
          <th>
            Aanbetaling
          </th>
          <td></td>
          <td></td>
          <td className="text-right">{money.toEuro(data.price_detail.totals.payNow || 0, true)}</td>
        </tr>
        <tr>
          <th>
            Totaal
          </th>
          <td></td>
          <td></td>
          <td className="text-right">{
            money.toEuro(
              data.price_detail.totals.stay.cost +
              data.price_detail.totals.sumMandatoryFees +
              data.price_detail.totals.sumOptionalFees +
              data.price_detail.totals.sumDepositFees
              , true
            )}
          </td>
        </tr>
        </tbody>
      </table>
      <div className="m-1 mt-3">
        <div className="font-bold mb-2">Opmerkingen</div>
        <Remarks id={data.id} reload={remReload}/>
      </div>
    </div>
  </>
}
