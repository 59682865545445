
import jsonPrettyPrint from "../../services/jsonPrettyPrint";

export default function OpenGdsResponse({data}) {


  return <>
    <div style={{margin: '0.25rem', marginTop: '1rem'}}>Channel Manager Response</div>
    <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(data.payload_response)}}/>
  </>
}
