import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


export default function Entrepreneurs() {
  const [data, setData] = useState([])
  const navigate = useNavigate()

  useEffect(() => {

    axios.get('/allyourz/entrepreneurs-all').then(res => {
      setData(res.data)
    })
  }, [])

  return (
    <DataTable value={data}
               editMode="row"
               dataKey="id"
               rows={15}
               paginator={true}
               sortMode="multiple"
               removableSort
               filterDisplay="row"
               stripedRows
               onRowClick={e => {
                 navigate('/allyourz/entrepreneur/' + e.data.id)
               }}
               rowHover={true}
               style={{marginTop: '5px'}}>
      {/*=======================================================*/}
      <Column field="name"
              header="Naam" sortable filter filterMatchMode={"contains"}
              style={{width: '40%'}}
              body={row => <div style={{cursor: 'pointer'}}>
                {row.name}
              </div>}
      />
      <Column field="city"
              header="Plaats" sortable filter filterMatchMode={"contains"}
              style={{width: '20%'}}/>
      <Column field="src"
              header="Bron"
              sortable filter filterMatchMode={"contains"}
              style={{textAlign: 'center'}}/>
      <Column field="external_id"
              header="External ID"
              sortable filter filterMatchMode={"contains"}
              style={{textAlign: 'center'}}/>
      <Column field="count_admins"
              header="Admins"
              sortable
              style={{textAlign: 'center', width:'10%'}}/>
    </DataTable>
  )
}
