import Menu from "../../components/Menu";
import {Fieldset} from "primereact/fieldset";
import axios from "axios";
import {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";

export default function PageManageQennerAddons() {
  const [data, setData] = useState([])

  useEffect(() => {
    axios.get('/allyourz/manage-qenner-addons').then(res => {
      setData(res.data)
    })
  }, [])

  const save = edit => {
    const name_nl = {
      old: edit.data.name_nl,
      new: edit.newData.name_nl
    }
    const name_de = {
      old: edit.data.name_de,
      new: edit.newData.name_de
    }
    // I do not update on id here, just translate all that is the same
    axios.post('/allyourz/manage-qenner-addons', {name_nl,name_de}).then(res => {
      setData(res.data)
    })

  }

  const textEditor = field => <InputText type="text"
                      className="w100"
                      value={field.value}
                      onChange={(e) => field.editorCallback(e.target.value)}/>;
  
  return <div className="page manage-qenner-addons">
    <Menu/>
    <Fieldset legend="Add Ons">
      <DataTable value={data}
                 rows={10}
                 paginator
                 removableSort
                 filterDisplay={'row'}
                 stripedRows
                 rowHover
                 rowClassName={row => {
                   return { 'todo': (row.name_nl === row.name_de && row.name_de === row.name_en)}
                 }}
                 editMode="row" dataKey="id"
                 onRowEditComplete={data => save(data)}>
        <Column rowEditor headerStyle={{width: '110px'}} bodyStyle={{textAlign: 'center'}}/>
        <Column field="id" header="ID"/>

        <Column field="name_nl"
                filter
                filterMatchMode="contains"
                editor={textEditor}
                sortable
                header="NL"/>
        <Column field="name_de" filter filterMatchMode="contains" editor={textEditor} sortable header="DE"/>
        <Column field="name_en" filter filterMatchMode="contains" sortable header="EN (Qenner)"/>

      </DataTable>
    </Fieldset>
  </div>
}