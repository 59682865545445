import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import {classNames} from "primereact/utils";
import {confirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";

export default function Registrations() {
  const [data, setData] = useState()
  const toast = useRef()
  useEffect(() => {
      axios.get('/allyourz/registrations-all').then(res => {
        setData(res.data)
      })
  }, [])

  const updateLocalData = (id,status) => {
    const clone = [...data]
    const row = clone.find(e => {
      return e.id === id
    })

    row.status = status
    setData(clone)
  }

  const onboard = (id) => {
    axios.post('/allyourz/onboard',{id}).then(res => {
      updateLocalData('id','onboard')
    }).catch(e => {
      toast.current.show({
        severity: 'error',
        summary: e.response.data.message,
        detail: 'Het e-mail adres is reeds bekend bij allyourz.'});
    })
  }
  const spam = (id) => {
    axios.post('/allyourz/onboard-spam',{id}).then(res => {
      updateLocalData(id,'spam')
    }).catch(e => {
      toast.current.show({severity: 'error', summary: 'Fout opgetreden', detail: e.response.data.message});
    })
  }

  const act = (type,row) => {

    // First thing todo is check if the email exists in USERS as well as ENTREPRENEURS

    switch (type){
      case 'onboard':
        confirmDialog({
          header: <>Ondernemer {row.hostName} onboarden</>,
          message: <>
            <span style={{fontSize:'1.2rem'}}>Je staat op het punt de ondernemer '{row.hostName}' te onboarden.</span>

              <div>
              <ul>
                <li>De ondernemer wordt aangemaakt.</li>
                <li>Een administratie wordt aangemaakt.</li>
                <li>De ondernemer krijgt een e-mail waarmee het account geacctiveerd kan worden.</li>
              </ul>
              </div>

            <span style={{fontSize:'1.2rem'}}>Weet je dit zeker?</span>
          </>,
          icon: 'pi pi-building',
          acceptLabel: 'Ja',
          rejectLabel:'Nee',
          accept: () => {onboard(row.id)},
          reject: () => {}
        });
        break;
      case 'spam':
        confirmDialog({
          header: 'Aanmelding weigeren',
          message: <>
            <span style={{fontSize:'1.2rem'}}>Je staat op het punt de aanmelding van '{row.hostName}' te weigeren.</span>
            <p></p>
            <span style={{fontSize:'1.2rem'}}>Weet je dit zeker?</span>
          </>,
          icon: 'pi pi-times',
          acceptLabel: 'Ja',
          rejectLabel:'Nee',
          accept: () => {spam(row.id)},
          reject: () => {}
        });
        break;
      default:
        //
    }
  }

  return <>
    <Toast ref={toast}
           position="top-center"/>
    <DataTable value={data}
               dataKey="id"
               rows={15}
               rowHover={true}>
      <Column field="createdAt"
              className="text-center"
              style={{width:'10rem'}}
              header="Aanmeld Datum" body={row=>{
        return row.createdAt.substring(0,10)
      }}/>

      <Column field="email" header="Email"/>
      <Column field="name" header="Event"/>
      <Column field="participants" header="Deeln."/>
      <Column field="hostName" header="Naam"/>
      <Column field="event1" header="Checked"/>
      <Column field="status"
              className="text-center"
              header="Status" body={row=>{
        switch (row.status){
          case 'onboard':
            return <i className="pi pi-thumbs-up"/>
          case 'spam':
            return <i className="pi pi-times"/>
          default:
            return ''
        }
      }}/>
      <Column header="Onboard" style={{width:'7rem'}} body={row=>{
        return <>
          <Button icon="pi pi-thumbs-up"
                  disabled={!!row.status}
                  className={classNames('p-button-sm p-button-secondary', {'p-button-success': !row.status})}
                  onClick={()=>act('onboard',row)}
                  style={{width:'1.6rem'}}/>
          <Button icon="pi pi-times"
                  disabled={!!row.status}
                  className={classNames('p-button-sm p-button-secondary', {'p-button-danger': !row.status})}
                  onClick={()=>act('spam',row)}
                  style={{float:'right', width:'1.6rem'}}/>

        </>
      }}/>

    </DataTable>
    </>
}








