import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {useContext, useEffect, useState} from "react";
import {Dropdown} from 'primereact/dropdown';
import {AutoComplete} from 'primereact/autocomplete';
import {TriStateCheckbox} from 'primereact/tristatecheckbox';
import {UserContext} from "../../contexts/UserContext";
import InfoButton from "../atoms/InfoButton";

export default function Search({data, meta, runSearch}) {
  const user = useContext(UserContext)
  const [localData, setLocalData] = useState({})
  const [administrationSuggestions, setAdministrationSuggestions] = useState([]);
  const [entrepreneurSuggestions, setEntrepreneurSuggestions] = useState([]);


  useEffect(() => {
    const downHandler = (e) => {
      if (e.key === 'Enter') {
        runSearch(localData)
      }
    }
    window.addEventListener("keydown", downHandler)
    return () => window.removeEventListener("keydown", downHandler)
  }, [runSearch, localData]);

  // This is just the search sidebar. The real data is stored in the parent (Bookings)
  // so fill on mount
  useEffect(() => {
    setLocalData(data)
  }, [data])

  const update = (v, name) => {
    if (v === '' || v === null) {
      // delete when empty
      const n = {...localData}
      delete n[name]
      setLocalData(n)
    } else {
      const n = {}
      n[name] = v
      setLocalData({...localData, ...n})
    }
  }

  const field = (label, name) => {
    const value = localData[name] || ''
    return <tr>
      <th>{label}</th>
      <td>
        <InputText
          autoFocus={name === 'last_name'}
          className="w100"
          value={value}
          onChange={e => update(e.target.value, name)}/>
      </td>
    </tr>
  }

  const buttons = () => <>
    <Button label="Zoek..."
            disabled={Object.keys(localData).length === 0}
            severity="warning"
            style={{width: '5rem'}}
            onClick={() => {
              /**
               * Sets the data in the parent (Bookings) and if that one changes it will run the search.
               */
              runSearch(localData)
            }}/>
    <Button className="ml-3"
            disabled={Object.keys(localData).length === 0}
            outlined severity="danger"
            label="Clear"
            style={{width: '5rem'}}
            onClick={() => setLocalData({})}/>
  </>

  const administrationAutoComplete = () => <tr>
    <th>Administratie</th>
    <td>
      <AutoComplete
        // inputRef={ref}
        dropdown
        className="w100"
        field="name"
        value={localData.administration}
        suggestions={administrationSuggestions}
        completeMethod={(e) => {
          setAdministrationSuggestions(meta.administrations.filter(a => {
            return a.query.includes(e.query.toLowerCase())  // is case-insensitive, see above
          }).map(e => {
            return {name: e.name, id: e.id, ent: e.ent_name}
          }))
        }}
        onClear={() => {
          update('', 'administration')
        }}
        onSelect={(e) => {
          update(e.value, 'administration')
        }}
        onChange={(e) => {
          update(e.value, 'administration')
        }}/>
    </td>
  </tr>

  const entrepreneurAutoComplete = () => <tr>
    <th>Ondernemer</th>
    <td>
      <AutoComplete
        // inputRef={ref}
        dropdown
        className="w100"
        field="name"
        value={localData.entrepreneur}
        suggestions={entrepreneurSuggestions}
        completeMethod={(e) => {
          setEntrepreneurSuggestions(meta.entrepreneurs.filter(a => {
            return a.query.includes(e.query.toLowerCase())  // is case-insensitive, see above
          }).map(e => {
            return {name: e.name, id: e.id, ent: e.ent_name}
          }))
        }}
        onClear={() => {
          update('', 'entrepreneur')
        }}
        onSelect={(e) => {
          update(e.value, 'entrepreneur')
        }}
        onChange={(e) => {
          update(e.value, 'entrepreneur')
        }}/>
    </td>
  </tr>


  const partnerId = () => <tr>
    <th>Partner ID<ClearButt fieldname="pid"/></th>
    <td>
      <Dropdown
        value={localData.pid}
        onChange={(e) => update(e.value, 'pid')}
        options={meta.pids}

        className="w100"/>
    </td>
  </tr>
  const status = () => <tr>
    <th>Status<ClearButt fieldname="status"/></th>
    <td>
      <Dropdown
        value={localData.status}
        onChange={(e) => update(e.value, 'status')}
        options={meta.statuses}
        className="w100"/>
    </td>
  </tr>
  const stripe_status = () => <tr>
    <th>Stripe Status <ClearButt fieldname="stripe_status"/> </th>
    <td>
      <Dropdown
        value={localData.stripe_status}
        onChange={(e) => update(e.value, 'stripe_status')}
        options={meta.stripe_statuses}
        className="w100"/>
    </td>
  </tr>

  const ClearButt = ({fieldname}) => {
    return <InfoButton
      style={{float: 'right', marginRight: '0.25rem', fontSize: '0.5rem'}}
      text="Clear"
      onClick={() => update(null, fieldname)}
      size="1.25rem" icon="pi pi-times"/>
  }

  const src = () => <tr>
    <th>Bron <ClearButt fieldname="src"/></th>
    <td>
      <Dropdown
        value={localData.src}
        onChange={(e) => update(e.value, 'src')}
        options={meta.srcs}
        className="w100"/>
    </td>
  </tr>

  const pms = () => <tr>
    <th>PMS <ClearButt fieldname="pms"/></th>
    <td>
      <Dropdown
        value={localData.pms}
        onChange={(e) => update(e.value, 'pms')}
        options={meta.pmss}
        className="w100"/>
    </td>
  </tr>

  const ___ = () => <>
    <tr>
      <td colSpan="2" className="p-1" style={{color: '#cccccc'}}>
        <span>--</span>
        <span className="right">--</span>
      </td>
    </tr>
  </>

  const payment = () => <tr>
    <th><label className="pointer" onClick={() => {
      update((typeof localData.payment === "undefined" ? true : (localData.payment ? false : null)), 'payment')
    }}>Stripe</label></th>
    <td className="p-2">
      <TriStateCheckbox
        value={typeof localData.payment === "undefined" ? null : localData.payment}
        onChange={(e) => update(e.value, 'payment')}/>
      &nbsp; {localData.payment === true ? 'Betaling nodig' : (localData.payment === false ? 'Geen betaling nodig' : '')}
    </td>
  </tr>


  const resolved = () => <tr>
    <th><label className="pointer" onClick={() => {
      update((typeof localData.resolved === "undefined" ? true : (localData.resolved ? false : null)), 'resolved')
    }}>Opgelost</label></th>
    <td className="p-2">
      <TriStateCheckbox
        value={typeof localData.resolved === "undefined" ? null : localData.resolved}
        onChange={(e) => update(e.value, 'resolved')}/>
      &nbsp; {localData.resolved === true ? 'Opgelost' : (localData.resolved === false ? 'Niet opgelost' : '')}
    </td>
  </tr>

  const manual = () => <tr>
    <th style={{width: '40%'}}><label className="pointer" onClick={() => {
      update((typeof localData.manual === "undefined" ? true : (localData.manual ? false : null)), 'manual')
    }}>Manueel</label></th>
    <td className="p-2">
      <TriStateCheckbox
        value={typeof localData.manual === "undefined" ? null : localData.manual}
        onChange={(e) => update(e.value, 'manual')}/>
      &nbsp; {localData.manual === true ? 'Manueel afgehandeld' : (localData.manual === false ? 'Niet manueel afgehandeld' : '')}
    </td>
  </tr>

  return <>
    <div className="grid booking-detail">
      <div className="col-6">
        <table style={{marginTop: '1.2rem'}}>
          <tbody>
          {field('Achternaam', 'last_name')}
          {field('Voornaam', 'first_name')}
          {field('Email', 'email')}
          {field('Telefoon', 'phone_number')}
          {field('Postcode', 'zip_code')}
          {field('Huisnummer', 'house_number')}
          {field('Straatnaam', 'street')}
          {field('Stad', 'city')}
          {field('Land', 'country')}
          </tbody>
        </table>
      </div>
      <div className="col-6">
        <table style={{marginTop: '1.2rem'}}>
          <tbody>
          {field('Reserverings No', 'reservation_number')}
          {field('Check In', 'check_in')}
          {field('Check Out', 'check_out')}
          {field('Boekingsdatum', 'created_at')}
          {user.field('sys.is_admin').view && <>
            {___()}
            {src()}
            {pms()}
            {partnerId()}
            {status()}
            {payment()}
            {stripe_status()}
            {manual()}
            {resolved()}
            {entrepreneurAutoComplete()}
            {administrationAutoComplete()}
          </>}
          </tbody>
        </table>
      </div>
      <div className="col-12">
        {buttons()}
      </div>
    </div>
  </>
}
