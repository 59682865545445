import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";
import axios from "axios";
import Remarks from "../atoms/Remarks";
import {useState} from "react";

export default function ResolveBooking({data, setData, closeMe, onResolved, popHead, reload}) {

  const [remark, setRemark] = useState()
  const [remReload,setRemReload] = useState(false)


  const resolveBooking = () => {
    confirmDialog({
      position: 'top',
      header: 'Markeer als opgelost.',
      message: <><p>Door op 'Ja' te klikken markeer je deze boeking als 'opgelost'.
        <br/>
        <br/>
        Als alle rode boekingen zijn opgelost zal het boek in het menu niet meer rood zijn.
      </p></>,
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Nee',
      acceptLabel: 'Ja',
      accept: () => {
        axios.post('/allyourz/booking-error-resolved', {
          id: data.id,
          remark: `Gemarkeerd als opgelost.\n\n${remark}`

        }).then(res => {
          // Update table (parent)
          setData(res.data)
          onResolved() // Bookings table
          reload()     // BookPayload
        })
      }
    });
  }

  const setManualSuccess = () => {
    confirmDialog({
      position: 'top',
      header: 'Markeer als opgelost.',
      message: <><p>Door op 'Ja' te klikken markeer je deze boeking als 'Succesvol Afgerond'
        <br/>en zal hij 'Groen' woden in het overzicht en meetellen voor de commisie.
        <br/><br/>
        Weet je dit zeker?
      </p></>,
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Nee',
      acceptLabel: 'Ja',
      accept: () => {
        axios.post('/allyourz/booking-manual-success', {
          id: data.id,
          remark: `Succesvol manueel afgerond.`
        }).then(res => {
          // Update table (parent)
          setData(res.data)
          onResolved() // Bookings table
          reload()     // BookPayload
          setRemReload(!remReload)
        })
      }
    });

  }

  return data.resolved_by ? <>
      <div className="grid mt-2">
        <div className="col-2 font-bold">
          Opgelost
        </div>
        <div className="col-10">
          {popHead}
        </div>
      </div>
      {!data.manual_success_by && <div className="grid mt-2">
        <div className="col-2 font-bold pt-3">
          Markeer als success
        </div>
        <div className="col-5">
          <Button className="p-button-success"
                  onClick={() => setManualSuccess()}
                  label="Markeer boeking als succesvol afgerond!"/>
        </div>
      </div>}

      <div className="grid mt-2">
        <div className="col-2 font-bold">
          Opmerkingen
        </div>
        <div className="col-5">
          <Remarks id={data.reservation_id} reload={remReload}/>
        </div>
      </div>
    </>
    :
    <>
      <div className="grid mt-2">
        <div className="col-2 font-bold">
          Markeren als opgelost
        </div>
        <div className="col-10">
          {popHead}
        </div>
        <div className="col-2 font-bold">
          Geef reden
        </div>
        <div className="col-10">
          <InputTextarea style={{width: '50%'}}
                         rows={5}
                         value={remark}
                         onChange={e => setRemark(e.target.value)}/>
        </div>
        <div className="col-2">
        </div>
        <div className="col-10">
          <Button className="p-button-danger"
                  disabled={!remark}
                  onClick={() => resolveBooking()}
                  label="Los Op"/>
          <Button className="p-button-warning ml-4"
                  onClick={closeMe}
                  label="Cancel"/>
        </div>
      </div>
    </>

}
