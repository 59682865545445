import {useContext, useEffect, useRef, useState} from "react";
import {UserContext} from "../contexts/UserContext";
import axios from "axios";
import {InputText} from "primereact/inputtext";
import {InputMask} from 'primereact/inputmask';
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import {FileUpload} from "primereact/fileupload";
import {Toast} from "primereact/toast";
import {Dropdown} from "primereact/dropdown";

export default function PolicyRules({administration_id, administrationData}) {
  const user = useContext(UserContext)
  const [rules, setRules] = useState({})
  const [doc, setDoc] = useState({})
  const [dirty, setDirty] = useState(false)
  const toast = useRef()

  const setData = (res, firstload) => {
    setRules(res.data.policyrule)
    setDoc(res.data.document)
    setDirty(false)
    if (!firstload) {
      toast.current.show({
        life: 2000,
        severity: 'success',
        summary: 'De Policy Rule',
        detail: 'is succesvol bijgewerkt!'
      });

    }
  }
  useEffect(() => {
    axios.get(`/allyourz/policy-rule-by-administration-id`, {params: {administration_id}}).then(res => {
      setData(res, true)
    })
  }, [user.language, administration_id])

  const onBeforeSend = (req) => {
    let api_token = sessionStorage.getItem('api_token')
    // Set auth!
    req.xhr.setRequestHeader('Authorization', 'Bearer ' + api_token)
    req.xhr.setRequestHeader('allyourz-language', user.language)
    return req;
  }

  const save = () => {
    axios.put('/allyourz/policy-rule-by-administration-id' +
      `?administration_id=${administration_id}` +
      `&doc_name=${encodeURIComponent(doc.name)}`,
      rules
    ).then(res => {
      setData(res)
    })
  }

  return <div className="policy-rules">
    <Toast ref={toast} position="top-left"/>
    {/* CHECKIN */}
    <div className="grid">
      <div className="col-2 fieldname">Checkin / Checkout</div>
      <div className="col-1">
        <InputMask
          mask="99:99"
          slotChar="__:__"
          value={rules.checkin_starttime || ''}
          onChange={e => {
            setDirty(true)
            setRules({...rules, checkin_starttime: e.target.value})
          }}/>
      </div>
      <div className="col-2 fieldname" style={{textAlign: 'center'}}>Van / Tot</div>
      <div className="col-1">
        <InputMask
          mask="99:99"
          slotChar="__:__"
          value={rules.checkin_endtime || ''}
          onChange={e => {
            setDirty(true)
            setRules({...rules, checkin_endtime: e.target.value})
          }}/>
      </div>
      <div className="col-1 col-offset-1 fieldname">Checkout</div>
      <div className="col-1">
        <InputMask
          mask="99:99"
          slotChar="__:__"
          value={rules.checkout_time || ''}
          onChange={e => {
            setDirty(true)
            setRules({...rules, checkout_time: e.target.value})
          }}/>
      </div>
    </div>
    {/* Min/Max nights */}
    <div className="grid">
      <div className="col-2 fieldname">Min / Max Nachten</div>
      <div className="col-1">
        <InputText
          value={rules.min_nr_nights || ''}
          onChange={e => {
            setDirty(true)
            setRules({...rules, min_nr_nights: e.target.value})
          }}/>
      </div>
      <div className="col-2 fieldname" style={{textAlign: 'center'}}>Min. / Max.</div>

      <div className="col-1">
        <InputText
          value={rules.max_nr_nights || ''}
          onChange={e => {
            setDirty(true)
            setRules({...rules, max_nr_nights: e.target.value})
          }}/>
      </div>
    </div>
    {/* Min/Max persons */}
    <div className="grid">
      <div className="col-2 fieldname">Min / Max Personen</div>
      <div className="col-1">
        <InputText
          value={rules.total_minamount_persons || ''}
          onChange={e => {
            setDirty(true)
            setRules({...rules, total_minamount_persons: e.target.value})
          }}/>
      </div>
      <div className="col-2 fieldname" style={{textAlign: 'center'}}>Min. / Max.</div>

      <div className="col-1">
        <InputText
          value={rules.total_maxamount_persons || ''}
          onChange={e => {
            setDirty(true)
            setRules({...rules, total_maxamount_persons: e.target.value})
          }}/>
      </div>
    </div>

    {/* minnum_days_before_start_booking */}
    <div className="grid">
      <div className="col-2 fieldname">Min. aantal dagen voor aankomst dichtzetten</div>
      <div className="col-1">
        <InputText
          value={rules.minnum_days_before_start_booking || ''}
          onChange={e => {
            setDirty(true)
            setRules({...rules, minnum_days_before_start_booking: e.target.value})
          }}/>
      </div>
      <div className="col-2 fieldname text-center">Annuleren mogelijk</div>
      <div className="col-2">
        <Dropdown value={rules.cancellation_threshold}
                  onChange={(e) => {
                    setDirty(true)
                    setRules({...rules, cancellation_threshold: e.target.value})
                  }}
                  options={administrationData.cancellation_thresholds}/>
      </div>
      <div className="col-2 fieldname text-center">Betaling situatie</div>
      <div className="col-2">
        <Dropdown value={rules.pay_in_advance_situation}
                  onChange={(e) => {
                    setDirty(true)
                    setRules({...rules, pay_in_advance_situation: e.target.value})
                  }}
                  options={administrationData.pay_in_advance_situations}/>
      </div>
    </div>

    <hr/>

    <div className="grid">
      <div className="col-2 fieldname">Huisregels</div>
      <div className="col-10">
        <InputTextarea
          value={rules.houserules || ''} rows={5}
          onChange={e => {
            setDirty(true)
            setRules({...rules, houserules: e.target.value})
          }}/>
      </div>
    </div>

    <div className="grid">
      <div className="col-2 fieldname">Betaling</div>
      <div className="col-10">
        <InputTextarea
          value={rules.pay_in_advance || ''} rows={5}
          onChange={e => {
            setDirty(true)
            setRules({...rules, pay_in_advance: e.target.value})
          }}/>
      </div>
    </div>
    <div className="grid">
      <div className="col-2 fieldname">Annulering</div>
      <div className="col-10">
        <InputTextarea
          value={rules.cancellation || ''} rows={5}
          onChange={e => {
            setDirty(true)
            setRules({...rules, cancellation: e.target.value})
          }}/>
      </div>
    </div>

    {/* TERMS */}
    <div className="grid">
      <div className="col-2 fieldname" style={{height: '42px'}}>
        Voorwaarden
      </div>
      <div className="col-3">
        <Checkbox inputId="custom_terms_and_conditions"
                  onChange={(e) => {
                    setDirty(true)
                    setRules({...rules, custom_terms_and_conditions: e.checked})
                  }}
                  checked={!!rules.custom_terms_and_conditions}/>
        <label style={{marginLeft: '0.5rem'}} htmlFor="custom_terms_and_conditions" className="p-checkbox-label">Custom
          Voorwaarden</label>
      </div>
      <div className="col-3">
        <Checkbox inputId="custom_terms_and_conditions_consent"
                  onChange={(e) => {
                    setDirty(true)
                    setRules({...rules, custom_terms_and_conditions_consent: e.checked})
                  }}
                  checked={!!rules.custom_terms_and_conditions_consent}/>
        <label style={{marginLeft: '0.5rem'}} htmlFor="custom_terms_and_conditions_consent"
               className="p-checkbox-label">Consent</label>
      </div>
    </div>
    {/* SMOKING */}
    <div className="grid">
      <div className="col-2 fieldname" style={{height: '42px'}}>

      </div>
      <div className="col-3">
        <Checkbox inputId="smoking_allowed"
                  onChange={(e) => {
                    setDirty(true)
                    setRules({...rules, smoking_allowed: e.checked})
                  }}
                  checked={!!rules.smoking_allowed}/>
        <label style={{marginLeft: '0.5rem'}} htmlFor="smoking_allowed" className="p-checkbox-label">Roken
          Toegestaan</label>
      </div>
    </div>

    {/************************************************************/}
    {/*                            DOC                           */}
    {/************************************************************/}
    <div className="grid">
      <div className="col-2 fieldname">Algemene voorwaarden</div>

      <div className="col-5">
        <InputText
          id="document" value={doc.name || ''}
          disabled
          onChange={e => {
            setDirty(true)
            setDoc({...doc, name: e.target.value})
          }}/>
      </div>
      <div className="col-1">
        {doc.url && <a
          target="_blank" rel="noreferrer"
          href={`${process.env.REACT_APP_DOCUMENTS}/${administration_id}/${user.language}/${doc.id}`}
          style={{textDecoration: 'none'}}>
          <Button className="p-button-outlined"
                  icon="pi pi-external-link"
                  style={{width: '2rem', height: '2rem'}}/>
        </a>}
      </div>

      <div className="col-4">
        <FileUpload
          onBeforeSend={onBeforeSend}
          name="pdf"
          accept="application/pdf"
          maxFileSize={8000000}
          onUpload={(res) => {
            setData({data: JSON.parse(res.xhr.response)})
          }}
          url={process.env.REACT_APP_API + '/allyourz/policy-rule-upload-and-set-document' +
            '?administration_id=' + administration_id +
            '&name=' + (doc.name || '')
          }/>
      </div>

    </div>
    <div className="grid">
      <div className="col-12">
        <Button
          label="Save"
          disabled={!dirty}
          onClick={() => save()}
        />
      </div>
    </div>

  </div>
}
