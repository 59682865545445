import {Image} from "primereact/image";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {FileUpload} from 'primereact/fileupload';
import {confirmDialog} from 'primereact/confirmdialog';
import {UserContext} from "../contexts/UserContext";
import MediaPicker from "./MediaPicker";
import {Dialog} from "primereact/dialog";
import {Fieldset} from "primereact/fieldset";

export default function MediaItems({entityId, administration_id}) {
  const context = useContext(UserContext)
  const [items, setItems] = useState([])
  const [showImagePicker, setShowImagePicker] = useState(false)
  const [showMedia, setShowMedia] = useState(false)

  useEffect(() => {
    load(entityId)
  }, [entityId, context.language])

  const load = (entityId) => {
    axios.get('/allyourz/' + entityId + '/media-by-entityid').then(res => {
      setItems(res.data)
    })
  }

  const onBeforeSend = (req) => {
    let api_token = sessionStorage.getItem('api_token');
    // Set auth!
    req.xhr.setRequestHeader('Authorization', 'Bearer ' + api_token)
    req.xhr.setRequestHeader('allyourz-language', context.language)
    return req;
  }

  const move = (id, direction) => {
    axios.post('/allyourz/' + entityId + '/media-by-entityid', {
      action: 'move',
      id,
      direction
    }).then(res => {
      load(entityId)
    })
  }

  const save = (id, value, field) => {
    axios.post('/allyourz/' + entityId + '/media-by-entityid', {
      action: 'save',
      id,
      value,
      field
    }).then(res => {
      // Do not reload
    })
  }

  const addExistingImages = (ids) => {
    axios.post('/allyourz/' + entityId + '/media-add-existing-images', {ids}).then(res => {
      load(entityId)
    })
  }

  const remove = (id) => {
    confirmDialog({
      header: 'Afbeelding verwijderen',
      message: <>De afbeelding wordt enkel van de site verwijderd maar blijft in het portaal aanwezig.</>,
      acceptLabel: 'OK',
      rejectLabel: 'Cancel',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        axios.post('/allyourz/' + entityId + '/media-by-entityid', {
          action: 'remove',
          id
        }).then(res => {
          load(entityId)
        })
      },
      reject: () => {
      }
    });
  }

  return <>
    <Dialog header="Bestaande plaatjes"
            position={"top"}
            visible={showImagePicker}
            style={{width: '50vw'}}
            onHide={() => setShowImagePicker(false)}>
      <MediaPicker
        administration_id={administration_id}
        onSave={ids => {
          addExistingImages(ids)
          setShowImagePicker(false)
        }}/>
    </Dialog>

    <Dialog
      visible={!!showMedia}
      style={{width: '80%'}}
      onHide={() => setShowMedia(false)}>
      <Image
        src={process.env.REACT_APP_MEDIA + showMedia}
        alt="Image"
        width="100%"
      />
    </Dialog>

    <div className="media">
      <div className="grid">
        <div className="col-8 upload">
          <Fieldset legend="Upload nieuwe afbeeldingen">
            <FileUpload
              onBeforeSend={onBeforeSend}
              name="images[]"
              accept="image/x-png,image/jpeg,image/jpg"
              maxFileSize={8000000}
              auto={false}
              onUpload={(res) => {
                load(entityId)
              }}
              url={process.env.REACT_APP_API + '/allyourz/media-upload' +
                '?administration_id='+administration_id +
                '&entity_id='+entityId
              }
              multiple/>
          </Fieldset>
        </div>
        <div className="col-4">
          <Fieldset legend="Of gebruik bestaande">
            <Button label="Kies afbeelding(en)" onClick={() => {
              setShowImagePicker(true)
            }}/>
          </Fieldset>
        </div>
      </div>

      <Fieldset legend="Gepubliceerd">
        <div className="grid">
          {items.map((item, i) => {
            return <div key={item.id} className="col-3" style={{textAlign: 'center'}}>
              <div className="grid">
                <div className="col-offset-2 col-8">
                  <div onClick={() => setShowMedia(item.url)}>
                    <Image
                      src={process.env.REACT_APP_MEDIA + item.thumb}
                      alt="Image"
                      width="100%"
                      style={{cursor: 'pointer'}}
                    />
                  </div>
                  <InputText value={item.title || ''}
                             onChange={(e) => {
                               items[i].title = e.target.value
                               setItems([...items])
                             }}
                             onBlur={(e) => {
                               save(item.id, e.target.value, 'title')
                             }}
                             placeholder="Titel"/>
                  <InputText value={item.description || ''}
                             onChange={(e) => {
                               items[i].description = e.target.value
                               setItems([...items])
                             }}
                             onBlur={(e) => {
                               save(item.id, e.target.value, 'description')
                             }}
                             style={{marginTop: '0.3rem'}}
                             placeholder="Omschrijving"/>
                  <div className="grid" style={{marginTop: '-0.2rem'}}>
                    <div className="col-offset-3 col-3 text-right">
                      {i !== 0 &&
                        <Button onClick={() => move(item.id, 'left')} icon="pi pi-arrow-left" className="p-button-text"/>}
                    </div>
                    <div className="col-3 text-left">
                      {i < (items.length - 1) &&
                        <Button onClick={() => move(item.id, 'right')} icon="pi pi-arrow-right" className="p-button-text"/>}
                    </div>
                    <div className="col-3 right">
                      <Button onClick={() => remove(item.id)} icon="pi pi-times" className="p-button-text p-button-danger"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          })}
        </div>
      </Fieldset>
    </div>
  </>
}
