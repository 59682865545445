import {useEffect, useRef, useState} from 'react'

import {Fieldset} from "primereact/fieldset";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import axios from "axios";
import Menu from "../../components/Menu";

const defaultUser = {id: null, name: '', email: '', password: '', google2fa_secret: '', roles: ''}

export default function PageUsers() {
  const toast = useRef()
  const formRef = useRef();
  const secretRef = useRef();
  const jwtRef = useRef();

  const [state, setState] = useState([])


  useEffect(() => {
    axios.get('/allyourz/guest/all').then(res => {
      let {data} = res
      data.push(defaultUser)
      setState({data: res.data})
    })

  }, [])

  const onImpersonate = (id) => {
    axios.get(`/allyourz/guest/impersonate?id=${id}`)
    .then(response => {
      secretRef.current.value = response.data.secret
      jwtRef.current.value = response.data.jwt
  
      formRef.current.submit()
  
      secretRef.current.value = ''
      jwtRef.current.value = ''
    })
    .catch(e => {
      toast.current.show({life: 5000, severity: 'error', summary: 'Invoer fout', detail: e.response.data.message})
    })
  }

  return (
    <><Toast ref={toast} position="top-left"/>
      <div className="page users">
        <Menu/>
        <form action={`${process.env.REACT_APP_ALLYOURZ_NL}/api/impersonate`} method="POST" target="_blank" style={{display: 'none'}} ref={formRef}>
          <input type="hidden" name="secret" ref={secretRef} />
          <input type="hidden" name="jwt" ref={jwtRef} />
        </form>
        <Fieldset legend="Gasten">
          <DataTable value={state.data}
                     dataKey="id"
                     rows={10}
                     sortMode="multiple"
                     removableSort
                     filterDisplay="row"
                     paginator={true}
                     style={{marginTop: '5px'}}>
            {/*=======================================================*/}
            <Column field="name" header="Naam" sortable filter filterMatchMode={"contains"} />
            <Column field="email" header="Email" sortable filter filterMatchMode={"contains"} />
            <Column header="Imiteren" body={(data) => <Button className="butt p-button-secondary" onClick={() => onImpersonate(data.id)}> imiteren</Button>} />
          </DataTable>
        </Fieldset>
      </div>
    </>
  )


}
