import {useContext, useEffect, useRef, useState} from "react";
import {TabPanel, TabView} from "primereact/tabview";
import MediaItems from "../MediaItems";
import Data from "./Data";
import Availability from "./calendar/Availability";
import {UserContext} from "../../contexts/UserContext";
import axios from "axios";
import {BreadcrumbContext} from "../../contexts/BreadcrumbContext";
import {Toast} from "primereact/toast";
import DebugActivity from "./DebugActivity";

import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {InputMask} from "primereact/inputmask";
import Facilities from "../facilities/Facilities";
import {confirmDialog} from "primereact/confirmdialog";


export default function Activity({id, administration_id, onActivityChanged}) {

  const toast = useRef(null)
  const user = useContext(UserContext)
  const breadcrumbs = useContext(BreadcrumbContext)
  const [timeslots, setTimeslots] = useState([])
  const [data, setData] = useState({})
  const [activeIndex, setActiveIndex] = useState(0)
  const [newTimeslotDialog, setNewTimeslotDialog] = useState(false)
  const [newTimeslot, setNewTimeslot] = useState('09:30')

  useEffect(() => {
    axios.get('/allyourz/activity-by-id', {params: {id}}).then(res => {
      setData(res.data)
      setTimeslots(res.data.timeslots)
      /**
       * Do breadcrumbs
       */
      const ac = res.data.ac
      breadcrumbs.setBookable(
        ac.entrepreneur_id,
        ac.entrepreneur_name,
        ac.administration_id,
        ac.administration_name,
        ac.name
      )

    })
    // eslint-disable-next-line
  }, [id, user.language]);


  const save = (data, callback) => {

    axios.post('/allyourz/activity-by-id', {
      data
    }).then(res => {
      setData(res.data)
      onActivityChanged()
      callback()
      toast.current.show({
        life: 1000,
        severity: 'success',
        summary: data.name,
        detail: 'updated successfully!'
      });
    }).catch(e => {
      toast.current.show({
        life: 5000, severity: 'error', summary: 'Onjuiste invoer',
        detail: Object.entries(e.response.data.errors).map((e, i) => <div key={i}
                                                                          dangerouslySetInnerHTML={{__html: e[1][0]}}/>),
      });
      onActivityChanged()
      callback()
    })
  }

  const removeTimeslot = (timeslot_id, time, hide) => {
    confirmDialog({
      position: 'center',
      header: 'Aanvangstijd verwijderen of ' + (hide ? 'tonen' : 'verbergen'),
      message: <>Aanvangstijd <strong>{time}</strong> verwijderen of {hide ? 'tonen' : 'verbergen'}?<br/></>,
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Verwijderen',
      acceptLabel: (hide ? 'tonen' : 'verbergen'),
      accept() {
        axios.get('allyourz/activity-hide-timeslot', {params: {timeslot_id}}).then((res) => {
          toast.current.show({life: 10000, severity: 'success', summary: 'Succes!', detail: 'Aanvagstijd ' + time + ' met success ' + (hide ? 'getoond' : 'verborgen')})
          setData(res.data)
          setTimeslots(res.data.timeslots)
          setActiveIndex(0)
        })
      },
      reject() {
        axios.get('allyourz/activity-delete-timeslot', {params: {timeslot_id}}).then((res) => {
          toast.current.show({life: 10000, severity: 'success', summary: 'Succes!', detail: 'Aanvagstijd ' + time + ' met success verwijderd'})
          setData(res.data)
          setTimeslots(res.data.timeslots)
          setActiveIndex(0)
        }).catch(res => {
          toast.current.show({
            life: 10000,
            severity: 'error',
            summary: 'Fout opgetreden',
            detail: res.response.data.message
          })
        })
      }
    });

  }

  const addTimeslot = () => {
    axios.post('/allyourz/activity-add-timeslot', {
      id,
      timeslot: newTimeslot
    }).then(res => {
      setData(res.data)
      setTimeslots(res.data.timeslots)
      setNewTimeslotDialog(false)
      setActiveIndex(0)
    }).catch(e => {
      toast.current.show({
        life: 5000, severity: 'error', summary: 'Onjuiste invoer',
        detail: <div dangerouslySetInnerHTML={{__html: e.response.data}}/>
      });
    })

  }

  /**
   * needs the timeslot ID to seelct the proper tab
   */
  const activityReload = (timeslot_id) => {

    axios.get('/allyourz/activity-by-id', {params: {id}}).then(res => {
      setData(res.data)
      setTimeslots(res.data.timeslots)

    })
  }

  const newTimeslotDialogHtml = () => {
    return <>
      <div className="grid mt-3 mb-4">
        <div className="col-4 text-lg pt-3">
          Nieuwe Aanvangstijd
        </div>
        <div className="col-8">
          <InputMask
            mask="99:99"
            style={{width: '100%'}}
            value={newTimeslot}
            onChange={e => setNewTimeslot(e.target.value)}
          />
        </div>
      </div>
      <div className="grid">
        <div className="col-6">
          <Button label="Cancel"
                  className="p-button-warning"
                  onClick={() => {
                    setNewTimeslot('09:30')
                    setNewTimeslotDialog(false)
                  }}
          />
        </div>
        <div className="col-6">
          <Button label="OK"
                  onClick={() => addTimeslot()}
                  style={{float: 'right'}}/>
        </div>
      </div>
    </>
  }

  /**
   * @todo when we have time select the proper tab on edit and new
   * Can not find an easy solution that quickly
   */
  // const selectTabByTimeslotId = (id) => {
  //   const index = parseInt(document.querySelector('.' + id + ' a').id.split('_').pop())
  //   setActiveIndex(index)
  // }

  if (!Object.keys(data).length) return null

  return <>
    <Dialog header="Nieuwe Aanvangstijd" position="top"
            visible={newTimeslotDialog}
            style={{width: '30vw'}}
            onHide={() => setNewTimeslotDialog(false)}>
      {newTimeslotDialogHtml()}
    </Dialog>
    <Toast ref={toast} position="top-left"/>
    <div className="grid">
      <div className="col-12">
        <TabView
          style={{margin: '0 0 0 0'}}
          activeIndex={activeIndex}
          onTabChange={(e) => {
            setActiveIndex(e.index)
          }}>
          <TabPanel header="Gegevens">
            <Data activityData={data}
                  reload={activityReload}
                  save={save}/>
          </TabPanel>
          <TabPanel header="Media">
            <MediaItems entityId={id} administration_id={administration_id}/>
          </TabPanel>
          <TabPanel header="Faciliteiten" className="mr-4">
            <Facilities entityId={id} type="activity"/>
          </TabPanel>
          {timeslots.map((e, i) => {
            return <TabPanel
              key={i}
              className={e.id}
              header={
                <><span style={{color:e.hide?'#c8c8c8': 'inherit'}} title={e.hide ? 'Deze aanvangstijd is verborgen' : 'Actief'}>{(i ? '' : 'Aanvang: ')} {e.timeslot}</span>
                  <Button style={{marginLeft: '25px', fontSize: '0.8rem', width: '16px', height: '16px'}}
                          severity={e.hide ? 'secondary' : 'info'}
                          icon="pi pi-times"
                          onClick={()=> removeTimeslot(e.id,e.timeslot,e.hide)}
                  />
                </>
              }>
              <Availability
                timeslot_id={e.id}
                activityRelaod={activityReload}
                removeTimeslot={removeTimeslot}
              />
            </TabPanel>
          })}
          {user.field('sys.add_timeslot').view && <TabPanel headerTemplate={
            <Button icon="pi pi-clock"
                    title="Nieuwe aanvangstijd"
                    className="p-button-success"
                    onClick={() => {
                      setNewTimeslotDialog(true)
                    }}
                    style={{
                      height: '2rem',
                      width: '2rem',
                      marginTop: '0.4rem',
                      marginLeft: '0.8rem'
                    }}/>
          }/>}

          {user.field('sys.show_debug').view &&
            <TabPanel className="ml-4" header="Debug">
              <DebugActivity activity_id={id}/>
            </TabPanel>
          }
        </TabView>
      </div>
    </div>
  </>
}
