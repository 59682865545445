import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Checkbox} from "primereact/checkbox";

export default function Zakelijk({data, setData, countries}) {
  return <div className="grid mt-1">
    <div className="col-8">
      <div>
        {/*Zakelijke naam*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Zakelijke naam (volledig wettelijke naam)</div>
          <div className="col-8">
            <InputText
              id={'i_name'}
              value={data.i_name || ''}
              onChange={(e) => {

                setData({...data, i_name: e.target.value})
              }}
              placeholder={'Zakelijke naam (volledig wettelijke naam)'}
              className="w100"/>
          </div>
        </div>
        {/*Geregistreerd zakelijk adres*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Geregistreerd zakelijk adres + land</div>
          <div className="col-6">
            <InputText
              id={'i_address'}
              value={data.i_address || ''}
              onChange={(e) => {

                setData({...data, i_address: e.target.value})
              }}
              placeholder={'Geregistreerd zakelijk adres (adres uit KvK)'}
              className="w100"/>
          </div>
          <div className="col-2">
            <Dropdown
              id={'i_address_country'}
              options={countries}
              value={data.i_address_country || 'NL'}
              onChange={(e) => {

                setData({...data, i_address_country: e.value})
              }}/>
          </div>
        </div>
        {/*Btw nummer*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Btw nummer</div>
          <div className="col-8">
            <InputText
              id={'i_btw_no'}
              disabled={!!data.i_no_bwt_no}
              value={data.i_btw_no || ''}
              onChange={(e) => {

                setData({...data, i_btw_no: e.target.value})
              }}
              placeholder={'Btw nummer'}
              className="w100"/>
          </div>
        </div>
        {/*Banknummer*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Banknummer</div>
          <div className="col-8">
            <InputText
              id={'i_bank_no'}
              value={data.i_bank_no || ''}
              onChange={(e) => {

                setData({...data, i_bank_no: e.target.value})
              }}
              placeholder={'IBAN bankrekening nummer'}/>
          </div>
        </div>
        {/*Bedrijfsregistratienummer*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Bedrijfsregistratienummer (KVK)</div>
          <div className="col-8">
            <InputText
              id={'i_kvk_no'}
              value={data.i_kvk_no || ''}
              onChange={(e) => {

                setData({...data, i_kvk_no: e.target.value})
              }}
              placeholder={'Bedrijfsregistratienummer (KvK nummer)'}/>
          </div>
        </div>
        {/*Fiscaal nummer*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Fiscaal nummer</div>
          <div className="col-6">
            <InputText
              id={'i_fiscal_no'}
              value={data.i_fiscal_no || ''}
              onChange={(e) => {

                setData({...data, i_fiscal_no: e.target.value})
              }}
              placeholder={'Fiscaal identificatienummer (RSIN nummer)'}/>
          </div>
          <div className="col-2 flex align-items-center">
            <Dropdown
              id={'i_fiscal_no_country'}
              options={countries}
              value={data.i_fiscal_no_country || 'NL'}
              onChange={(e) => {

                setData({...data, i_fiscal_no_country: e.value})
              }}/>
          </div>
        </div>
        {/*Kadaster nummer*/}
        <div className="grid">
          <div className="col-4 flex align-items-center">Kadaster nummer *</div>
          <div className="col-8">
            <InputText
              id={'i_kadaster_no'}
              value={data.i_kadaster_no || ''}
              onChange={(e) => {

                setData({...data, i_kadaster_no: e.target.value})
              }}
              placeholder={'Kadaster nummer'}/>
          </div>
        </div>
        <div className="grid my-1">
          <div className="col-12">
            <Checkbox
              inputId="truth"
              checked={!!data.truth}
              onChange={e => setData({...data, truth: e.checked})}/>
            <label htmlFor="truth"
                   className="font-bold p-checkbox-label pointer ml-2 truth">Ik heb dit formulier correct en naar waarheid ingevuld</label>&nbsp;
          </div>
        </div>
      </div>
    </div>
    <div className="col-4">

    </div>
  </div>

}