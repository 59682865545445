import {useContext, useEffect, useRef, useState} from "react";
import {UserContext} from "../contexts/UserContext";
import axios from "axios";
import {Checkbox} from "primereact/checkbox";
import {Toast} from "primereact/toast";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {Password} from "primereact/password";
import StatusButton from "./bookings/StatusButton";
import Remarks from "./atoms/Remarks";
import InfoButton from "./atoms/InfoButton";
import {confirmDialog} from "primereact/confirmdialog";
import {Card} from "primereact/card";

export default function Entrepreneur({entrepreneurData, save, reload}) {
  const [data, setData] = useState({})
  const toast = useRef()
  const user = useContext(UserContext)


  useEffect(() => {
    setData(entrepreneurData)
  }, [entrepreneurData])


  const disable2FA = (value) => {
    if (value) {
      confirmDialog({
        header: 'Two Factor Authenticatie Uitschakelen',
        message: <>
          Je staat op het punt Two Factor Authenticatie uit te zetten.<br/><br/>
          <strong>LET OP!</strong> Je huidige wachtwoord zal, als je op 'Ja' klikt, niet meer werken!<br/><br/>
          In de volgende stap kun je een nieuw wachtwoord aanmaken.<br/><br/>
          Weet je dit zeker dat je Two Factor Authenticatie uit wil schakelen?<br/>
        </>,
        acceptLabel: 'Ja', rejectLabel: 'Nee', accept: () => {
          axios2FA(value)
        }
      })
    } else {
      confirmDialog({
        header: 'Two Factor Authenticatie Aanzetten',
        message: <>
          Je staat op het punt om Two Factor Authenticatie aan te zetten.<br/><br/>
          Weet je zeker dat je Two Factor Authenticatie aan wil zetten?<br/>
        </>,
        acceptLabel: 'Ja', rejectLabel: 'Nee', accept: () => {
          axios2FA(value)
        }
      })
    }
  }

  const axios2FA = (value) => {
    axios.post('/allyourz/entrepreneurs-manage-2fa', {entrepreneur_id: data.id, value}).then(res => {
      reload()
      if (value) {
        const l = window.document.location
        const link = l.protocol + '//' + l.host + '/forgot-password'
        const linkemail = (!data.users_email || '/' + encodeURIComponent(data.users_email))
        const email = (!data.users_email || '/' + data.users_email)

        confirmDialog({
          header: 'Nieuw Wachtwoord Aanmaken',
          message: <>
            Klik op onderstaande link <br/><br/>
            <a href={link + linkemail} target="_blank" rel="noreferrer">{link + email}</a><br/><br/>
            om een nieuw wachtwoord aan te maken.<br/><br/>
          </>,
          acceptLabel: 'OK', rejectLabel: 'Sluiten'
        })


      }
    }).catch(e => {
      toast.current.show({life: 5000, severity: 'error', summary: 'Invoer fout', detail: e.response.data.message})
    })

  }

  const addDebtorCode = () => {

    confirmDialog({
      header: 'Debtor Code Toevoegen',
      message: <>
        Je staat op het punt de debtor code aan te maken voor deze ondernemer.<br/>
        Vergeet niet de Commissie in te vullen.<br/><br/>
        Weet je dit zeker?
      </>,
      acceptLabel: 'Ja',
      rejectLabel: 'Nee',
      accept: () => {
        axios.post('/allyourz/entrepreneurs-add-debtor-code', {entrepreneur_id: data.id}).then(res => {
          reload()
        }).catch(e => {
          toast.current.show({life: 5000, severity: 'error', summary: 'Invoer fout', detail: e.response.data.message})
        })
      }
    });
  }

  return <>
    <Toast ref={toast} position="top-left"/>
    <div className="grid">
      <div className="col-6">
        <div className="grid">
          <div className="col-4 fieldname">Naam</div>
          <div className="col-8">
            <InputText value={data.name || ''} onChange={(e) => setData({...data, name: e.target.value})}/>
          </div>
        </div>
        <div className="grid">
          <div className="col-4 fieldname">Vertegenwoordiger</div>
          <div className="col-8">
            <InputText value={data.representative_name || ''} onChange={(e) => setData({...data, representative_name: e.target.value})}/>
          </div>
        </div>
        <div className="grid">
          <div className="col-4 fieldname">Telefoon Nummer</div>
          <div className="col-8">
            <InputText value={data.telephone_number || ''} onChange={(e) => setData({...data, telephone_number: e.target.value})}/>
          </div>
        </div>
        <div className="grid">
          <div className="col-4 fieldname">Login</div>
          <div className="col-8">
            <InputText disabled={true} value={data.users_email || ''}/>
          </div>
        </div>
        <div className="grid">
          <div className="col-4 fieldname">Email</div>
          <div className="col-8">
            <InputText autoComplete="nope" value={data.email || ''} onChange={(e) => setData({...data, email: e.target.value})}/>
          </div>
        </div>
        <div className="grid">
          <div className="col-4 fieldname">Billing Email</div>
          <div className="col-8">
            <InputText autoComplete="nope" value={data.billing_email || ''} onChange={(e) => setData({...data, billing_email: e.target.value})}/>
          </div>
        </div>


        <div className="grid" style={{marginTop: '0.5rem'}}>
          <div className="col-4 fieldname">Straat</div>
          <div className="col-8">
            <InputText value={data.streetname || ''} onChange={(e) => setData({...data, streetname: e.target.value})}/>
          </div>
        </div>
        <div className="grid">
          <div className="col-4 fieldname">Huisnummer</div>
          <div className="col-8">
            <InputText value={data.housenumber || ''} onChange={(e) => setData({...data, housenumber: e.target.value})}/>
          </div>
        </div>
        <div className="grid">
          <div className="col-4 fieldname">Postcode</div>
          <div className="col-8">
            <InputText value={data.zip || ''} onChange={(e) => setData({...data, zip: e.target.value})}/>
          </div>
        </div>
        <div className="grid">
          <div className="col-4 fieldname">Plaatsnaam</div>
          <div className="col-8">
            <InputText value={data.city || ''} onChange={(e) => setData({...data, city: e.target.value})}/>
          </div>
        </div>
        <div className="grid">
          <div className="col-8 col-offset-4">
            <Button label="Save" onClick={() => save(data)}/>
          </div>
        </div>
        {user.field('sys.remark').view && <div className="grid mt-4">
          <div className="col-4 fieldname">
            Opmerking(en)
          </div>
          <div className="col-8">
            <Remarks
              id={data.id}
              style={{fontSize: '0.9rem'}}
              data={data.remarks}
              rows={6}
              onSave={reload}
            />
          </div>
        </div>}
      </div>
      <div className="col-6">
        <div className="grid">
          <div className="col-4 fieldname">KVK Nummer</div>
          <div className="col-8">
            <InputText className="text-right" value={data.chamber_of_commerce_number || ''} onChange={(e) => setData({...data, chamber_of_commerce_number: e.target.value})}/>
          </div>
        </div>
        <div className="grid">
          <div className="col-4 fieldname">BTW Nummer</div>
          <div className="col-8">
            <InputText value={data.vat_number || ''} onChange={(e) => setData({...data, vat_number: e.target.value})}/>
          </div>
        </div>

        {user.field('ent.stripe_id').view &&
          <div className="grid">
            <div className="col-4 fieldname">Stripe id</div>
            <div className="col-8">
              <InputText
                disabled={!user.field('ent.stripe_id').edit}
                value={data.stripe_id || ''}
                onChange={(e) => setData({...data, stripe_id: e.target.value})}/>
            </div>
          </div>}

        {user.field('ent.stripe_status').view && data.stripe_status &&
          <div className="grid">
            <div className="col-4 fieldname">Stripe status</div>
            <div className="col-8">
              <StatusButton status={data.stripe_status}/>
            </div>
          </div>}

        {user.field('ent.commission_percentage').view &&
          <div className="grid">
            <div className="col-4 fieldname">Commissie</div>
            <div className="col-8">
              <InputText className="text-right" value={data.commission_percentage || ''} onChange={(e) => setData({...data, commission_percentage: e.target.value})}/>
            </div>
          </div>}
        {user.field('ent.premium_model').view &&
          <div className="grid">
            <div className="col-4 fieldname">Premium Model</div>
            <div className="col-8">
              <Dropdown
                disabled={!user.field('ent.premium_model').edit}
                value={data.premium_model}
                options={data.premium_model_options}
                onChange={(e) => setData({...data, premium_model: e.target.value})}
                style={{width: '100%'}}
              />

            </div>
          </div>}


        {user.field('ent.debtor_code').view &&
          <div className="grid">
            <div className="col-4 fieldname">Debtor Code
              <InfoButton text="Klik hier om een Debtor Code te genereren"
                          icon="pi pi-bolt"
                          onClick={addDebtorCode}
                          style={{marginLeft: '0.5rem'}}/>
            </div>
            <div className="col-8">
              <InputText className="text-right"
                         style={{backgroundColor: '#FFE682'}}
                         readOnly
                         value={data.debtor_code || ''}
                         onChange={(e) => setData({...data, debtor_code: e.target.value})}
              />
            </div>
          </div>}

        {user.field('ent.khn_member').view &&
          <div className="grid">
            <div className="col-4 fieldname">
              KHN lid
            </div>
            <div className="col-8">
              <Checkbox inputId="cb3"
                        disabled={!user.field('ent.khn_member').edit}
                        onChange={(e) => setData({...data, khn_member: e.checked})}
                        checked={!!data.khn_member}/>
              <InfoButton className="ml-2" text="Neem contact op met allyourz.nl om deze aan te laten vinken." size="1.25rem"/>
              {user.field('ent.khn_member').edit &&
                <label style={{marginLeft: '0.5rem'}} htmlFor="cb3" className="p-checkbox-label">
                  Zet aan
                </label>}
            </div>
          </div>}

        {user.field('ent.psp_account_id').view &&
          <div className="grid">
            <div className="col-4 fieldname">PSP Account Id</div>
            <div className="col-8">
              <InputText value={data.psp_account_id || ''} onChange={(e) => setData({...data, psp_account_id: e.checked ? 1 : 0})}/>
            </div>
          </div>}

        {user.field('ent.psp_validation_status').view &&
          <div className="grid">
            <div className="col-8 col-offset-4">
              <Checkbox inputId="cb3"
                        onChange={(e) => setData({...data, psp_validation_status: e.checked})}
                        checked={!!data.psp_validation_status}/>
              <label style={{marginLeft: '0.5rem'}} htmlFor="cb3" className="p-checkbox-label">PSP Gevalideerd</label>
            </div>
          </div>}

        {user.field('ent.src').view &&
          <div className="grid" style={{marginTop: '0.5rem'}}>
            <div className="col-4 fieldname">Bron</div>
            <div className="col-3">
              <Dropdown
                disabled={!user.field('ent.src').edit}

                value={data.src}
                options={[
                  {label: 'Allrez', value: 'allrez'},
                  {label: 'Nextpax', value: 'nextpax'},
                  {label: 'Cubilis', value: 'cubilis'},
                  {label: 'OpenGDS', value: 'opengds'},
                  {label: 'Qenner', value: 'qenner'}
                ]}
                onChange={(e) => setData({...data, src: e.target.value})}
                placeholder="Selecteer een bron"
                style={{width: '100%'}}
              />
            </div>
            <div className="col-2 fieldname text-right">PMS</div>
            <div className="col-3">
              <InputText
                value={data.pms_name || ''}
                onChange={(e) => setData({...data, pms_name: e.target.value})}
              />
            </div>
          </div>}

        {user.field('ent.external_id').view &&
          <div className="grid">
            <div className="col-4 fieldname">External Id</div>
            <div className="col-8">
              <InputText value={data.external_id || ''}
                         autoComplete="nope"
                         onChange={(e) => setData({...data, external_id: e.target.value})}/>
            </div>
          </div>}
        {user.field('ent.external_id_password').view &&
          <div className="grid">
            <div className="col-4 fieldname">Extern Wachtwoord</div>
            <div className="col-8">
              <Password
                feedback={false}
                toggleMask={true}
                autoComplete="new-password"
                value={data.external_id_password || ''}
                onChange={(e) => setData({...data, external_id_password: e.target.value})}/>
            </div>
          </div>}


        {user.field('ent.is_active').view &&
          <div className="grid">
            <div className="col-8 col-offset-4">
              <Checkbox inputId="cb1"
                        onChange={(e) => setData({...data, is_active: e.checked})}
                        checked={!!data.is_active}/>
              <label style={{marginLeft: '0.5rem'}} htmlFor="cb1" className="p-checkbox-label">Is Active</label>
            </div>
          </div>}

        {user.field('ent.exclusivity').view &&
          <div className="grid">
            <div className="col-8 col-offset-4">
              <Checkbox inputId="cb2"
                        onChange={(e) => setData({...data, exclusivity: e.checked})}
                        checked={!!data.exclusivity}/>
              <label style={{marginLeft: '0.5rem'}} htmlFor="cb2" className="p-checkbox-label">Exclusivity</label>
            </div>
          </div>}

        {user.field('ent.sequence').view &&
          <div className="grid">
            <div className="col-4 fieldname">Sequence</div>
            <div className="col-8">
              <InputText className="text-right" value={data.sequence || ''} readOnly={true}/>
            </div>
          </div>}
        {user.field('sys.skip_2fa').view &&
          <Card>
            <div className="grid">
              <div className="col-4 fieldname">Two Factor Authenticatie</div>
              <div className="col-8 mt-1">
                <Checkbox inputId="cb4"
                          onChange={(e) => {
                            disable2FA(!e.checked)
                          }}
                          checked={!data.skip_2fa}/>
                <label style={{marginLeft: '0.5rem'}} htmlFor="cb4" className="p-checkbox-label">{data.skip_2fa ? 'Zet Aan' : ''}</label>

              </div>
            </div>
          </Card>}
      </div>

    </div>
  </>
}
