import {useContext, useEffect, useState} from "react";
import {UserContext} from "../contexts/UserContext";
import axios from "axios";
import mapToOptions from "../services/mapToOptions";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {classNames} from 'primereact/utils'

export default function Properties({entity_id}) {
  const context = useContext(UserContext)
  const [properties, setProperties] = useState([])
  const [propertyMap, setPropertyMap] = useState({})
  const [propertyMapOptions, setPropertyMapOptions] = useState([])

  const [editMode, setEditMode] = useState(-1)
  const [dirty, setDirty] = useState(false)

  const setData = (res) => {
    setProperties(res.data.properties)
    setPropertyMap(res.data.property_map)
    setPropertyMapOptions(mapToOptions(res.data.property_map))
    setDirty(false)
    setEditMode(-1)
  }

  useEffect(() => {
    axios.get(`/allyourz/properties-by-entity-id?entity_id=${entity_id}`).then(res => {
      setData(res)
    })
  }, [entity_id, context.language])

  const update = (index, field, val) => {
    const clone = [...properties]
    clone[index][field] = val
    setProperties(clone)
    setDirty(true)
  }

  function saveClicked() {
    axios.put(`/allyourz/properties-by-entity-id?entity_id=${entity_id}`, properties).then(res => {
      setData(res)
    })
  }

  const newClicked = () => {
    const c = [...properties]
    c.push({id: null, property_id: null, quantity: 1, entity_id: null})
    setProperties(c)
    setEditMode(c.length - 1)
  }

  function cancelClicked() {
    axios.get(`/allyourz/properties-by-entity-id?entity_id=${entity_id}`).then(res => {
      setData(res)
    })
  }

  function removeClicked(index) {
    const c = [...properties]
    c.splice(index, 1)
    setProperties(c)
    setDirty(true)
  }

  function row(data, index) {
    return <div key={index} className="grid">
      <div className="col-1">
        <Button
          disabled={(editMode !== -1) && (editMode !== index)}
          className={classNames({'p-button-success': editMode === index})}

          icon={classNames('pi pi-pencil', {'pi-check': editMode === index})}
          onClick={() => {
            if (editMode === -1) {
              setEditMode(index)
            } else {
              setEditMode(-1)
            }
          }}/>
      </div>

      {/* Addition naam */}
      <div className={classNames('col-2', {pt1rem: editMode !== index})}>
        {editMode === index ? <Dropdown
            options={propertyMapOptions}
            value={'_' + data.property_id}
            onChange={(e) => {
              update(index, 'property_id', e.value.substring(1))
            }}
          /> :
          propertyMap['_' + data.property_id]}
      </div>

      {/* bedrag */}
      <div className={classNames('col-1 text-right', {pt1rem: editMode !== index})}>
        {editMode === index ?
          <InputText className="text-right"
                     value={data.quantity}
                     onChange={(e) => {
                       update(index, 'quantity', e.target.value)
                     }}/> : data.quantity}
      </div>

      {/* delete */}
      <div className="col-1">
        <Button
          icon="pi pi-times"
          style={{float: 'right'}}
          className="p-button-outlined p-button-danger"
          onClick={() => {
            removeClicked(index)
          }}/>
      </div>
    </div>
  }

  return <>
    <div className="add-ons">
      <div className="grid text-center font-bold">
        <div className="col-1"></div>
        <div className="col-2">Eigenschap</div>
        <div className="col-1">Aantal</div>
        <div className="col-1"></div>
      </div>

      {/* Properties */}
      {properties.map((e, i) => row(e, i))}

      {/* Buttons */}
      <div className="grid" style={{marginTop: '1rem'}}>
        <div className="col-1">
          <Button
            label="Nieuw"
            disabled={editMode !== -1}
            className="p-button-secondary"
            onClick={() => {
              newClicked()
            }}/>
        </div>

        <div className="col-1">
          <Button
            label="Save"
            disabled={!dirty}
            className="p-button-success"
            onClick={() => {
              saveClicked()
            }}/>
        </div>
        <div className="col">
          <Button
            style={{float: 'right'}}
            label="Cancel"
            disabled={!dirty}
            className="p-button-warning"
            onClick={() => {
              cancelClicked()
            }}/>
        </div>
      </div>
    </div>
  </>
}
