import {useEffect, useState} from "react";
import {Fieldset} from "primereact/fieldset";
import {TabPanel, TabView} from "primereact/tabview";
import {Button} from "primereact/button";
import axios from "axios";
import Particulier from "./Particulier";
import Zakelijk from "./Zakelijk";

const indexMap = {0: 'individual', 1: 'business'}

export default function Dac7form({entrepreneur_id}) {
  const [data, setData] = useState({})
  const [countries, setCountries] = useState([])
  const [activeIndex, setActiveIndex] = useState(null);
  const [reload, setReload] = useState(false)
  const [thankYou, setThankYou] = useState(false)

  useEffect(() => {
    axios.get('/allyourz/dac7-get', {params: {entrepreneur_id}}).then(res => {
      setData(res.data.data)
      setCountries(res.data.countries)
      setActiveIndex(res.data.data.type === 'business' ? 1 : 0)
    })
  }, [entrepreneur_id, reload]);

  const save = () => {
    const postData = {...data}
    postData.type = indexMap[activeIndex]
    axios.post('/allyourz/dac7-post', postData).then(res => {
      setData(res.data.data)
      setActiveIndex(res.data.data.type === 'business' ? 1 : 0)
      setThankYou(true)
    }).catch(err => {
      for (let key in err.response.data.errors) {
        if (key === 'truth') {
          document.getElementsByClassName('truth')[0].classList.add('ay-invalid')
        } else {
          document.getElementById(key).classList.add('ay-invalid')
        }
      }
    })
  }

  return (

    <Fieldset legend="DAC7">
      {activeIndex !== null && <>
        {/* FORMS */}
        <TabView activeIndex={activeIndex} onTabChange={e => {
          setActiveIndex(e.index)
        }}>

          <TabPanel header="Particulier">
            {!thankYou ? <>
              <Particulier data={data} setData={setData} countries={countries}/>
              * Het kadastrale nummer kun je eenvoudig vinden
              via <a href="https://mijn.overheid.nl/login/" target="_blank" rel="noreferrer">MijnOverheid</a> (inloggen
              met DigiD)
              <br/>op de akte van jouw accommodatie/s. Neem gerust contact met ons op als het niet lukt.
            </> :
              <>Bedankt voor het aanvullen van de benodigde data.</>
            }
          </TabPanel>

          <TabPanel header="Zakelijk" pt={{header: {className: 'ml-2'}}}>
            {!thankYou ? <>
              <Zakelijk data={data} setData={setData} countries={countries}/>
              * Het kadastrale nummer kun je vinden op de akte van jouw accommodatie/s of via de kadasterwebsite van
              jouw gemeente.
            </> :
              <>Bedankt voor het aanvullen van de benodigde data.</>
            }
          </TabPanel>
        </TabView>

        {/* BUTTONS */}
        {!thankYou && <div className="grid mt-1">
          <div className="col-8">
            <Button label="Annuleren"
                    onClick={() => {
                      const a = document.getElementsByClassName('ay-invalid')
                      while (a.length) {
                        a[0].classList.remove('ay-invalid')
                      }
                      setReload(!reload)
                    }
                    }
                    severity="warning"
                    className="p-button-success w-4"/>
            <Button className="p-button-success float-right w-4"
              // disabled={!dirty || !data.truth}
                    label="Opslaan"
                    onClick={() => {
                      save()
                    }}/>
          </div>
        </div>}
      </>}
    </Fieldset>

  )


}