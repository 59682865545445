import React, {useState} from 'react'

const BreadcrumbContext = React.createContext({});

const BreadcrumbContextProvider = ({children}) => {

  const [model, setModel] = useState([])

  const entItem = (label, id) => {
    return {label, url: `/allyourz/entrepreneur/${id}`}
  }
  const admItem = (label, id) => {
    return {label, url: `/allyourz/administration/${id}`}
  }
  const boItem = (label) => {
    return {label}
  }

  const setEntrepreneur = (ent_id, ent_name) => {
    setModel([
      entItem(ent_name,ent_id)
    ])
  }
  const setAdministration = (ent_id, ent_name, adm_id, adm_name) => {
    setModel([
      entItem(ent_name, ent_id),
      admItem(adm_name, adm_id)
    ])
  }
  const setBookable = (ent_id, ent_name, adm_id, adm_name, boo_name) => {
    setModel([
      entItem(ent_name,ent_id),
      admItem(adm_name, adm_id),
      boItem(boo_name)
    ])

  }

  const clearBookable = () => {
    const m = [...model]
    if (m.length === 3) {
      m.pop()
    }
    setModel(m)
  }

  return <BreadcrumbContext.Provider value={{
    model,
    // setModel,
    setEntrepreneur,
    setAdministration,
    setBookable,
    clearBookable,

  }}>
    {children}
  </BreadcrumbContext.Provider>
}

export {BreadcrumbContext, BreadcrumbContextProvider}
