import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {UserContext} from "../contexts/UserContext";
import shortenText from "../services/shortenText";

export default function AdministrationTypes({entrepreneur_id}) {
  const context = useContext(UserContext)
  const [data, setData] = useState([])
  const [length, setLength] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if(entrepreneur_id){
      axios.get('/allyourz/administrations-by-entrepreneur-id', {params:{id:entrepreneur_id}}).then(res => {
        setData(res.data)
        setLength(res.data.length > 10)
      })
    } else {
      axios.get('/allyourz/administration-all').then(res => {
        setData(res.data)
        setLength(res.data.length > 10)
      })
    }

  }, [entrepreneur_id, context.language])

  return <DataTable value={data}
                    editMode="row"
                    dataKey="id"
                    rows={15}
                    paginator={true}
                    sortMode="multiple"
                    removableSort={true}
                    filterDisplay={length ? 'row': false}
                    stripedRows
                    onRowClick={e => {
                      navigate('/allyourz/administration/' + e.data.id)
                    }}
                    rowHover={true}
                    style={{marginTop: '5px'}}>
    {/*=======================================================*/}
    <Column field="name" header="Naam"
            sortable={length}
            filter={length}
            filterMatchMode={"contains"}
            style={{width: '30%'}}
            body={row => <div style={{cursor: 'pointer'}}>
              {shortenText(row.name,45,true)}
            </div>}
    />
    <Column field="city" header="Plaats"
            sortable={length}
            filter={length}
            filterMatchMode={"contains"} style={{width: '12.5%'}}/>
    <Column field="src" header="Bron"
            sortable={length}
            filter={length}
            filterMatchMode={"contains"} style={{width: '10%', textAlign: 'center'}}/>
    <Column field="slug" header="Type"
            sortable={length}
            filter={length}
            filterMatchMode={"contains"} style={{width: '10%', textAlign: 'center'}}/>

    {true &&
    <Column field="entrepreneur"
            header="Ondernemer"
            filter={length}
            sortable={length}
            filterMatchMode={"contains"}
            body={(row) => {
              return <div
                style={{cursor: 'pointer'}}
                onClick={(e) => {
                  navigate(`/allyourz/entrepreneur/${row.entrepreneur_id}`)
                  e.stopPropagation()
                }}>
                <Button className="p-button-outlined"
                        style={{width: '1.2rem', height: '1.2rem', marginRight: '0.5rem'}}
                        icon="pi pi-arrow-up-right"/>
                {shortenText(row.entrepreneur,25,true)}
              </div>
            }}
    />}

    <Column field="is_acp_m" header="A/C/P Main" filter={true} style={{width: '7rem', textAlign: 'center'}}/>
    <Column field="active_bookables" header="B's" sortable style={{width: '6%', textAlign: 'center'}}/>

  </DataTable>

}
