import Menu from "../../components/Menu";

import {Fieldset} from "primereact/fieldset";

import BookablesList from "../../components/bookable/BookablesList";
import {useParams} from "react-router-dom";

export default function PageBookablesAll() {

  const params = useParams()

  return (
    <div className="page bookables-all">

      <Menu/>

      <Fieldset legend="Alle Bookables">
        <BookablesList entrepreneur_id={params.entrepreneur_id}/>
      </Fieldset>
    </div>
  )

}
