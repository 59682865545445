import {useContext} from 'react'

import {Fieldset} from "primereact/fieldset";
import Menu from "../../components/Menu";
import {UserContext} from "../../contexts/UserContext";

export default function PageUser() {

  const user = useContext(UserContext)

  return (
    <div className="page user">
      <Menu/>
      <Fieldset legend="User">
        {
          Object.entries(user.user_fields).map((e, i) => {
            return <div className="grid" key={i}>
              <div className="col-3">{e[0]}</div>
              <div className="col-9">{e[1]}</div>
            </div>
          })
        }
      </Fieldset>
      <Fieldset legend="Roles">
        {
          user.user_roles.map(item => {
            return <div className="grid" key={item}>
              <div className="col-12">{item}</div>
            </div>
          })
        }
      </Fieldset>
    </div>
  )


}


