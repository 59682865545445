import {Card} from "primereact/card";
import {Calendar} from 'primereact/calendar';
import {useState} from "react";
import {Button} from "primereact/button";
import {Image} from "primereact/image";
import axios from "axios";
import {saveAs} from 'file-saver';
import getYmd from "../../services/getYmd";


export default function Bookings() {
  const [dateFrom, setDateFrom] = useState(new Date(new Date().getFullYear() - 1, 0, 1))
  const [dateUntil, setDateUntil] = useState(new Date(new Date().getFullYear(), 11, 31))
  const [loading, setLoading] = useState(false)

  const download = () => {
    setLoading(true)
    axios.get(`allyourz/download-bookings?from=${getYmd(dateFrom)}&until=${getYmd(dateUntil)}`, {responseType: "blob"}).then(res => {
      saveAs(res.data, 'bookings-' + getYmd(dateFrom) + '-' + getYmd(dateUntil) + '.xls');
      setLoading(false)
    })
  }

  return <Card>
    <div className="grid">
      <div className="col-offset-2 col-2 mt-2 font-bold">Van</div>
      <div className="col-4">
        <Calendar
          showIcon
          readOnlyInput
          dateFormat="yy-mm-dd"
          value={dateFrom}
          onChange={(e) => setDateFrom(e.value)}/>
      </div>
    </div>
    <div className="grid">
      <div className="col-offset-2 col-2 mt-2 font-bold">Tot</div>
      <div className="col-4">
        <Calendar
          showIcon
          readOnlyInput
          dateFormat="yy-mm-dd"
          value={dateUntil}
          onChange={(e) => setDateUntil(e.value)}/>
      </div>
    </div>
    <div className="grid">
      <div className="col-offset-2 col-2 mt-2 font-bold"></div>
      <div className="col-4">
        <Button
          style={{width: '14rem'}}
          label="Download"
          iconPos="right"
          loading={loading}
          icon={<Image width={'25px'} height={'25px'} src="/media/icons/excel-logo.svg"/>}
          onClick={() => download()}
        />
      </div>
    </div>

  </Card>
}
