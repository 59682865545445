import getYmd from "../../../services/getYmd";
import money from "../../../services/money";
import {classNames} from "primereact/utils";

export default function dateTemplate(d,dataAvailable){

  const day = dataAvailable[getYmd(d)];

  const quantity = day?.quantity !== undefined ? day.quantity : ''
  const total_subs = day?.all_subs
  const rate = day?.rate ? money.toEuro(day?.rate, true) : ''
  const rate_medium = day?.rate_medium ? money.toEuro(day?.rate_medium, true) : ''
  const rate_low = day?.rate_low ? money.toEuro(day?.rate_low, true) : ''

  return <div
    className="datefield">
    <div className={classNames('sm lt total')}>{total_subs !== 0 ? total_subs : ''}</div>
    <div className={classNames('sm rt', {zero: quantity === 0})}>{quantity}</div>
    <div className={classNames('day', {'hasnote': !!day?.note})}>{d.day}{day?.note ? '!' : ''}</div>
    <div className={classNames('sm rb', {'font-bold': rate_low || rate_medium})}>{rate}</div>
  </div>

}
