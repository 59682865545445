import Menu from "../../components/Menu";
import {Fieldset} from "primereact/fieldset";
import {Accordion, AccordionTab} from 'primereact/accordion';

import {useState} from "react";
import Bookings from "../../components/reports/Bookings";
import ExactCommissionExport from "../../components/reports/ExactCommissionExport";
import ExactRelationExport from "../../components/reports/ExactRelationExport";
import NextpaxAudit from "../../components/reports/NextpaxAudit";
import NextpaxSyncLog from "../../components/reports/NextpaxSyncLog";
import Entrepreneurs from "../../components/reports/Entrepreneurs";
import BookableWithAvailability from "../../components/reports/BookableWithAvailability";


export default function PageReports() {
  const [index, setIndex] = useState(null)
  return <div className="page all-reports">

    <Menu/>

    <Fieldset legend="Rapportages">
      <Accordion activeIndex={index} onTabChange={(e) => setIndex(e.index)}>
        <AccordionTab tabIndex={0} header="Boekingen">
          <Bookings/>
        </AccordionTab>
        <AccordionTab tabIndex={1} header="Exact Relation Export">
          <ExactRelationExport/>
        </AccordionTab>
        <AccordionTab tabIndex={2} header="Exact Commision Export">
          <ExactCommissionExport/>
        </AccordionTab>
        <AccordionTab tabIndex={3} header="Nextpax Audit">
          <NextpaxAudit/>
        </AccordionTab>
        <AccordionTab tabIndex={4} header="Nextpax Sync Error Log">
          <NextpaxSyncLog/>
        </AccordionTab>
        <AccordionTab tabIndex={5} header="Actieve Bookables Met Beschikbaarheid">
          <BookableWithAvailability/>
        </AccordionTab>
        <AccordionTab tabIndex={6} header="Ondernemers">
          <Entrepreneurs/>
        </AccordionTab>
      </Accordion>
    </Fieldset>
  </div>
}
