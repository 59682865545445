import Menu from "../components/Menu";
import {Fieldset} from "primereact/fieldset";
import {Messages} from "primereact/messages";
import {useRef, useState} from "react";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import axios from "axios";
import {useParams} from "react-router-dom";

export default function PageForgotPassword(){
  const params = useParams()
  const messages = useRef(null)
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)
  const [email,setEmail] = useState(params.email || '')

  const submit = () => {
    setSending(true)
    setSent(true)
    axios.post('/auth/reset-request',{email}).then(res => {
      messages.current.show({
        severity: 'success',
        detail: <>
          Een email is verstuurd naar het e-mail adres {email}
          <br/>
          <br/>
          Volg de link in de e-mail om het wachtwoord opnieuw in te stellen.
        </>,
        closable: false,
        sticky: true
      });
      setSending(false);
    }).catch(e => {
      setSending(false);
      setSent(false);
      messages.current.show({
        severity: 'error',
        detail: Object.entries(e.response.data.errors).map((e,i) => <div key={i}>{e[1][0]}</div>),
      });

    })
  }

  return (
    <div className="page login">
      <Menu/>
      <Fieldset legend="Wachtwoord vergeten" style={{fontSize: '1.3rem'}}>

        <div className="Login grid">
          <div className="xl:col-4"/>
          <div className="xl:col-4">
            <Card footer={<Button
              label="Verstuur"
              disabled={sent}
              icon={sending ? 'pi pi-spinner pi-spin' : null}
              onClick={() => submit()}
              style={{marginRight: '.25em'}}/>}>

              <form>
                <div className="grid">
                  <div className="col-12">
                    <div className="p-inputgroup">
                      <div className="p-inputgroup-addon">
                        <i className="pi pi-user"></i>
                      </div>
                      <InputText value={email}
                                 placeholder="E-mail"
                                 onChange={(e) => {setEmail(e.target.value)}}
                                 style={{width: '100%'}}/>
                    </div>
                  </div>
                </div>
              </form>
            </Card>


            <Messages ref={messages}/>
          </div>
          <div className="xl:col-4"/>
        </div>
      </Fieldset>
    </div>
  )

}
