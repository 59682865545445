import {TabPanel, TabView} from "primereact/tabview";
import {useEffect, useRef, useState} from "react";
import {AutoComplete} from 'primereact/autocomplete';
import axios from "axios";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {confirmDialog} from "primereact/confirmdialog";

const tabMap = [ 'other', 'mine' ]

export default function Tips({administration_id}) {
  const ref = useRef()
  const [activeTab, setActiveTab] = useState(0)
  const [allAdmins, setAllAdmins] = useState([]) // just all admins. does not change
  const [value, setValue] = useState('');
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);
  const [hints, setHints] = useState([])
  const [reload, setReload] = useState(false)
  useEffect(() => {
    axios.get('/allyourz/administrations').then(res => {
      setAllAdmins(res.data.map(e => {
        return {...e, query: e.name.toLowerCase()} // ser query for case insensitive search
      }))
    })
  }, []);


  useEffect(() => {

    axios.get('/allyourz/tips', {
      params: {
        entity_id: administration_id,
        type: tabMap[activeTab]
      }
    }).then(res => {
      setHints(res.data)
    })


  }, [activeTab, administration_id, reload]);


  const search = (e) => {
    const query = e.query.toLowerCase()
    setItems(allAdmins.filter(a => {
      return a.query.includes(query)  // is case insensitive, see above
    }).map(e => {
      return {name: e.name, id: e.id, ent: e.ent_name}
    }))
  }

  const save = (type) => {
    axios.post('/allyourz/insert-delete-tips', {
      insert_delete: 'insert',
      type,
      entity_id: administration_id,
      hint_id: selected.id
    }).then(() => {
      setReload(!reload)
    })
  }
  const deleteHint = (hint_id, name) => {
    confirmDialog({
      header: 'Hint verwijderen',
      message: <>
        je staat op het punt '{name}' te verwijderen als tip.<br/><br/>
        Weet je dit zeker?
      </>,
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'Nee',
      acceptLabel: 'Ja',
      accept: () => {
        axios.post('/allyourz/insert-delete-tips', {
          insert_delete: 'delete',
          type: tabMap[activeTab],
          entity_id: administration_id,
          hint_id
        }).then(() => {
          setReload(!reload)
        })
      }
    });
  }

  const myAutoComplete = () => {
    return <div className="grid">
      <div className="col-12 mt-1">
        Type (een deel) de naam van de aanbieder, selecteer en klik 'Voeg toe'.
      </div>
      <div className="col-6">
        <AutoComplete
          autoFocus
          inputRef={ref}
          dropdown
          className="w100"
          field="name"
          value={value}
          suggestions={items}
          completeMethod={search}
          onClear={() => {
            setSelected(null)
          }}
          onSelect={(e) => {
            setSelected(e.value)
          }}
          onChange={(e) => {
            setValue(e.value)
          }}/>
      </div>

      <div className="col-3">
        <Button className="w100"
                disabled={!selected}
                severity="success"
                label="Voeg toe"
                onClick={() => {
                  save(tabMap[activeTab])
                  setSelected(null)
                  setValue('')
                  setItems([])
                  ref.current.focus()
                }}
        />
      </div>

      <div className="col-3">
        <Button className="w100"
                disabled={!selected}
                severity="warning"
                label="Annuleer"
                onClick={() => {
                  setSelected(null)
                  setValue('')
                  setItems([])
                  ref.current.focus()
                }}
        />
      </div>
    </div>
  }

  const myData = () => {
    return <div className="grid">
      <div className="col-12">
        <DataTable value={hints}>
          <Column
            style={{width: '50px'}}
            className="text-center"
            body={row => {
              return <>
                <Button
                  rounded
                  outlined
                  severity="danger"
                  icon="pi pi-times"
                  onClick={() => {
                    deleteHint(row.hint_id, row.admin_name)
                  }}
                />
              </>
            }}/>
          <Column style={{width:'50%'}} field="admin_name" header="Administratie"/>
          <Column field="entre_name" header="Ondernemer"/>

        </DataTable>
      </div>
    </div>
  }


  return <>
  <TabView activeIndex={activeTab} onTabChange={
      (e) => {
        setItems([])
        setValue('')
        setActiveTab(e.index)
      }
    }>
      <TabPanel header="Tips van deze aanbieder">
        {myAutoComplete('other')}
        {myData('other')}
      </TabPanel>
    <TabPanel header="Meer van deze aanbieder">
      {myAutoComplete('mine')}
      {myData('mine')}
    </TabPanel>

    </TabView>
  </>
}