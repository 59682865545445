import {Button} from "primereact/button";
import {classNames} from "primereact/utils";

export default function StatusButton({status, onClick, resolved_by, manual_success_by, noLabel, width}) {

  let className, icon
  if (!status) {
    // Stripe only
    status = 'n.v.t.'
    icon = 'pi pi-ellipsis-h'
  } else {
    icon = resolved_by ? 'pi pi-wrench' : 'pi pi-external-link'
  }

  switch (status) {
    case 'open':
    case 'processing':
    case 'pending':
      className = 'p-button-warning'
      break
    case 'success':
    case 'active':
      className = 'p-button-success'
      break
    case 'cancelled':
      className = 'p-button-info'
      break
    case 'failed':
      className = resolved_by ? (manual_success_by ? 'p-button-success' : 'p-button-help') : 'p-button-danger'
      status = status + (
        manual_success_by ? ' maar manueel afgerond' :
        (resolved_by ? ' resolved' : '')
      )
      break
    case 'expired':
    case 'inactive':
    case 'unrequested':
    case 'unknown':
      className = 'p-button-danger'
      break
    default:
      className = 'p-button-secondary'
  }

  return <Button
    className={classNames(className, {disabled: !onClick})}
    label={!noLabel && status}
    title={status}
    onClick={onClick}
    icon={icon}
  />
}
