import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {useEffect, useState} from "react";
import axios from "axios";
import {Button} from "primereact/button";
import {saveAs} from 'file-saver';
import getYmd from "../../services/getYmd";
export default function ManageDac7() {

  const [data, setData] = useState([])
  const [working, setWorking] = useState(false)

  useEffect(() => {
    axios.get('/allyourz/manage-dac7').then(res => {
      setData(res.data)
    })
  },[])

  const download = (row) => {
    axios.get('allyourz/manage-dac7-download?id=' + row.id, {responseType: "blob"}).then(res => {
      saveAs(res.data, 'dac7-' + row.id + '.xml')
      setWorking(false)
    })

  }

  const create = () => {
    setWorking(true)
    axios.post('/allyourz/manage-dac7-create-dump').then(res => {
      setWorking(false)
      setData(res.data)
    })
  }

  return <>
    <DataTable value={data}>
      <Column field="id" header="ID"/>
      <Column field="created_at" header="Created"/>
      <Column body={row=>{
        return <Button label="Download" onClick={() => download(row)}/>
      }}/>
    </DataTable>
    <div className="mt-4">
      <Button label="Create NEW DAC7 file"
              disabled={working}
              loading={working}
              onClick={create}/>
    </div>
  </>
}