import {useContext, useEffect, useState} from "react";

import axios from "axios";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import money from "../../services/money";
import {Button} from "primereact/button";
import {Sidebar} from 'primereact/sidebar';
import Booking from "./Booking";
import {Dialog} from "primereact/dialog";
import BookPayload from "./BookPayload";
import {UserContext} from "../../contexts/UserContext";
import StatusButton from "./StatusButton";
import getDays from "../../services/getDays";
import Search from "./Search";


export default function Bookings({entrepreneur_id, administration_id}) {

  const user = useContext(UserContext)
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({})
  const [totalRecords, setTotalRecords] = useState(0);
  const [sbVisible, setSbVisible] = useState(false)

  const [searchData, setSearchData] = useState({})
  const [searchVisible, setSearchVisible] = useState(false)
  const [searchOn, setSearchOn] = useState(false)
  const [searchString, setSearchString] = useState('')

  const [popReservationId, setPopReservationId] = useState(false)
  const [popVis, setPopVis] = useState(false)
  const [popHead, setPopHead] = useState('')

  const [sbData, setSbData] = useState({})

  const [lazy, setLazy] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: ['bookable_name'],
    sortOrder: [-1]
  });


  useEffect(() => {
    const downHandler = (e) => {

      if (e.ctrlKey && e.key.toUpperCase() === 'F') {
        e.preventDefault();
        setSearchVisible(true);
      }
      if (e.ctrlKey && e.key.toUpperCase() === 'C') {
        e.preventDefault();
        setSearchData({})
      }

    }
    window.addEventListener("keydown", downHandler)
    return () => window.removeEventListener("keydown", downHandler)
  }, []);


  /**
   * Get things like PID (Partner IDS) and all possible statuses (success, failed, etc.)
   */
  useEffect(() => axios.get('/allyourz/bookings-meta').then(res => setMeta(res.data)), [])

  /**
   * Runs when searchData changes
   */
  useEffect(() => {
    const hasFilter = Object.keys(searchData).length > 0
    setSearchOn(hasFilter)
    if (hasFilter) {
      let a = []
      /**
       * Sets the search string
       */
      Object.entries(searchData).forEach(([key, value]) => {
        switch (key) {
          case 'administration':
            value = value.name
            break;
          case 'entrepreneur':
            value = value.name
            break;
          case 'manual':
            value = value ? 'Manueel afgehandeld' : 'Niet manueel afgehandeld'
            break;
          case 'resolved':
            value = value ? 'Opgelost' : 'Niet opgelost'
            break;
          case 'payment':
            value = value ? 'Betaling nodig' : 'Geen betaling nodig'
            break;
          default:
          //
        }
        a.push(`${value}`)
      })
      setSearchString(a.join(', '))
    } else {
      setSearchString('')
    }
  }, [searchData])

  // This one is called from the search sidebar (is a parameter to it)
  const runSearch = (sidebarData) => {
    setSearchData(sidebarData)
    setSearchVisible(false)
  }

  const loadLazyData = () => {

    const myLazy = Object.assign({}, lazy)

    delete myLazy.filters
    delete myLazy.sortField
    delete myLazy.sortOrder

    if (administration_id) {
      axios.post('/allyourz/bookings-by-administration-id', {administration_id, lazy: myLazy, searchData}).then(res => {
        setData(res.data.result)
        setTotalRecords(res.data.count)
      })
    } else if (entrepreneur_id) {
      axios.post('/allyourz/bookings-by-entrepreneur-id', {entrepreneur_id, lazy: myLazy, searchData}).then(res => {
        setData(res.data.result)
        setTotalRecords(res.data.count)
      })
    } else {
      axios.post('/allyourz/bookings-all', {lazy: myLazy, searchData}).then(res => {
        setData(res.data.result)
        setTotalRecords(res.data.count)
      })
    }
  }

  useEffect(() => {
    loadLazyData()
  }, [lazy, searchData]) // eslint-disable-line react-hooks/exhaustive-deps

  const rowClicked = (data) => {
    setSbData(data)
    setSbVisible(true)
  }

  function popMe(row) {
    setPopHead(row.administration_name + ' / ' + row.bookable_name + ' - ' + row.reservation_number)
    setPopReservationId(row.id)
    setPopVis(true)
  }

  const dialogsAndSidebars = () => <>
    <Dialog header={popHead}
            position={'top'}
            visible={popVis}
            style={{width: '80%'}}
            onHide={() => setPopVis(false)}>
      <div>
        <BookPayload
          popHead={popHead}
          id={popReservationId}
          onResolved={() => {
            loadLazyData()
          }}
          closeMe={() => setPopVis(false)}
        />
      </div>
    </Dialog>

    {/*BOOKING DATA*/}
    <Sidebar visible={sbVisible}
             position="right"
             className="p-sidebar-md"
             onHide={() => setSbVisible(false)}>
      <Booking reservation_id={sbData.id}/>
    </Sidebar>

    {/*SEARCH POPUP*/}
    <Dialog header={popHead}
            position={'top'}
            visible={searchVisible}
            style={{width: '60rem'}}
            onHide={() => setSearchVisible(false)}>
      <Search data={searchData}
              meta={meta}
              setData={setSearchData}
              runSearch={runSearch}/>
    </Dialog>

  </>

  return <>
    {dialogsAndSidebars()}

    {/* Search butts */}
    <div className="bookings grid" style={{marginTop: administration_id ? '0' : '-1.75rem'}}>
      <div className="col-10">
        <div className="mt-2">{!searchVisible && (searchString && 'Zoeken op: ' + searchString)}</div>
      </div>
      <div className="col-2 text-right">
        {searchOn && <>
          <Button
            title="Ctrl+C"
            className="ml-2"
            outlined
            severity="danger"
            icon="pi pi-times"
            onClick={() => {
              setSearchData({})
            }}/>
        </>
        }
        <Button
          title="Ctrl+F"
          className="ml-2"
          severity="success"
          icon="pi pi-search"
          onClick={() => {
            setSearchVisible(true)
          }}
        />
      </div>

      <div className="col-12">
        <DataTable value={data}
                   paginator
                   lazy
                   first={lazy.first}
                   rows={lazy.rows}
                   totalRecords={totalRecords}
                   dataKey="id"
                   onPage={(e) => setLazy(e)}
                   sortMode="multiple"
                   removableSort={true}
                   multiSortMeta={lazy.multiSortMeta}
                   onSort={(e) => {
                     setLazy(e)
                   }}
                   onRowClick={e => {
                     rowClicked(e.data)
                   }}

                   rowHover={true}>
          {user.field('sys.show_raw_booking_data').view &&
            <Column field="payload_response"
                    className="text-center w-5rem"
                    header="Status"
                    body={(row) => {
                      if (row.status && row.status !== 'TODO') {
                        return <StatusButton
                          noLabel
                          width="10rem"
                          status={row.status}
                          resolved_by={row.resolved_by}
                          manual_success_by={row.manual_success_by}
                          onClick={() => popMe(row)}/>
                      }

                      // The old:
                      if (row.payload_success === null) {
                        return <></>
                      } else if (row.payload_success === 1) {
                        return <Button icon="pi pi-book"
                                       className="p-button-success p-button-sm w-2rem"
                                       onClick={() => {
                                         popMe(row)
                                       }}
                        />
                      } else if (row.payload_success === 0 && row.resolved_by === null) {
                        return <Button icon="pi pi-book pi-spin"
                                       className="p-button-danger p-button-sm w-2rem"
                                       onClick={() => {
                                         popMe(row)
                                       }}
                        />
                      } else if (row.payload_success === 0 && row.resolved_by !== null) {
                        return <Button icon="pi pi-book"
                                       className="p-button-warning p-button-sm w-2rem"
                                       onClick={() => {
                                         popMe(row)
                                       }}
                        />
                      }
                    }}
            />}
          {/*{!entrepreneur_id && <Column field="administration_name" header="Administratie"/>}*/}
          <Column field="bookable_name" sortable header="Bookable"/>
          <Column field="created_at" sortable
                  className="text-center w-7rem"
                  header="Order" body={row => {
            return row.created_at.substring(0, 10)
          }}/>
          <Column field="reservation_number" sortable className="w-10rem" header="No."/>
          <Column field="last_name" sortable header="Naam" body={r => `${r.last_name}, ${r.first_name}`}/>
          <Column field="check_in" sortable className="w-7rem" header="In"/>
          <Column field="adults" className="text-center" header="Pers."/>
          <Column field="check_out" header="Nights" className="text-center"
                  body={(row) => getDays(row.check_in, row.check_out)}
          />

          <Column field="e_src" className="text-right w-5rem" header="Bron"/>
          <Column field="pms_name" className="text-center" header="PMS"/>
          <Column field="stripe_status" className="text-center w-5rem" header="Stripe"
                  body={(rowData) =>
                    <StatusButton
                      noLabel
                      status={rowData.stripe_status}
                      onClick={rowData.stripe_status ?
                        () => window.open(`https://dashboard.stripe.com/payments/${rowData.stripe_id}`, '_blank').focus()
                        : undefined}/>}/>
          <Column field="pid" className="text-right" header="Pid"
                  body={(row) => {
                    return <img
                      alt=""
                      title={row.pid}
                      style={{borderRadius: '50%', cursor: 'pointer'}}
                      src={`/media/icons/${row.pid === 'allyourz' ? 'allyourz' : 'localbooker'}.png`}/>
                  }}/>
          <Column field="price_stay"
                  className="text-right w-10rem"
                  header="Kale prijs" body={row => {
            return <div>
              <div style={{cursor: 'pointer'}}>
                <span style={{lineHeight: '1.7rem', marginRight: '0.5rem'}}>{money.toEuro(row.price_stay, true)}</span>
                <Button icon="pi pi-book" className="p-button-sm w-2rem" onClick={() => rowClicked(row)}/>
              </div>
            </div>


          }}/>
        </DataTable>
      </div>
    </div>
  </>
}



