/**
 *
 * @param obj1
 * @param obj2
 * @returns {*}
 *
 * Merges two arrays recursively
 */
export default function mergeRecursive(obj1, obj2) {

  for (let p in obj2) {
    try {
      if (obj2[p].constructor === Object) {
        obj1[p] = mergeRecursive(obj1[p], obj2[p]);
      } else {
        obj1[p] = obj2[p];
      }
    } catch (e) {
      obj1[p] = obj2[p];
    }
  }

  return obj1;
}
