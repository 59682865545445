import {RadioButton} from "primereact/radiobutton";
import {SelectButton} from "primereact/selectbutton";

export default function LegendaLeft(
  {
    reportMode, setReportMode,
    selectedRange, rangeOptions, setSelectedRange,
    selectedBlock, blockOptions, setSelectedBlock,
    selectedSingle, singleOption, setSelectedSingle,
    uxClicked,
    selectedDates, setSelectedDates
  }) {

  return <div className="col-10">
    <div className="legenda grid">

      <div className="col-3 mt-2">
        <label htmlFor="mode_report" className="pointer">Report mode </label>
        <RadioButton inputId="mode_report" value="report" checked={reportMode} onChange={() => {
          setSelectedDates([]) // This one just prevents double calls (I hope)
          setReportMode(true)
        }}/>
        <label htmlFor="mode_edit" className="pointer ml-3">Edit mode </label>
        <RadioButton inputId="mode_edit" value="edit" checked={!reportMode} onChange={() => {
          setSelectedDates([]) // This one just prevents double calls (I hope)
          setReportMode(false)
        }}/>
      </div>

      <div className="col-1 mt-2">
        {!reportMode && 'Reeks:'}
      </div>
      <div className="col-2">
        <SelectButton
          style={{visibility: reportMode ? 'hidden' : 'visible'}}
          value={selectedRange}
          options={rangeOptions}
          onChange={(e) => {
            setSelectedRange(e.value)
            uxClicked('range', e.value)
          }}
          multiple
        />
      </div>

      <div className="col-1 mt-2">
        {!reportMode && 'Blok'}
      </div>
      <div className="col-2 ">
        {!reportMode && <SelectButton
          value={selectedBlock}
          options={blockOptions}
          onChange={(e) => {
            setSelectedBlock(e.value)
            uxClicked('block', e.value)
          }}
          multiple
        />}
      </div>
      {/*SINGLE*/}
      <div className="col-2">
        {!reportMode && <SelectButton
          value={selectedSingle}
          options={singleOption}
          onChange={(e) => {
            setSelectedRange(e.value)
            setSelectedSingle(['single'])
            uxClicked('single', e.value)
          }}
          multiple
        />}
      </div>

      <div className="col-1 text-right mt-2">
        {!reportMode && 'Dagen: ' + selectedDates.length}
      </div>
    </div>
  </div>

}
