import {forwardRef, useImperativeHandle} from 'react'
import {InputText} from "primereact/inputtext";
import InfoButton from "../../atoms/InfoButton";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {useEffect, useState} from "react";
import {Checkbox} from "primereact/checkbox";
import getYmd from "../../../services/getYmd";
import money from "../../../services/money";

const defaultRange = {
  quantity: null,
  rate: null,
  rate_low: null,
  rate_medium: null,
  note: null
}
const PriceAvailability = forwardRef(({selectedDates, initialState, save, dataAvailable}, ref) => {
  const [updates, setUpdates] = useState(defaultRange)
  const [daynote, setDaynote] = useState('')

  useImperativeHandle(ref, () => ({
    reset: () => setUpdates(defaultRange)
  }));

  /**
   * This one runs when the selected dates are changed
   * This one checks if all the values are the same of the fields in
   * the selectedDates. If so show the value, else blank
   *
   * Also checks if 1 date is selected for the walk function
   * Also checks if 1 date is selected for a daynote
   * Also hides tooltips when we have seleced dates
   */
  useEffect(() => {
    let result = null
    selectedDates.forEach(e => {
      const data = {...dataAvailable[getYmd(e)]}
      if (!result) {
        result = data
        delete result.timeslot_id
        delete result.date
      } else {
        Object.entries(result).forEach((d) => {
          if (result[d[0]] !== data[d[0]]) {
            result[d[0]] = null
          }
        })
      }
    })
    if (result) {
      result.rate = (!!result.rate === false ? '' : money.toEuro(result.rate))
      result.rate_low = (!!result.rate_low === false ? '' : money.toEuro(result.rate_low))
      result.rate_medium = (!!result.rate_medium === false ? '' : money.toEuro(result.rate_medium))

      setUpdates(result)
    }

    // Can we walk and can we set a daynote
    if (selectedDates.length === 1) {
      const data = dataAvailable[getYmd(selectedDates[0])]

      setDaynote(data?.note || '')
    } else {
      setDaynote('')
    }

  }, [selectedDates, dataAvailable])



  const setNullCkecbox = (field, callback) => {
    return <Checkbox
      style={{float: 'right', marginTop: '0.25rem'}}
      checked={updates[field] !== null}
      onChange={e => {
        if (e.checked) {
          // Checking the checkbox
          const clone = {...updates}
          clone[field] = ''
          setUpdates(clone)
        } else {
          // Unchecking the checkbox set to NULL... server will skip
          const clone = {...updates}
          clone[field] = null
          setUpdates(clone)
        }
      }}
    />
  }

  return <>

      <div className="grid mb-2">
        <div className="col-10 line-height-4">Geef hieronder de prijs/beschikbaarheid op</div>
        <div className="col-2 text-right line-height-4">
          Update
        </div>
      </div>
      {/*QUANTITY*/}
      <div className="grid">
        <div className="col-6 label">Aantal plaatsen</div>
        <div className="col-4">
          <InputText
            className="text-right"
            value={updates.quantity === null ? '' : updates.quantity}
            onChange={e => {
              setUpdates({...updates, quantity: e.target.value})
            }}
          />
        </div>
        <div className="col-2">
          {setNullCkecbox('quantity')}
        </div>
      </div>
      {/*RATE*/}
      <div className="grid">
        <div className="col-6 label">Prijs</div>
        <div className="col-4">
          <InputText
            className="text-right"
            value={updates.rate === null ? '' : updates.rate}
            onChange={e => setUpdates({...updates, rate: e.target.value})}
          />
        </div>
        {/*======================================*/}
        <div className="col-2 checkbox">
          {setNullCkecbox('rate')}
        </div>
      </div>
      {/*RATE MEDIUM*/}
      <div className="grid">
        <div className="col-6 label">Prijs Medium</div>
        <div className="col-4">
          <InputText
            className="text-right"
            value={updates.rate_medium === null ? '' : updates.rate_medium}
            onChange={e => setUpdates({...updates, rate_medium: e.target.value})}
          />
        </div>
        {/*======================================*/}
        <div className="col-2 checkbox">
          {setNullCkecbox('rate_medium')}
        </div>
      </div>
      {/*RATE LOW*/}
      <div className="grid">
        <div className="col-6 label">Prijs Laag</div>
        <div className="col-4">
          <InputText
            className="text-right"
            value={updates.rate_low === null ? '' : updates.rate_low}
            onChange={e => setUpdates({...updates, rate_low: e.target.value})}
          />
        </div>
        {/*======================================*/}
        <div className="col-2 checkbox">
          {setNullCkecbox('rate_low')}
        </div>
      </div>


      {/*RATE MEDIUM*/}
      <div className="grid mt-1">
        <div className="col-3">
          <span
            style={{color: (selectedDates.length === 1 ? '#333333' : '#a6a6a6')}}
            className="font-bold">Dagnotitie
          </span>&nbsp;
          <InfoButton text="Enkel te bewerken wanneer exact één dag geselecteerd is." className="mt-1"/>
        </div>
        <div className="col-9">
          <InputTextarea
            readOnly={selectedDates.length !== 1}
            rows={6}
            placeholder={selectedDates.length !== 1 ? 'Selecteer precies één dag om een dagnotitie te maken.' : ''}
            value={daynote}
            onChange={e => setDaynote(e.target.value)}
          />
        </div>
      </div>

      {/*SAVE*/}
      <div className="grid mt-1">
        <div className="col-3 col-offset-6">
          <Button label="Save" onClick={() => save(updates, daynote)}/>
        </div>
        <div className="col-3">
          <Button label="Clear"
                  style={{float: 'right'}}
                  className="p-button-warning"
                  onClick={() => {
                    initialState()
                  }}/>
        </div>
      </div>

  </>
})

PriceAvailability.displayName = 'PriceAvailability'
export default PriceAvailability
