import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {Calendar} from "primereact/calendar";
import {Toast} from "primereact/toast";
import getYmd from "../../../services/getYmd";
import LegendaRight from "./LegendaRight";
import LegendaLeft from "./LegendaLeft";
import dateTemplate from "./dateTemplate";
import PriceAvailability from "./PriceAvailability";
import {horizontalSelect, verticalSelect} from "../../../services/dateRangeHelpers";
import Report from "./Report";

export default function Availability({timeslot_id, activityRelaod}) {

  const refForm = useRef()

  // All selected dates of the datepicker
  const [selectedDates, setSelectedDates] = useState([])
  // The start of the range
  const [startRange, setStartRange] = useState('')
  // The data from the server
  const [dataAvailable, setDataAvailable] = useState({})
  const [timeslot, setTimeslot] = useState({})
  // The UX status
  const [status, setStatus] = useState('wait_for_start_date_range')

  // The select buttons
  // eslint-disable-next-line
  const [dayOption, setDayOption] = useState([
    {label: 'Day', value: 'day'}])
  // eslint-disable-next-line
  const [singleOption, setSingleOption] = useState([
    {label: 'Single', value: 'single'}])
  // eslint-disable-next-line
  const [rangeOptions, setRangeOptions] = useState([
    {label: 'Van', value: 'from'},
    {label: 'Tot', value: 'to', disabled: true}
  ])
  // eslint-disable-next-line
  const [blockOptions, setBlockOptions] = useState([
    {label: 'Van', value: 'from'},
    {label: 'Tot', value: 'to', disabled: true}
  ])
  const [reportMode, setReportMode] = useState(true)
  const [selectedRange, setSelectedRange] = useState(['from'])
  const [selectedBlock, setSelectedBlock] = useState([])
  const [selectedSingle, setSelectedSingle] = useState([])
  const [, setSelectedDay] = useState([])
  const [viewDate, setViewDate] = useState(new Date())

  const toast = useRef()


  /**
   * Handle data
   */
  function handleData(res) {
    const dbAvail = {}
    setTimeslot(res.data.timeslot)
    res.data.availability.forEach(e => {
      dbAvail[e.date] = e
    })
    setDataAvailable(dbAvail)
  }

  useEffect(() => {
    const downHandler = ({key}) => {
      if (key === 'Escape') {
        initialState()
      }
    }
    window.addEventListener("keydown", downHandler)
    return () => window.removeEventListener("keydown", downHandler)
  }, []); // eslint-disable-line

  useEffect(() => {
    axios.get('allyourz/activity-get-availability-by-timeslot-id?id=' + timeslot_id).then(res => {
      handleData(res)
      initialState()
    })
    // eslint-disable-next-line -- should be fine I guess, although the user might click a tab
  }, [timeslot_id])

  // Always reset after mode switch
  useEffect(() => initialState(), [reportMode]) // eslint-disable-line

  const save = (updates, daynote) => {
    const asYmd = selectedDates.map(d => getYmd(d))

    axios.post('allyourz/activity-update-availability', {
      timeslot_id,
      selectedDates: asYmd,
      updates,
      timeslot,
      daynote
    }).then(res => {
      handleData(res)
      activityRelaod(timeslot_id)
      toast.current.show({life: 1000, severity: 'success', summary: 'Kalender', detail: 'Bijgewerkt!'})
    }).catch(e => {
      toast.current.show({
        life: 5000, severity: 'error', summary: 'Onjuiste invoer',
        detail: <div dangerouslySetInnerHTML={{__html: e.response.data.map(item => item.error).join("<br/>")}}/>
      });
    })
  }

  /**
   * UX
   */
  const uxClicked = (action, value) => {

    if (reportMode) {
      setSelectedDates([value.value])
      return
    }

    switch (action) {
      case 'datepicker_select':
        /**
         * DATEPICKER SELECT
         */
        switch (status) {
          case 'wait_for_start_date_range':
            setStartRange(getYmd(value.value))
            setSelectedRange(['to'])
            doOption('rangeOptions', 0, 'disabled', true)
            doOption('blockOptions', 0, 'disabled', true)
            doOption('singleOption', 0, 'disabled', true)
            doOption('dayOption', 0, 'disabled', true)
            setStatus('wait_for_end_date_range')
            break;
          case 'wait_for_end_date_range':
            setSelectedRange([])
            setSelectedDates(horizontalSelect(startRange, getYmd(value.value)))
            doOption('singleOption', 0, 'disabled', false)
            setStatus('single_select')
            setSelectedSingle(['single'])
            break;
          case 'wait_for_start_date_block':
            setStartRange(getYmd(value.value))
            setSelectedBlock(['to'])
            doOption('rangeOptions', 0, 'disabled', true)
            doOption('blockOptions', 0, 'disabled', true)
            doOption('singleOption', 0, 'disabled', true)
            doOption('dayOption', 0, 'disabled', true)
            setStatus('wait_for_end_date_block')
            break;
          case 'wait_for_end_date_block':
            setSelectedBlock([])
            setSelectedDates(verticalSelect(startRange, getYmd(value.value), dataAvailable))
            doOption('singleOption', 0, 'disabled', false)
            setStatus('single_select')
            setSelectedSingle(['single'])
            break;
          case 'single_select':
            doOption('rangeOptions', 0, 'disabled', true)
            doOption('blockOptions', 0, 'disabled', true)
            break;
          case 'day_select':
            horizontalSelect(getYmd(value.value), getYmd(value.value))
            break;
          default:
        }

        break;

      /**
       * BUTTON CLICKED
       */
      case 'range':
        setStatus('wait_for_start_date_range')
        setSelectedSingle([])
        setSelectedBlock([])
        setSelectedDay([])
        break;
      case 'block':
        setStatus('wait_for_start_date_block')
        setSelectedSingle([])
        setSelectedRange([])
        setSelectedDay([])
        break;
      case 'single':
        setStatus('single_select');
        setSelectedBlock([])
        setSelectedRange([])
        setSelectedDay([])
        break;
      case 'day':
        setStatus('day_select');
        setSelectedBlock([])
        setSelectedRange([])
        setSelectedSingle([])
        break;
      default:
    }
  }

  const doOption = (options, index, key, value) => {
    const clone = eval('[...' + options + ']') // eslint-disable-line
    clone[index][key] = value
    const setterName = 'set' + options.charAt(0).toUpperCase() + options.slice(1)
    eval(setterName + '(clone)') // eslint-disable-line
  }


  const initialState = () => {
    setSelectedDates([])
    doOption('rangeOptions', 0, 'disabled', false)
    doOption('blockOptions', 0, 'disabled', false)
    doOption('singleOption', 0, 'disabled', false)
    doOption('dayOption', 0, 'disabled', false)

    setSelectedRange(['from'])
    setSelectedBlock([])
    setSelectedSingle([])
    setSelectedDay([])
    setStatus('wait_for_start_date_range')

    if (refForm.current) {
      refForm.current.reset()
    }

  }


  return <>
    <Toast ref={toast} position="top-left"/>

    {/*LEGENDA*/}
    <div className="availability grid mt-1">
      <LegendaLeft
        selectedDates={selectedDates} setSelectedDates={setSelectedDates}
        reportMode={reportMode} setReportMode={setReportMode}
        rangeOptions={rangeOptions} selectedRange={selectedRange} setSelectedRange={setSelectedRange}
        blockOptions={blockOptions} selectedBlock={selectedBlock} setSelectedBlock={setSelectedBlock}
        selectedSingle={selectedSingle} setSelectedSingle={setSelectedSingle} singleOption={singleOption}
        uxClicked={uxClicked}
      />
      <LegendaRight setTimeslot={setTimeslot} timeslot={timeslot} reportMode={reportMode}/>
    </div>

    {/*CALENDAR*/}
    <div className="comp availability grid">
      <div className="col-8">
        <Calendar value={selectedDates}
                  selectionMode="multiple"
                  inline={true}
                  viewDate={viewDate}
                  onViewDateChange={e => setViewDate(e.value)}
                  onSelect={d => {
                    uxClicked('datepicker_select', d)
                  }}
                  onChange={(e) => {
                    uxClicked('datepicker_change', e.value)
                    setSelectedDates(e.value)
                  }}
                  dateTemplate={(d) => dateTemplate(d, dataAvailable)}
                  numberOfMonths={2}>
        </Calendar>
      </div>

      {/*FORM OR REPORT*/}
      <div className="col-4 manage-availability">
        {reportMode ?
          <Report
            timeslot_id={timeslot_id}
            activity_id={1}
            selectedDates={selectedDates} /> :
          <PriceAvailability
            ref={refForm}
            selectedDates={selectedDates}
            initialState={initialState}
            dataAvailable={dataAvailable}
            save={save}
          />
        }
      </div>
    </div>

  </>
}
