import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useEffect, useState} from "react";

export default function PrMultisort() {

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
  });

  useEffect(() => {
    loadLazyData();
  }, [lazyState]);

  const loadLazyData = () => {
  }

  return (
    <div className="card">
      <DataTable

        value={[]}            // Does not matter
        lazy                  // We're talking lazy here

        sortMode="multiple"   // <== "single" or "multiple" ... "single" works

        onSort={(event) => {
          console.log(event)
          setlazyState(event);
        }}

        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        multiSortMeta={lazyState.multiSortMeta}>
        {/* fields */}
        <Column field="name" header="Name" sortable/>
        <Column field="company" sortable header="Company"/>
        {/* /fields */}

      </DataTable>
    </div>
  );

}