import {useEffect, useState} from "react";
import axios from "axios";
import jsonPrettyPrint from "../../services/jsonPrettyPrint";

export default function DebugBookable({bookable_id}){

  const [debug,setDebug]=useState('')

  useEffect(()=>{
    axios.get('/allyourz/debug-bookable?id=' + bookable_id).then(res=>{
      setDebug(res.data)
    })
  },[bookable_id])


  return <div className="grid">
    <div className="col-10">
      <pre className="json-pretty-print" dangerouslySetInnerHTML={{__html: jsonPrettyPrint(debug)}}/>
    </div>

  </div>
}
