import Menu from "../../components/Menu";
import {Fieldset} from "primereact/fieldset";

import AdministrationTypes from "../../components/AdministrationTypes";
import {useParams} from "react-router-dom";

export default function PageAdministrationTypes() {

  const params = useParams()

  return (
    <div className="page administration-types">
      <Menu/>
      <Fieldset legend="Administraties">
        <AdministrationTypes entrepreneur_id={params.entrepreneur_id}/>
      </Fieldset>
    </div>
  )
}
