import {useEffect, useState} from "react";
import axios from "axios";

import {TabView, TabPanel} from 'primereact/tabview';
import NextpaxDecisionAndResponse from "./NextpaxDecisionAndResponse";
import NextpaxPayload from "./NextpaxPayload";
import CubilisResponse from "./CubilisResponse";
import CubilisPayload from "./CubilisPayload";
import OpenGdsPayload from "./OpenGdsPayload";
import OpenGdsResponse from "./OpenGdsResponse";
import AllrezResponse from "./AllrezResponse";
import AllRezPayload from "./AllRezPayload";
import CancelBooking from "./CancelBooking";
import ResolveBooking from "./ResolveBooking";
import TryAgainBooking from "./TryAgainBooking";
import QennerResponse from "./QennerResponse";
import QennerPayload from "./QennerPayload";

export default function BookPayload({id, administration_id, onResolved, closeMe, popHead}) {

  const [data, setData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0)
  useEffect(() => {
    axios.get('/allyourz/booking-payload', {params: {id, administration_id}}).then(res => {
      setData(res.data)
      setActiveIndex(0)
    })
  }, [id, administration_id])

  if (!data) {
    return null
  }

  const reload = async () => {
    await axios.get('/allyourz/booking-payload', {params: {id, administration_id}}).then(res => {
      setData(res.data)
    })
  }

  return <div className="postman">
    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
      <TabPanel header="Response">
        {data.src === 'nextpax' && <NextpaxDecisionAndResponse data={data}/>}
        {data.src === 'cubilis' && <CubilisResponse data={data}/>}
        {data.src === 'opengds' && <OpenGdsResponse data={data}/>}
        {data.src === 'allrez' && <AllrezResponse data={data}/>}
        {data.src === 'qenner' && <QennerResponse data={data}/>}
      </TabPanel>
      <TabPanel header="Payload">
        {data.src === 'nextpax' && <NextpaxPayload data={data}/>}
        {data.src === 'cubilis' && <CubilisPayload data={data}/>}
        {data.src === 'opengds' && <OpenGdsPayload data={data}/>}
        {data.src === 'allrez' && <AllRezPayload data={data}/>}
        {data.src === 'qenner' && <QennerPayload data={data}/>}
      </TabPanel>

      {(data.reservation_status === 'failed' && !data.resolved_by) &&
        <TabPanel headerStyle={{marginLeft: '2rem'}} header={'Opnieuw inschieten'}>
          <TryAgainBooking
            data={data}
            setData={setData}
            onResolved={onResolved}
            closeMe={closeMe}
            reload={async () => {
              await reload()
              setActiveIndex(0)
            }}
          />
        </TabPanel>}

      {/* RESOLVE */}
      {['failed'].includes(data.reservation_status) &&
        <TabPanel
          headerStyle={{marginLeft: '2rem'}}
          header={
            data.resolved_by ?
              (data.manual_success_by ? 'Opgelost en alsnog afgerond' : 'Opgelost (niet manueel afgerond)') :
              'Markeer als opgelost'
          }>
          <ResolveBooking
            data={data}
            setData={setData}
            onResolved={onResolved}
            closeMe={closeMe}
            popHead={popHead}
            reload={reload}
          />
        </TabPanel>}

      {/* CANCEL */}
      {['success', 'cancelled'].includes(data.reservation_status) &&
        <TabPanel headerStyle={{marginLeft: '2rem'}}
                  header={data.reservation_status === 'success' ? "Annuleer Deze Boeking" : "Geannuleerde Boeking"}>
          <CancelBooking
            data={data}
            reload={reload}
            popHead={popHead}
            closeMe={closeMe}
            onResolved={onResolved}/>
        </TabPanel>}
    </TabView>
  </div>
}
