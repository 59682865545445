import PrMultisort from "../components/test/PrMultisort";

export default function PageTest() {

  return <div>
    <div style={{margin:'auto', marginTop:'100px'}}>
      {/*<Tips administration_id="D7AFC128-C19E-4DD5-8856-D4375BAEA240" />*/}

      <PrMultisort />
    </div>
  </div>
}
