import xmlPrettyPrint from "../../services/xmlPrettyPrint";
import Highlight from 'react-highlight'


export default function QennerResponse({data}) {

  const isXml =  data.payload_response && data.payload_response.startsWith('<?xml')

  return <>
    <div style={{margin: '0.25rem', marginTop: '1rem'}}>Channel Manager Response</div>
    {isXml ? <Highlight className="xml">
        {xmlPrettyPrint(data.payload_response)}
      </Highlight> :

      <pre className="mt-4 mb-4 p-3">
        {data.payload_response}
      </pre>


    }
  </>

}
