import {useContext, useEffect, useState} from "react";
import {UserContext} from "../contexts/UserContext";
import axios from "axios";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {Dropdown} from 'primereact/dropdown';
import mapToOptions from '../services/mapToOptions'
import {InputMask} from "primereact/inputmask";
import {InputTextarea} from "primereact/inputtextarea";
import {Checkbox} from "primereact/checkbox";

const fromUntil = Array.from({length: 29}, (_, i) => {
  return !i ? '-' : i
})

export default function AddOns({entity_id, src}) {

  const user = useContext(UserContext)
  const [addOns, setAddOns] = useState([])
  const [editMode, setEditMode] = useState(-1)
  const [dirty, setDirty] = useState(false)
  const [canEdit, setCanEdit] = useState(false)
  const [cartRemark, setCartRemark] = useState()
  const [saving, setSaving] = useState(false)
  const [copyFrom, setCopyFrom] = useState(null)
  // Maps
  const [addOnMap, setAddOnMap] = useState({})
  const [, setCopyMap] = useState({})
  const [chargeTypeMap, setChargeTypeMap] = useState({});
  const [chargeModeMap, setChargeModeMap] = useState({});
  // And them options
  const [addOnMapOptions, setAddOnMapOptions] = useState([])
  const [copyMapOptions, setCopyMapOptions] = useState([])
  const [chargeTypeOptions, setChargeTypeOptions] = useState([])
  const [chargeModeOptions, setChargeModeOptions] = useState([])

  const update = (index, field, val) => {
    const clone = [...addOns]
    clone[index][field] = val
    setAddOns(clone)
    setDirty(true)
  }

  const removeClicked = (index) => {
    const c = [...addOns]
    c.splice(index, 1)
    setAddOns(c)
    setDirty(true)
  }
  const newClicked = () => {
    const c = [...addOns]
    c.push({amount: 0, chargeMode: '', chargeType: '', entity_id: null, fromDate: '', name: '', untilDate: ''})
    setAddOns(c)
    setEditMode(c.length - 1)
  }
  const cancelClicked = () => {
    axios.get(`/allyourz/add-ons-by-entity-id`, {params: {entity_id: entity_id}}).then(res => {
      setData(res)
    })
  }
  const doCopy = (from_bookable_id) => {
    axios.get(`/allyourz/add-ons-by-bookable-id`, {
      params: {
        from_bookable_id: from_bookable_id,
        to_entity_id: entity_id
      }
    }).then(res => {
      if (canEdit) {
        let clone = [...addOns]
        Array.prototype.push.apply(clone, res.data.addOns);
        setAddOns(clone)
      }
      setCartRemark(res.data.cart_remark)
      setDirty(true)
    })
  }

  const setData = (res) => {
    setAddOns(res.data.add_ons)
    setCartRemark(res.data.cart_remark)

    setEditMode(-1)
    setDirty(false)
    setCopyFrom(null)

    setAddOnMap(res.data.all_add_ons)
    setCopyMap(res.data.copy_from)
    setChargeModeMap(res.data.chargeModeMap)
    setChargeTypeMap(res.data.chargeTypeMap)

    setAddOnMapOptions(mapToOptions(res.data.all_add_ons))
    setCopyMapOptions(mapToOptions(res.data.copy_from))
    setChargeModeOptions(mapToOptions(res.data.chargeModeMap))
    setChargeTypeOptions(mapToOptions(res.data.chargeTypeMap))
  }

  const saveClicked = () => {
    setSaving(true)
    axios.put(`/allyourz/add-ons-by-entity-id?entity_id=${entity_id}`,
      {
        addOns,
        cartRemark
      }
    ).then(res => {
      setData(res)
      setSaving(false)
    })
  }

  useEffect(() => {

    axios.get(`/allyourz/add-ons-by-entity-id?entity_id=${entity_id}`).then(res => {
      setData(res)
      setCanEdit(!['nextpax', 'qenner'].includes(src))
    })
  }, [user.language, entity_id, src])


  const addOnRow = (data, index) => {
    return <div key={index} className="grid">

      {canEdit ? <div className="col-1">
          {/*THE EDIT BUTTON (cubils, opengds, allrez) */}
          <Button
            disabled={(editMode !== -1) && (editMode !== index)}
            className={classNames({'p-button-success': editMode === index})}

            icon={classNames('pi pi-pencil', {'pi-check': editMode === index})}
            onClick={() => {
              if (editMode === -1) {
                setEditMode(index)
              } else {
                setEditMode(-1)
              }
            }}/>
        </div> :
        <div className="col-1 text-center">
          {/*THE DISABLE CHECKBOX (qenner nextpax) Only the optionals*/}
          {data.chargeType === 'OPT' && <Checkbox
            className="mt-1"
            checked={!!data.disabled} onClick={e => {
            axios.post(`/allyourz/add-ons-by-entity-id?entity_id=${entity_id}`,
              {
                addition_id: data.addition_id,
                disabled: e.checked,
                fromDate: data.fromDate,
                untilDate: data.untilDate,
                minDays: data.minDays,
                maxDays: data.maxDays
              }
            ).then(res => {
              setData(res)
              setSaving(false)
            })}}/>}
        </div>
      }

      {/* Addition naam */}
      <div className={classNames('col-2', {pt1rem: editMode !== index})}>
        {editMode === index ? <Dropdown
            options={addOnMapOptions}
            value={data.addition_id}
            onChange={(e) => {
              update(index, 'addition_id', e.value)
            }}
          /> :
          addOnMap[data.addition_id] || data.name}
      </div>

      {/* bedrag */}
      <div className={classNames('col-1 text-right', {pt1rem: editMode !== index})}>
        {editMode === index ?
          <InputText className="text-right"
                     value={data.amount}
                     onChange={(e) => {
                       update(index, 'amount', e.target.value)
                     }}/> : data.amount}
      </div>

      {/* Type */}
      <div className={classNames('col-2', {pt1rem: editMode !== index})}>
        {editMode === index ? <Dropdown
            options={chargeTypeOptions}
            value={data.chargeType}
            onChange={(e) => {
              update(index, 'chargeType', e.value)
            }}
          /> :
          chargeTypeMap[data.chargeType]}
      </div>

      {/* Mode */}
      <div className={classNames('col-2', {pt1rem: editMode !== index})}>
        {editMode === index ? <Dropdown
            options={chargeModeOptions}
            value={data.chargeMode}
            onChange={(e) => {
              update(index, 'chargeMode', e.value)
            }}
          /> :
          chargeModeMap[data.chargeMode]}
      </div>

      {/* Van */}
      <div className={classNames('col-1 text-center', {pt1rem: editMode !== index})}>
        {editMode === index ?
          <InputMask
            className="text-center"
            mask="9999-99-99"
            slotChar="jjjj-mm-dd"
            value={data.fromDate || ''}
            onChange={e => {
              update(index, 'fromDate', e.target.value)
            }}/>
          : data.fromDate}
      </div>

      {/* Tot */}
      <div className={classNames('col-1 text-center', {pt1rem: editMode !== index})}>
        {editMode === index ?
          <InputMask
            className="text-center"
            mask="9999-99-99"
            slotChar="jjjj-mm-dd"
            value={data.untilDate || ''}
            onChange={e => {
              update(index, 'untilDate', e.target.value)
            }}/>
          : data.untilDate}
      </div>


      <div className="col-2 text-center">

        {/* MIN - MAX */}
        {editMode === index ?
          <>
            <Dropdown value={data.minDays}
                      style={{width: '4.5rem'}}
                      placeholder="-"
                      options={fromUntil}
                      onChange={e => {
                        update(index, 'minDays', e.value)
                      }}/>
            <Dropdown value={data.maxDays}
                      style={{width: '4.5rem'}}
                      placeholder="-"
                      options={fromUntil}
                      onChange={e => {
                        update(index, 'maxDays', e.value)
                      }}/>
          </> :
          (data.minDays && data.maxDays) ? data.minDays + ' - ' + data.maxDays : '---'
        }

        {/* DELETE BUTT */}
        {canEdit && <Button
          icon="pi pi-times"
          className="right p-button-outlined p-button-danger"
          onClick={() => {
            removeClicked(index)
          }}/>
        }
      </div>
    </div>
  }

  return <>
    <div className="add-ons mt-2">
      <div className="grid text-center font-bold">
        <div className="col-1">{canEdit ? '' : 'Hide'}</div>
        <div className="col-2">Add On</div>
        <div className="col-1">Bedrag</div>
        <div className="col-2">Type</div>
        <div className="col-2">Mode</div>
        <div className="col-1">Van</div>
        <div className="col-1">Tot</div>
        {src !== 'nextpax' && <>
          <div className="col-2 text-left pl-7">Min - Max</div>
        </>}
      </div>
      {/* Add ons */}
      {addOns.map((e, i) => addOnRow(e, i))}

      {/* Opmerkingen */}
      {user.field('book.cart_remark').view && <>
        <div className="grid mt-3">
          <div className="col-offset-1 col-2 text-center font-bold">
            Opmerkingen
          </div>
          <div className="col-9">
            Deze komen onderaan de verplichte kosten<br/>
            en zijn enkel door allyourz aan te passen.
          </div>
        </div>
        <div className="grid">
          <div className="col-offset-1 col-5">
            <InputTextarea
              readOnly={!user.field('book.cart_remark').edit}
              value={cartRemark || ''}
              onChange={e => {
                setDirty(true)
                setCartRemark(e.target.value)
              }} rows={3}/>
          </div>
        </div>
      </>}

      {/* Buttons */}
      {canEdit && <div className="grid" style={{marginTop: '1rem'}}>
        <div className="col-1">
          <Button
            label="Save"
            disabled={!dirty}
            icon={classNames({'pi pi pi-spin pi-spinner': saving})}
            className="p-button-success"
            onClick={() => {
              saveClicked()
            }}/>
        </div>
        <div className="col-1">
          <Button
            label="Nieuw"
            disabled={editMode !== -1}
            className="p-button-secondary"
            onClick={() => {
              newClicked()
            }}/>
        </div>
        <div className="col-2 text-right" style={{paddingTop: '1rem'}}>
          Of kopieer van
        </div>
        <div className="col-2">
          <Dropdown
            options={copyMapOptions}
            value={copyFrom}
            onChange={(e) => {
              setCopyFrom(e.value)
            }}
          />
        </div>
        <div className="col-1">
          <Button
            label="OK"
            onClick={() => {
              doCopy(copyFrom)
            }}
            disabled={!copyFrom}/>
        </div>
        <div className="col">
          <Button
            style={{float: 'right'}}
            label="Cancel"
            disabled={!dirty}
            className="p-button-warning"
            onClick={() => {
              cancelClicked()
            }}/>
        </div>
      </div>}
    </div>
  </>
}
