import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";
import {useContext, useEffect, useRef, useState} from "react";
import {UserContext} from "../contexts/UserContext";
import {Checkbox} from "primereact/checkbox";
import {Toast} from "primereact/toast";
import Remarks from "./atoms/Remarks";
import InfoButton from "./atoms/InfoButton";

const resetOpeningHours = 'Maandag     09:30 - 17:00\nDinsdag     09:30 - 17:00\nWoensdag    09:30 - 17:00\nDonderdag   09:30 - 17:00\nVrijdag     09:30 - 17:00\nZaterdag    09:30 - 17:00\nZondag      09:30 - 17:00\n'

export default function AdministrationType({administrationData, save, cancelOrReload}) {
  const user = useContext(UserContext)
  const [data, setData] = useState()
  const [sbiCodes, setSbiCodes] = useState([])
  const [regions, setRegions] = useState([])
  const [dirty, setDirty] = useState(null)
  const toast = useRef();

  useEffect(() => {
    setData(administrationData.at)

    setSbiCodes(administrationData.sbi_tree)
    setRegions(administrationData.regions)
    setDirty(null)
  }, [administrationData])

  /**
   * Handle dirty
   */
  useEffect(() => {
    if (data) {
      setDirty(d => d === null ? false : true)
    }
  }, [data])

  if (!data) return null

  return <div className="grid">
    <Toast ref={toast} position="top-left"/>
    <div className="col-6">
      <div className="grid">
        <div className="col-3 fieldname">Naam</div>
        <div className="col-9">
          <InputText value={data.name || ''} onChange={(e) => setData({...data, name: e.target.value})}/>
        </div>
      </div>
      <div className="grid">
        <div className="col-3 fieldname">Straat</div>
        <div className="col-9">
          <InputText value={data.streetname || ''} onChange={(e) => setData({...data, streetname: e.target.value})}/>
        </div>
      </div>
      <div className="grid">
        <div className="col-3 fieldname">Huisnummer</div>
        <div className="col-9">
          <InputText value={data.housenumber || ''} onChange={(e) => setData({...data, housenumber: e.target.value})}/>
        </div>
      </div>
      <div className="grid">
        <div className="col-3 fieldname">Postcode</div>
        <div className="col-9">
          <InputText value={data.zip || ''} onChange={(e) => setData({...data, zip: e.target.value})}/>
        </div>
      </div>
      <div className="grid">
        <div className="col-3 fieldname">Plaatsnaam</div>
        <div className="col-9">
          <InputText value={data.city || ''} onChange={(e) => setData({...data, city: e.target.value})}/>
        </div>
      </div>

      <div className="grid">
        <div className="col-3 fieldname">Regio</div>
        <div className="col-9">
          <Dropdown
            optionLabel="name"
            optionValue="id"
            value={data.region_id}
            options={regions}
            onChange={(e) => setData({...data, region_id: e.target.value})}
            placeholder="Selecteer een regio"
            style={{width: '100%'}}
          />
        </div>
      </div>

      <div className="grid">
        {user.field('adm.lat').view && <>
          <div className="col-3 fieldname"></div>
          <div className="col-1 fieldname">Lat</div>
          <div className="col-2">
            <InputText
              disabled={!user.field('adm.lat').edit}
              value={data.lat || ''}
              onChange={(e) => setData({...data, lat: e.target.value})}
            />
          </div>
        </>}
        {user.field('adm.lon').view && <>
          <div className="col-1 col-offset-1 fieldname">Long</div>
          <div className="col-2">
            <InputText
              disabled={!user.field('adm.lon').edit}
              value={data.lon || ''}
              onChange={(e) => setData({...data, lon: e.target.value})}
            />
          </div>
        </>}
        <div className="col-offset-1 col-1">
          <Button className="p-button-rounded p-button-secondary"
                  tooltip="Verander je de adres gegevens dan wordt deze waarde overschreven."
                  tooltipOptions={{position: 'top'}}
                  icon="pi pi-info"
                  style={{width: '1.5rem', height: '1.5rem'}}
          />
        </div>
      </div>

      <div className="grid">
        <div className="col-3 fieldname">Verberg adres</div>
        <div className="col-9">
          <Checkbox
            inputId="hide_address"
            onChange={(e) => setData({...data, hide_address: e.checked})}
            checked={!!data.hide_address}
          />
        </div>
      </div>

      {user.field('adm.deposit_percentage').view && <div className="grid">
        <div className="col-3 fieldname">Aanbetaling %</div>
        <div className="col-9">
          <InputNumber
            disabled={!user.field('adm.deposit_percentage').edit}
            value={data.deposit_percentage}
            onChange={(e) => setData({...data, deposit_percentage: e.value})}
            suffix=" %"
            min={0}
            max={100}
          />
        </div>
      </div>}

      {user.field('adm.google_place_id').view &&
        <div className="grid">
          <div className="col-3 fieldname">Google place id</div>
          <div className="col-7">
            <InputText
              disabled={!user.field('adm.google_place_id').edit}
              value={data.google_place_id || ''}
              onChange={(e) => setData({...data, google_place_id: e.target.value})}
            />
          </div>
          <div className="col-2">
            <Button icon="pi pi-search" onClick={() => window.open('https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder', '_blank').focus()}/>
          </div>
        </div>}

      {user.field('adm.sbi_id').view &&
        <div className="grid">
          <div className="col-3 fieldname">Bedrijfsactiviteit</div>
          <div className="col-9">
            <Dropdown
              value={data.sbi_id}
              options={sbiCodes}
              onChange={(e) => setData({...data, sbi_id: e.target.value})}
              optionLabel="label"
              optionGroupLabel="label"
              optionGroupChildren="items"
              optionGroupTemplate={(option) => option.label}
              style={{width: '100%'}}
              placeholder="Selecteer een activiteit"
            />
          </div>
        </div>}
      {user.field('adm.is_main').view &&
        <div className="grid">
          <div className="col-10 col-offset-3">
            <Checkbox inputId="is_main"
                      onChange={(e) => setData({...data, is_main: e.checked})}
                      checked={!!data.is_main}></Checkbox>
            <label style={{marginLeft: '0.5rem'}} htmlFor="is_main" className="p-checkbox-label">Is Main</label>
          </div>
        </div>}

      {user.field('adm.is_active').view &&
        <div className="grid">
          <div className="col-10 col-offset-3">
            <Checkbox inputId="cb1"
                      onChange={(e) => setData({...data, is_active: e.checked})}
                      checked={!!data.is_active}></Checkbox>
            <label style={{marginLeft: '0.5rem'}} htmlFor="cb1" className="p-checkbox-label">Is Active</label>
          </div>
        </div>}

      {user.field('adm.is_checked').view &&
        <div className="grid">
          <div className="col-10 col-offset-3">
            <Checkbox inputId="cb2"
                      onChange={(e) => setData({...data, is_checked: e.checked})}
                      checked={!!data.is_checked}></Checkbox>
            <label style={{marginLeft: '0.5rem'}} htmlFor="cb2" className="p-checkbox-label">Is Checked</label>
          </div>
        </div>}

      {user.field('adm.is_published').view &&
        <div className="grid">
          <div className="col-10 col-offset-3">
            <Checkbox inputId="cb3"
                      onChange={(e) => setData({...data, is_published: e.checked})}
                      checked={!!data.is_published}></Checkbox>
            <label style={{marginLeft: '0.5rem'}} htmlFor="cb3" className="p-checkbox-label">Is Published</label>
          </div>
        </div>}

      <div className="grid">
        <div className="col-8 fieldname"></div>
        <div className="col-2">
          <Button className="p-button-warning"
                  disabled={!dirty}
                  onClick={() => {
                    cancelOrReload()
                  }}
                  label="Cancel"/>
        </div>

        <div className="col-2">
          <Button className="p-button-success"
                  disabled={!dirty}
                  onClick={() => {
                    save(data)
                  }}
                  label="Save"
                  style={{float: 'right'}}/>
        </div>
      </div>
    </div>


    {/* --------------------------- */}
    {/* Rechter kant van het scherm */}
    {/* --------------------------- */}

    <div className="col-6">
      <div className="grid">
        <div className="col-3 fieldname">Slug</div>
        <div className="col-9">
          <InputText
            disabled={!user.field('adm.slug').edit}
            value={data.slug || ''}
            onChange={(e) => setData({...data, slug: e.target.value})}
            // keyfilter={/^[a-z0-9]+(?:-[a-z0-9]+)*$/ } @TODO this is a bug in PrimeReact, dash (-) doesn't work
            keyfilter={/^[a-z0-9-]*$/ }
          />
        </div>
      </div>

      <div className="grid">
        <div className="col-3 fieldname">One-liner</div>
        <div className="col-9">
          <InputText value={data.oneliner || ''} onChange={(e) => setData({...data, oneliner: e.target.value})}/>
        </div>
      </div>
      <div className="grid">
        <div className="col-3 fieldname">Korte omschrijving</div>
        <div className="col-9">
          <InputText value={data.short_description || ''} onChange={(e) => setData({...data, short_description: e.target.value})}/>
        </div>
      </div>

      <div className="grid">
        <div className="col-12 fieldname" style={{textAlign: 'center'}}>
          Omschrijving
        </div>
        <div className="col-12">
          <InputTextarea value={data.description || ''}
                         onChange={(e) => setData({...data, description: e.target.value})}
                         rows={6}
                         autoResize/>
        </div>
      </div>

      <div className="grid">
        <div className="col-7">
          {user.field('sys.remark').view && <>
            <div className="mb-3 text-center font-bold">Opmerkingen</div>
            <Remarks id={data.id}/>
          </>}
        </div>
        <div className="col-5">
          <div className="mb-3 text-center font-bold">Openingstijden</div>
          <div style={{position: 'relative'}}>
            <InputTextarea value={data.opening_hours || ''}
                           style={{fontFamily: 'courier new', fontSize: '0.9rem'}}
                           onChange={(e) => setData({...data, opening_hours: e.target.value})}
                           rows={8}
                           autoResize/>
            <InfoButton
              style={{position: 'absolute', right: '0.25rem', top: '0.25rem'}}
              icon="pi pi-bolt"
              text="Reset"
              onClick={() => {
                setData({...data, opening_hours: resetOpeningHours})
              }}
            />
          </div>
          <div className="mb-2 mt-3 text-center font-bold">Afwijkende Openingstijden</div>
          <div style={{position: 'relative'}}>
            <InputTextarea value={data.deviant_opening_hours || ''}
                           style={{fontFamily: 'courier new', fontSize: '0.9rem'}}
                           onChange={(e) => setData({...data, deviant_opening_hours: e.target.value})}
                           rows={3}
                           autoResize/>
          </div>

        </div>
      </div>
    </div>
  </div>
}
