export default function mapToOptions(map, fieldName, idFieldName){

  if(fieldName){
    return Object.keys(map).map(key => {
      const o = {}
      o.value = key
      o.label = map[key][fieldName]
      o.id = map[key][idFieldName]
      return o
    })
  } else {
    return Object.keys(map).map(key => {
      const o = {}
      o.value = key
      o.label = map[key]
      return o
    })
  }
}
