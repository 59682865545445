import {Rating} from "react-simple-star-rating";
import {InputTextarea} from "primereact/inputtextarea";
import {SelectButton} from "primereact/selectbutton";
import Remarks from "../atoms/Remarks";
import {Button} from "primereact/button";
import {TabView, TabPanel} from 'primereact/tabview';
import {useContext, useState} from "react";
import axios from "axios";
import {CosmeticContext} from "../../contexts/CosmeticContext";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";


const options = [
  {label: 'Nieuw', value: 'new'},
  {label: 'Accepteer', value: 'accepted'},
  {label: 'Weiger', value: 'rejected'},
]
const languageMap = {
  nl: 0,
  de: 1,
  en: 2
}

export default function Review({review, setReview, onUpdate}) {
  const cosmetic = useContext(CosmeticContext)
  const [activeIndex, setActiveIndex] = useState(languageMap[review.language])
  const [dirty, setDirty] = useState(false)

  const save = () => {
    axios.post('/allyourz/update-review', review).then(() => {

      onUpdate()
      cosmetic.doReloadMenu()
    })
  }

  const fieldChanged = (field, value) => {
    setDirty(true)
    setReview({...review, [field]: value})
  }

  const emojis = () => {
    const emojiMap = {2: '😠', 4: '🫤', 6: '😑', 8: '😀', 10: '😍'}

    const scoreTypes = [
      {type: 'facilities', name: 'Faciliteiten'},
      {type: 'hygiene', name: 'Hygiëne'},
      {type: 'comfort', name: 'Comfort'},
      {type: 'staff', name: 'Personeel'},
      {type: 'price', name: 'Prijs'},
      {type: 'location', name: 'Locatie'}
    ]
    return scoreTypes.map((st, i) => {

      return <div key={i} className="flex align-items-center justify-content-end">
        <div className="w-8rem">
          {st.name}
        </div>
        <div className="font-xl pr-2">
          {[2, 4, 6, 8, 10].map((s, j) =>
            <span key={j}
                  className={classNames('font-xl ml-2', {'opacity-20': review['score_' + st.type] !== s})}>{emojiMap[s]}</span>
          )}
        </div>
      </div>
    })
  }

  const rating = () => {

    if(!review.score_facilities) return null

    const massaged = Math.round((
      review.score_facilities +
      review.score_hygiene +
      review.score_comfort +
      review.score_staff +
      review.score_price +
      review.score_location
    ) / 6) / 2

    return <Rating
      readonly
      initialValue={massaged}
      allowFraction
      size={45}/>
  }


  const reviewHtml = (lang) => {
    return <>
      <div className="grid mt-1">
        <div className="col-2 font-bold flex align-items-center">Oneliner</div>
        <div className="col-10">
          <InputText
            autoFocus={review.language !== lang}
            className={classNames('w100 font-bold', {'ay_gray': review.language === lang})}
            readOnly={review.language === lang}
            value={review['oneliner_' + lang] || ''}
            onChange={e => {
              fieldChanged('oneliner_' + lang, e.target.value)
            }}
          />
        </div>
      </div>
      <div className="grid mt-1">
        <div className="col-2 font-bold flex align-items-center">Review</div>
        <div className="col-10">
          <InputTextarea
            rows={5}
            className={classNames('w100', {'ay_gray': review.language === lang})}
            readOnly={review.language === lang}
            value={review['review_' + lang] || ''}
            onChange={e => {
              fieldChanged('review_' + lang, e.target.value)
            }}
          />
        </div>
      </div>
      <div className="grid mt-1">
        <div className="col-2 font-bold flex align-items-center">AY Comment</div>
        <div className="col-10">
          <InputTextarea
            autoFocus={review.language === lang} // focus here cuz the rest we can not edit
            rows={5}
            className="w100"
            value={review['comment_' + lang] || ''}
            onChange={e => {
              fieldChanged('comment_' + lang, e.target.value)
            }}
          />
        </div>
      </div>

    </>
  }

  return <>
    <div className="flex align-items-center">
      <div className="w50">{rating()}</div>
      <div className="w50">{emojis()}</div>
    </div>


    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
      <TabPanel header="NL">
        {reviewHtml('nl')}
      </TabPanel>
      <TabPanel header="DE">
        {reviewHtml('de')}
      </TabPanel>
      <TabPanel header="EN">
        {reviewHtml('en')}
      </TabPanel>
    </TabView>

    {/*=================== MODERATE ====================*/}
    <div className="grid m-2">
      <div className="col-6">
        <SelectButton
          value={review.status}
          options={options}
          onChange={e => {
            setDirty(true)
            setReview({...review, status: e.target.value})
          }}
        />
      </div>
      <div className="col-6">
        <Button
          label="Opslaan"
          disabled={!dirty}
          severity="success"
          className="right"
          onClick={() => save()}
        />
        <Button
          label="Cancel"
          disabled={!dirty}
          className="right mr-2"
          severity="warning"
          onClick={() => onUpdate()}
        />

      </div>
    </div>
    <div>
      <Remarks id={review.id}/>
    </div>
  </>
}
