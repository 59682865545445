import {useEffect, useRef, useState} from 'react'
import {Card} from 'primereact/card'
import {Button} from 'primereact/button'
import {InputText} from 'primereact/inputtext'
import {Password} from 'primereact/password'
import {Messages} from 'primereact/messages'
import {Fieldset} from 'primereact/fieldset'
import {Link} from "react-router-dom";
import axios from 'axios'

import Menu from "../components/Menu";


export default function PageLogin() {


  const qrcoderef = useRef(null)
  const twofaref = useRef(null)
  const messages = useRef(null)


  const [state, setState] = useState({
    email: '',
    password: '',
    svg: '',
    secret: '',
    step: '',
    two_fa: ''
  })

  useEffect(() => {
    if (state.step === 'check_two_fa') {
      twofaref.current?.focus()
    }
  }, [state.step])

  const get2faHtml = () => {
    return <Card>
      <div style={{textAlign: 'center'}}>
        <h3>Vul hier de Google Authenticator response in</h3>

        <div style={{paddingTop: '1rem'}}>
          <InputText value={state.two_fa}
                     ref={twofaref}
                     onChange={(e) => {
                       setState({...state, two_fa: e.target.value})
                       if (e.target.value.length === 6) {
                         submit(e.target.value)
                       }
                     }}
                     style={{width: '100'}}/>
        </div>
      </div>

    </Card>

  }

  const qrCodeHtml = () => {
    return <Card>
      <div style={{textAlign: 'center'}}>
        <h3>Scan deze QR code met Google 2Fa app</h3>
        <div ref={qrcoderef}/>
        <div>
          <div>Vul de response in en klik op OK</div>
        </div>
        <div style={{paddingTop: '1rem'}}>
          <InputText value={state.two_fa}
                     onChange={(e) => {
                       setState({...state, two_fa: e.target.value})
                     }}
                     style={{width: '100px'}}/>
        </div>
        <div style={{paddingTop: '1rem'}}>
          <Button label="OK" onClick={() => submit2fa()} style={{marginRight: '.25em'}}/>
        </div>
      </div>

    </Card>
  }

  const loginHtml = () => {
    return <>
      <Card footer={<Button
      label="Login"
      onClick={() => submit()}
      style={{marginRight: '.25em'}}/>}>

      {process.env.REACT_APP_ENV !== 'PROD' && <div style={{
        backgroundColor: 'darkorange',
        padding: '0.25rem',
        textAlign: 'center',
        marginBottom: '1rem'
      }}>
        <p>This is {process.env.REACT_APP_ENV}</p>
        <p>
          For production <a
          style={{textDecoration: 'none', color: '#666'}}
          href="https://allrez.allyourz.nl">click here...</a>
        </p>
      </div>}

      <form>
        <div className="grid">
          <div className="col-12">
            <div className="p-inputgroup">
              <div className="p-inputgroup-addon">
                <i className="pi pi-user"></i>
              </div>
              <InputText value={state.email}
                         placeholder="Username"
                         onChange={(e) => {
                           setState({...state, email: e.target.value})
                         }}
                         style={{width: '100%'}}/>

            </div>
          </div>
          <div className="col-12">
            <div className="p-inputgroup">
              <div className="p-inputgroup-addon">
                <i className="pi pi-lock"></i>
              </div>
              <Password value={state.password}
                        placeholder="Password"
                        feedback={false}
                        onChange={(e) => {
                          setState({...state, password: e.target.value})
                        }}
                        style={{width: '100%'}}/>
            </div>
          </div>
        </div>

      </form>
    </Card>
    <div style={{
      marginTop: '1rem',
      fontSize: '0.9rem'
    }}>
      Forgot password? <Link to="/forgot-password">Click here...</Link>
    </div>
  </>

  }


  const success = (data) => {
    // userId and api_token
    let AUTH_TOKEN = data.access_token
    sessionStorage.setItem('api_token', AUTH_TOKEN);
    sessionStorage.setItem('hostname', data.hostname);
    sessionStorage.setItem('user_name', data.user_name);
    sessionStorage.setItem('user_fields', JSON.stringify(data.user_fields));
    sessionStorage.setItem('entrepreneur_id', data.entrepreneur_id);
    sessionStorage.setItem('user_roles', JSON.stringify(data.user_roles));
    sessionStorage.setItem('language', 'nl');

    // Jump
    if(data.entrepreneur_id){
      document.location.href = '/allyourz/entrepreneur/' + data.entrepreneur_id
    } else {
      document.location.href = '/allyourz/administrations'
    }



  }

  const check2fa = (data) => {
    setState({...state, step: data.step})
  }

  const submit2fa = () => {
    const {email, password, secret, two_fa} = state
    axios.post(process.env.REACT_APP_API + '/auth/set-2fa', {
      email,
      password,
      secret,
      two_fa
    }).then(res => {
      success(res.data)
    }).catch((e) => {
      messages.current.show({
        severity: 'error',
        detail: 'Validation failed',
        closable: false
      });
    })
  }
  const createTwoFaSecret = (data) => {

    /**
     * Create the QR code for 2FA
     */
    let blob = new Blob([data.svg], {type: 'image/svg+xml'});
    let url = URL.createObjectURL(blob);
    let image = document.createElement('img');
    image.src = url;
    image.style.width = '250px'
    image.addEventListener('load', () => URL.revokeObjectURL(url), {once: true});
    qrcoderef.current.innerHTML = image.outerHTML


  }
  const submit = (two_fa) => {

    const data = two_fa ? {...state, two_fa} : {...state}

    axios.post(process.env.REACT_APP_API + '/auth/login', data).then(
      (res) => {
        switch (res.data.step) {
          case 'success':
            success(res.data)
            break;
          case 'create_two_fa_secret':
            setState({
              ...state,
              svg: res.data.svg,
              secret: res.data.secret,
              step: res.data.step
            })
            createTwoFaSecret(res.data)
            break;
          case 'check_two_fa':
            check2fa(res.data)
            break;
          default:
        }
      }
    ).catch((e) => {
      messages.current.show({
        severity: 'error',
        detail: 'Validation failed',
        closable: false
      });
    })
  }
  return (
    <div className="page login">
      <Menu/>
      <Fieldset legend="Login" style={{fontSize: '1.3rem'}}>

        <div className="Login grid">
          <div className="xl:col-4"/>
          <div className="xl:col-4">
            {state.step === '' && loginHtml()}
            {state.step === 'create_two_fa_secret' && qrCodeHtml()}
            {state.step === 'check_two_fa' && get2faHtml()}
            <Messages ref={messages}/>
          </div>
          <div className="xl:col-4"/>
        </div>
      </Fieldset>
    </div>
  )

}


