import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Menu from "./food/Menu";

export default function Extras({ administrationData, save, cancelOrReload }) {
  const [dirty, setDirty] = useState(false);
  const [data, setData] = useState(null);
  const [social, setSocial] = useState(null);

  useEffect(() => {
    setData(administrationData);
    setSocial(administrationData.social || {});
    setDirty(null);
  }, [administrationData]);

  /**
   * Handle dirty
   */
  useEffect(() => {
    if (data && social) {
      setDirty((d) => (d === null ? false : true));
    }
  }, [data, social]);

  if (!data && !social) return null;
  return (
    <div className="grid">
      <div className="col-6">
        <Menu 
          administration_id={administrationData.id} 
          data={data}
          setData={setData}
          setDirty={setDirty}
        />
      </div>
      <div className="col-6">
        <div className="grid">
          <div className="col-3 fieldname">Telefoon Nummer</div>
          <div className="col-9">
            <InputText
              value={data.tel || ""}
              onChange={(e) => setData({ ...data, tel: e.target.value })}
            />
          </div>
        </div>

        <div className="grid">
          <div className="col-3 fieldname">Email</div>
          <div className="col-9">
            <InputText
              value={data.email || ""}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
          </div>
        </div>

        <div className="grid">
          <div className="col-3 fieldname">Facebook</div>
          <div className="col-9">
            <InputText
              value={social.facebook || ""}
              onChange={(e) =>
                setSocial({ ...social, facebook: e.target.value })
              }
            />
          </div>
        </div>

        <div className="grid">
          <div className="col-3 fieldname">Instagram</div>
          <div className="col-9">
            <InputText
              value={social.instagram || ""}
              onChange={(e) =>
                setSocial({ ...social, instagram: e.target.value })
              }
            />
          </div>
        </div>

        <div className="grid">
          <div className="col-3 fieldname">Website</div>
          <div className="col-9">
            <InputText
              value={social.website || ""}
              onChange={(e) =>
                setSocial({ ...social, website: e.target.value })
              }
            />
          </div>
        </div>

        <div className="grid">
          <div className="col-3 fieldname">Formitable (id)</div>
          <div className="col-9">
            <InputText
              value={social.formitable || ""}
              onChange={(e) =>
                setSocial({ ...social, formitable: e.target.value })
              }
            />
          </div>
        </div>

        <div className="grid">
          <div className="col-3 fieldname">Resengo (id)</div>
          <div className="col-9">
            <InputText
              value={social.resengo || ""}
              onChange={(e) =>
                setSocial({ ...social, resengo: e.target.value })
              }
            />
          </div>
        </div>

        <div className="grid">
          <div className="col-3 fieldname">zeelandeet.nl</div>
          <div className="col-9 pt-3">{social.zeelandeet}</div>
        </div>
        <div className="grid">
          <div className="col-3 fieldname"></div>
          <div className="col-9 pt-3">
            <Button
              className="p-button-warning"
              disabled={!dirty}
              onClick={() => {
                cancelOrReload();
              }}
              label="Cancel"
            />

            <Button
              className="p-button-success"
              disabled={!dirty}
              onClick={() => {
                // Move back the social
                data.social = social;
                save(data);
              }}
              label="Save"
              style={{ float: "right" }}
            />
          </div>
        </div>
      </div>

    </div>
  );
}
