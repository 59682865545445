import {useEffect, useState} from "react";
import axios from "axios";
import jsonPrettyPrint from "../../services/jsonPrettyPrint";
import {Button} from "primereact/button";


export default function PostmanEntrepreneur({entrepreneur_id}) {

  const [data, setData] = useState(null)
  const [load, setLoad] = useState(false)
  const [update, setUpdate] = useState(false)

  useEffect(() => {
    if (!load) return
    axios.get(`allyourz/entrepreneur-postman-by-id?id=${entrepreneur_id}`).then(res => {
      setData(res.data)
      setLoad(false)
    })
  }, [entrepreneur_id, load])


  useEffect(() => {
    if (!update) return
    axios.get(`allyourz/entrepreneur-first-import?id=${entrepreneur_id}`).then(res => {
      setUpdate(false)
    })
  }, [entrepreneur_id, update])


  return <div className="grid postman">

    <div className="col-offset-2 col-4">
      <Button
        label="Show Raw Data..."
        loading={load}
        onClick={() => setLoad(true)}
      />
    </div>

    <div className="col-4">
      <Button
        label="Importeer Nieuwe Bookables..."
        className="right"
        severity="warning"
        loading={update}
        onClick={() => setUpdate(true)}
      />
    </div>

    {data && <div className="col-offset-2 col-8">
      <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(data)}}/>
    </div>}

  </div>
}
