import {useContext, useEffect, useRef, useState} from "react";
import {UserContext} from "../../contexts/UserContext";

import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";
import {Checkbox} from "primereact/checkbox";
import {Dropdown} from "primereact/dropdown";
import {Toast} from "primereact/toast";
import {classNames} from "primereact/utils";
import Remarks from "../atoms/Remarks";

export default function Bookable({bookableData, src, reload, save}) {
  const user = useContext(UserContext)
  const [data, setData] = useState({})
  const [saving, setSaving] = useState(false)
  const [tree, setTree] = useState([])
  const [otherAdmins, setOtherAdmins] = useState([])

  const toast = useRef()

  useEffect(() => {
    setSaving(false)
    setData(bookableData.bo)
    setTree(bookableData.tree)
    setOtherAdmins(bookableData.other_admins)
  }, [bookableData])


  return <>
    <Toast ref={toast} position="top-left"/>
    <div className="grid">
      <div className="col-2 fieldname">Naam</div>
      <div className="col-10">
        <InputText value={data.name || ''} onChange={(e) => setData({...data, name: e.target.value})}/>
      </div>
      <div className="col-2 fieldname">
        <div>
          Omschrijving
        </div>
        <div style={{paddingTop: '1rem'}}>
          <Button label="Save"
                  disabled={saving}
                  icon={classNames({'pi pi pi-spin pi-spinner': saving})}
                  onClick={() => {
                    setSaving(true)
                    save(data)
                  }}
          />
        </div>
      </div>
      <div className="col-10">
        <InputTextarea value={data.description || ''}
                       onChange={(e) => setData({...data, description: e.target.value})}
                       rows={6}
                       autoResize/>
      </div>
    </div>
    {user.field('book.is_active').view &&
      <div className="grid">
        <div className="col-10 col-offset-2">
          <Checkbox inputId="cb1"
                    onChange={(e) => setData({...data, is_active: e.checked})}
                    checked={!!data.is_active}></Checkbox>
          <label style={{marginLeft: '0.5rem'}} htmlFor="cb1" className="p-checkbox-label">Is Active</label>
        </div>
      </div>}
    {user.field('book.is_checked').view &&
      <div className="grid">
        <div className="col-10 col-offset-2">
          <Checkbox inputId="cb2"
                    onChange={(e) => setData({...data, is_checked: e.checked})}
                    checked={!!data.is_checked}></Checkbox>
          <label style={{marginLeft: '0.5rem'}} htmlFor="cb2" className="p-checkbox-label">Is Checked</label>
        </div>
      </div>}
    {user.field('book.is_published').view &&
      <div className="grid">
        <div className="col-10 col-offset-2">
          <Checkbox inputId="cb3"
                    onChange={(e) => setData({...data, is_published: e.checked})}
                    checked={!!data.is_published}></Checkbox>
          <label style={{marginLeft: '0.5rem'}} htmlFor="cb3" className="p-checkbox-label">Is Published</label>
        </div>
      </div>}
    <div className="grid">
      <div className="col-2 fieldname">Categorie</div>
      <div className="col-10">
        <Dropdown
          value={data.category_id}
          options={tree}
          onChange={(e) => setData({...data, category_id: e.target.value})}
          optionLabel="label"
          optionGroupLabel="label"
          optionGroupChildren="items"
          optionGroupTemplate={(option) => option.label}
          style={{width: '100%'}}
          placeholder="Selecteer een activiteit"/>
      </div>
    </div>


    {user.field('book.is_published').view && ['nextpax', 'opengds','qenner'].includes(src) &&
      <div className="grid">
        <div className="col-2 fieldname">External Id</div>
        <div className="col-10">
          <InputText value={data.external_id || ''} onChange={(e) => setData({...data, external_id: e.target.value})}/>
        </div>
      </div>}

    <div className="grid">
      <div className="col-2 fieldname">Gasten</div>
      <div className="col-1 fieldname">Minimaal</div>
      <div className="col-1">
        <InputText value={data.min_persons || ''} onChange={(e) => setData({...data, min_persons: e.target.value})}/>
      </div>
      <div className="col-1 fieldname">Maximaal</div>
      <div className="col-1">
        <InputText value={data.max_persons || ''} onChange={(e) => setData({...data, max_persons: e.target.value})}/>
      </div>
      <div className="col-3 fieldname text-right">Extra gast fee vanaf</div>
      <div className="col-1">
        <InputText className="text-center" value={data.extra_person_threshold || 0}
                   onChange={(e) => setData({...data, extra_person_threshold: e.target.value})}/>
      </div>
      <div className="col-2 fieldname">Personen</div>
    </div>
    <div className="grid">
      <div className="col-2 fieldname">Huisdieren</div>
      <div className="col-1 fieldname">Maximaal</div>
      <div className="col-1">
        <InputText value={data.max_pets || ''} onChange={(e) => setData({...data, max_pets: e.target.value})}/>
      </div>

      {user.field('book.never_pets').view &&
        <>
          <div className="col-2"/>
          <div className="col-3 fieldname text-right">
            <label htmlFor="never_pets">Huisdieren Nooit Toegestaan</label></div>
          <div className="col-1">
            <Checkbox inputId="never_pets"
                      className="mt-1"
                      checked={!!data.never_pets || false}
                      onChange={(e) => setData({...data, never_pets: e.checked})}/>
          </div>
        </>}
    </div>

    {user.field('sys.move_book_to_other_admin').view &&
      <div className="grid">
        <div className="col-2 fieldname">Verplaats naar admin</div>
        <div className="col-10">
          <Dropdown value={data.administration_id}
                    options={otherAdmins}
                    onChange={(e) => {
                      const clone = {...data}
                      clone.administration_id = e.target.value
                      setData(clone)
                    }}/>
        </div>
      </div>}
    {user.field('sys.remark').view &&
      <div className="grid">
        <div className="col-2 fieldname">Opmerkingen</div>
        <div className="col-10">
          <Remarks id={data.id}/>
        </div>
      </div>}

  </>

}
