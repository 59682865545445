import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {useState} from "react";
import {confirmDialog} from "primereact/confirmdialog";
import axios from "axios";
import Remarks from "../atoms/Remarks";
import jsonPrettyPrint from "../../services/jsonPrettyPrint";
import xmlPrettyPrint from "../../services/xmlPrettyPrint";
import Highlight from 'react-highlight'


export default function CancelBooking({data, popHead, closeMe, onResolved, reload}) {

  const [remark, setRemark] = useState('')

  const cancelBooking = () => {
    confirmDialog({
      position: 'top',
      header: 'Boeking Annuleren.',
      message:<>
        <p>Door op 'Ja' te klikken annuleer je deze boeking.
        <br/>
        <br/>
        Weet je dit zeker?
      </p></>,
      icon: 'pi pi-exclamation-triangle',

      rejectLabel: 'Nee',
      acceptLabel: 'Ja',
      accept: () => {
        axios.post('/allyourz/bookings-cancel', {
          product_id: data.id,
          remark: "Boeking geannuleerd.\n\n" + remark
        }).then(res => {
          onResolved() // Update table (Bookings)
          reload()     // Update the BookPayload part
        })
      }
    });
  }

  return <>
    {data.reservation_status === 'cancelled' ?
      //////////////////////////////////////////////////////////////////
      //                  THIS BOOKING IS CANCELLED                   //
      //////////////////////////////////////////////////////////////////
      <>
        <div className="grid mt-2">
          <div className="col-2 font-bold">
            Geannuleerde boeking
          </div>
          <div className="col-10">
            {popHead}
          </div>
          <div className="col-2 font-bold">
            Opmerkingen
          </div>
          <div className="col-5">
            <Remarks id={data.reservation_id}/>
          </div>
          <div className="col-5"/>
        </div>

        <div className="grid">
          <div className="col-2 font-bold">
          </div>
          <div className="col-10">
            <span className="ml-1" style={{
              color: (data.payload_cancel_success ? 'green' : 'darkred'),
              fontSize: '1.2rem'
            }}>
              {data.payload_cancel_success ? 'Deze boeking is succesvol geannuleerd.' : 'Fout opgetreden tijdens het annuleren.'}
            </span>

          </div>
        </div>
        <div className="grid">
          <div className="col-2 font-bold">
            Response
          </div>
          <div className="col-10">
            {['nextpax', 'opengds'].includes(data.src) &&
              <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(data.payload_cancel_response)}}/>
            }
            {['cubilis', 'qenner'].includes(data.src) &&
              <Highlight className="xml">
                {data.payload_cancel_success
                  ? xmlPrettyPrint(data.payload_cancel_response)
                  : xmlPrettyPrint('<error>' + data.payload_cancel_response + '</error>')
                }
              </Highlight>
            }
            {'allrez' === data.src &&
              <div className="ml-1">Dit is een allrez boeking</div>
            }
          </div>
        </div>
        <div className="grid">
          <div className="col-2 font-bold">
            {'allrez' !== data.src && <>Payload</>}
          </div>
          <div className="col-10">
            {['nextpax', 'opengds'].includes(data.src) &&
              <pre dangerouslySetInnerHTML={{__html: jsonPrettyPrint(data.payload_cancel)}}/>
            }
            {['cubilis', 'qenner'].includes(data.src) &&
              <Highlight className="xml">
                {xmlPrettyPrint(data.payload_cancel)}
              </Highlight>
            }
            {/*No allrez */}
          </div>
        </div>
      </>
      :
      //////////////////////////////////////////////////////////////////
      //                  YOU CAN CANCEL THIS BOOKING                 //
      //////////////////////////////////////////////////////////////////
      <div className="grid mt-2">
        <div className="col-2 font-bold">

        </div>
        <div className="col-7">
          <ul>
            <li>Bij Cubilis, OpenGDS en Nextpax en Qenner kan het even duren voor de bookable weer beschikbaar komt.
            </li>
            <li>Een allrez bookable staat in allrez onmiddelijk weer open.</li>
            <li>Via iCal staat de bookable weer open wanneer bijvoorbeeld Booking of Airbnb hun iCal import draait.</li>
          </ul>
        </div>
        <div className="col-3"/>
        <div className="col-2 font-bold">
          Te annuleren boeking
        </div>
        <div className="col-10">
          {popHead}
        </div>
        <div className="col-2 font-bold">
          Reden annulering
        </div>
        <div className="col-10">
          <InputTextarea
            autoFocus
            style={{width: '50%'}}
            value={remark}
            onChange={(e) =>
              setRemark(e.target.value)}
            rows={5}
          />
        </div>
        <div className="col-offset-2 col-10">
          <Button className="p-button-danger"
                  disabled={!remark}
                  onClick={() => cancelBooking()}
                  label="Annuleer Deze Boeking"/>
          <Button className="p-button-warning ml-4"
                  onClick={closeMe}
                  label="Cancel"/>

        </div>

        <div className="col-2 font-bold">
          Opmerking
        </div>
        <div className="col-5">
          <Remarks id={data.reservation_id}/>
        </div>
        <div className="col-5"/>
      </div>}
  </>
}
