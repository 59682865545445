import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {UserContext} from "../../contexts/UserContext";
import {useNavigate} from "react-router-dom";

export default function BookablesList({entrepreneur_id}) {
  const user = useContext(UserContext)
  const [data, setData] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if (entrepreneur_id) {
      axios.get('/allyourz/bookable-all-by-entrepreneur-id?id=' + entrepreneur_id).then(res => {
        setData(res.data)
      })
    } else {
      axios.get('/allyourz/bookable-all').then(res => {
        setData(res.data)
      })
    }
  }, [user.language, entrepreneur_id])

  // Bit yuk. have to wait for them roles
  if (!user.user_roles.length) return null

  return <>
    <DataTable value={data}
               size="small"
               rows={15}
               stripedRows
               sortMode="multiple"
               removableSort={true}
               filterDisplay="row"
               paginator={true}>
      <Column field="b_name"
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              body={row => {
                return <div
                  style={{cursor: 'pointer'}}
                  onClick={() => navigate('/allyourz/bookable/' + row.b_id + '/'  + row.src)}>
                  {row.b_name}
                </div>
              }}
              header="Bookable"/>
      <Column field="a_name"
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              header="Administratie"
              body={row => {
                return <div
                  style={{cursor: 'pointer'}}
                  onClick={() => navigate('/allyourz/administration/' + row.a_id)}>
                  {row.a_name}
                </div>
              }}/>
      <Column field="e_name"
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              header="Ondernemer"
              body={row => {
                return <div
                  style={{cursor: 'pointer'}}
                  onClick={() => navigate('/allyourz/entrepreneur/' + row.e_id)}>
                  {row.e_name}
                </div>
              }}/>
      <Column field="src"
              style={{width: '8rem'}}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              header="Bron"/>
      {user.field('sys.book_id').view &&
        <Column field="b_id"
                style={{width: '10rem'}}
                body={row => row.b_id.substring(0,12) + '…'}
                filter={true}
                header="Bookable Id"/>}
      <Column field="b_ext_id"
              style={{width: '8rem'}}
              sortable={true}
              filter={true}
              header="Extern Id"/>
      <Column field="status"
              style={{width: '8rem'}}
              sortable={true}
              filter={true}
              filterMatchMode={"contains"}
              className="text-center"
              header="A/C/P"/>
    </DataTable>

  </>
}
