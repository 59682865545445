import {useEffect, useState} from "react";
import axios from "axios";
import jsonPrettyPrint from "../../services/jsonPrettyPrint";

export default function DebugActivity({activity_id}){

  const [debug,setDebug]=useState('')

  useEffect(()=>{
    axios.get('/allyourz/debug-activity?id=' + activity_id).then(res=>{
      setDebug(res.data)
    })
  },[activity_id])


  return <>
    <pre className="json-pretty-print" dangerouslySetInnerHTML={{__html: jsonPrettyPrint(debug)}}/>
  </>
}
