import {useContext, useEffect, useState} from 'react'
import {Menubar} from 'primereact/menubar';
import {Button} from 'primereact/button';
import axios from 'axios'
import {UserContext} from "../contexts/UserContext";
import {useNavigate, useLocation} from "react-router-dom";
import {Dropdown} from 'primereact/dropdown';
import {classNames} from "primereact/utils";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import AyLogo from '../media/logo.svg'
import {confirmDialog} from 'primereact/confirmdialog';
import {CosmeticContext} from "../contexts/CosmeticContext";


export default function Menu() {

  const user = useContext(UserContext)
  const cosmetic = useContext(CosmeticContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [newEntDialog, setNewEntDialog] = useState(false)
  const [newEntName, setNewEntName] = useState('')
  const [bookingError, setNewBookingError] = useState(false);
  const [newBookingAlert, setNewBookingAlert] = useState(false);
  const [newReview, setNewReview] = useState(false);

  const checkForBookingAlert = () => {
    axios.get('/allyourz/booking-error-alert').then(res => {
      setNewBookingAlert(!!res.data.alert)
      setNewBookingError(!!res.data.error)
      setNewReview(!!res.data.new_review)
    });
  }

  useEffect(() => {
    if (cosmetic.reloadMenu !== null) {
      checkForBookingAlert()
    }
  }, [cosmetic.reloadMenu]);

  useEffect(() => {
    if (user.hasRole('admin')) {

      let handle
      handle = setTimeout(() => {
        checkForBookingAlert()
      }, 1000 * 60)

      return () => {
        clearTimeout(handle)
      }
    }
  }, [user])

  //
  const t = location.pathname.split('/')
  const activeMenu = t[1] + '/' + t[2]

  const entry = (label, icon, path) => {

    const active = location.pathname.startsWith(path.slice(0, -1))

    let piSpin = false, error = false;
    switch (icon) {
      case 'book':
        piSpin = newBookingAlert;
        error = bookingError;
        break;
      case 'star':
        piSpin = newReview;
        if (piSpin) {
          // icon = 'star-fill'
        }
        break;
      default:
      //
    }

    if (icon) {
      return {
        label,
        icon: classNames(`pi pi-${icon}`, {'pi-spin': piSpin}, {error}),
        className: classNames({active}),
        command: () => {
          if (active && icon === 'book' && (piSpin || error)) {
            confirmDialog({
              position: 'top',
              header: 'Markeer als gezien.',
              message: <><p>Door op Ja te klikken verwijder je de nieuwe boeking melding (het roteren van het boek).
                <br/>
                <br/>
                Wanneer het boek <strong style={{color: '#FD7577'}}>rood</strong> is is er een probleem met een of
                meerdere boekingen.
              </p></>,
              icon: 'pi pi-exclamation-triangle',
              rejectLabel: 'Nee',
              acceptLabel: 'Ja',
              accept: () => {
                axios.post('/allyourz/booking-error-alert').then(res => {
                  setNewBookingAlert(false)
                }).then(res => {
                })
              }
            });
            // active means we have new bookings and the icon is spinning
            // Let it stop spinning by clicking again on the icon
          } else {
            navigate(path)
          }
        }
      }
    } else {
      return {
        label,
        className: classNames({active}),
        command: () => navigate(path)
      }
    }
  }

  const newEntSave = () => {
    axios.put('/allyourz/entrepreneur-new', {name: newEntName}
    ).then(res => {
      setNewEntName('')
      setNewEntDialog(false)
      document.location.href = '/allyourz/entrepreneur/' + res.data.id
    })
  }

  const menuItems = () => {

    let allItems = []

    // LANGUAGE SWITCH
    if (user.isLoggedIn()) {
      allItems.push({

        label: <>
          <div style={{display: 'flex'}}>

            {/*LANGUAGE SWITCH*/}
            <Dropdown
              value={user.language}
              options={[{label: 'nl', value: 'nl'}, {label: 'de', value: 'de'}, {label: 'en', value: 'en'}]}
              onChange={(e) => user.changeLanguage(e.value)}/>

            {/*LOGO*/}
            <img style={{marginLeft: '1rem', marginTop: '0.25rem', height: '1.6rem'}} src={AyLogo} alt="Allyourz Logo"/>

            {/*PREMIUM MODEL*/}
            <div style={{marginLeft: '0.25rem', marginTop: '-0.9rem', marginBottom: '-0.9rem'}}>
              <div className="text-center pt-2">&nbsp;
                {user.hasRole('basis') ? <i></i> : <i style={{fontSize: '2rem'}} className="pi pi-verified"/>}
              </div>
              <div className="text-center" style={{fontSize: '0.9rem', minWidth: '3rem'}}>
                {user.getPremiumRoleName()}
              </div>
            </div>

          </div>
        </>
      })
    } else {
      allItems.push({
        label: <div style={{display: 'flex'}}>
          <img style={{marginLeft: '1rem', marginTop: '0.25rem', height: '1.6rem'}} src={AyLogo} alt="Allyourz Logo"/>
        </div>
      })
    }

    if (user.hasRole('admin')) {
      // When admin all entrepreneurs and all administrations and some more
      allItems.push(entry('Ondernemers', 'building', '/allyourz/entrepreneurs'))
      allItems.push(entry('Administraties', 'home', '/allyourz/administrations'))
      allItems.push(entry('Bookables', 'box', '/allyourz/bookables'))
      allItems.push(entry('Boekingen', 'book', '/allyourz/boekingen'))
      allItems.push(entry('Reviews', 'star', '/allyourz/reviews'))

      const adminItems = [];
      adminItems.push(entry('Faciliteiten', 'sort-alt', '/allyourz/facility-order'))
      adminItems.push(entry('AddOns', 'plus-circle', '/allyourz/manage-addons'))
      adminItems.push(entry('Qenner AddOns', 'minus-circle', '/allyourz/manage-qenner-addons'))
      adminItems.push(entry('Aanmeldingen', 'thumbs-up', '/allyourz/aanmeldingen'))
      adminItems.push(entry('Gasten', 'users', '/guests'))
      adminItems.push(entry('Allyourz', 'users', '/users/allyourz'))
      adminItems.push(entry('Ondernemers', 'users', '/users/entrepreneurs'))
      adminItems.push(entry('Rapportages', 'microsoft', `/allyourz/reports`))
      adminItems.push(entry('Localbooker', 'chart-line', `/allyourz/localbooker`))
      adminItems.push(entry('Schedules', 'clock', `/allyourz/schedules`))
      if(user.hasRole('finance')){
        adminItems.push(entry('DAC7', 'money-bill', `/dac7`))
      }
      allItems.push({label: 'Admin', icon: 'pi pi-sliders-v', items: adminItems})
    } else if (user.entrepreneur_id) {
      allItems.push(entry('Ondernemer', 'building', `/allyourz/entrepreneur/${user.entrepreneur_id}`))
      allItems.push(entry('Administratie', 'home', `/allyourz/administrations/${user.entrepreneur_id}`))
      allItems.push(entry('Bookables', 'box', `/allyourz/bookables/${user.entrepreneur_id}`))
      allItems.push(entry('Boekingen', 'book', `/allyourz/boekingen/${user.entrepreneur_id}`))
    }

    return allItems
  }

  const rightMenu = () => {
    return <>
      <Button label={user.user_name}
              title="Uitloggen"
              icon="pi pi-power-off"
              onClick={() => logout()}
              style={{marginRight: '0.5em', float: "right"}}/>

      {activeMenu === 'allyourz/entrepreneurs' &&  user.isLoggedIn() && user.field('sys.add_entrepreneur').view &&
        <Button icon="pi pi-building"
                title="Nieuwe ondernemer toevoegen"
                className="p-button-success mr-8"
                onClick={() => setNewEntDialog(true)}
                style={{marginRight: '0.5em', float: "right"}}/>}

    </>
  }

  const newEntDialogHtml = () => {
    return <div className="grid">
      <div className="col-12">
        Naam
      </div>
      <div className="col-12">
        <InputText style={{width: '100%'}} value={newEntName} onChange={e => setNewEntName(e.target.value)}/>
      </div>
      <div className="col-6">
        <Button label="Cancel"
                className="p-button-warning"
                onClick={() => {
                  setNewEntName('')
                  setNewEntDialog(false)
                }}
        />
      </div>
      <div className="col-6">
        <Button label="OK"
                onClick={() => newEntSave()}
                style={{float: 'right'}}/>
      </div>
    </div>
  }


  const logout = () => {
    axios.post(process.env.REACT_APP_API + '/auth/logout').then(
      (res) => {
        if (res.data.msg === 'ok') {
          user.logout()
          navigate('/')
        }
      }).catch(e => {
      navigate('/')
    })
  }

  return <HelmetProvider>

    <Dialog header="Nieuwe Ondernemer"
            position="top"
            visible={newEntDialog}
            style={{width: '30vw'}}
            onHide={() => setNewEntDialog(false)}>{newEntDialogHtml()}
    </Dialog>

    <Helmet>
      <title>{user.language.toUpperCase()} - Allyourz</title>
    </Helmet>

    <Menubar
      model={menuItems()}
      end={
        rightMenu()
      }/></HelmetProvider>
}
