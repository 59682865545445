import {Fieldset} from "primereact/fieldset";
import Menu from "../../components/Menu";
import {useParams} from "react-router-dom";
import Reviews from "../../components/reviews/Reviews";
export default function PageReviews() {
  const params = useParams()
  return (
    <div className="page all-reviews">
      <Menu/>
      <Fieldset legend="Reviews">
        <Reviews entrepreneur_id={params.entrepreneur_id}/>
      </Fieldset>
    </div>
  )
}


