import axios from "axios";
import React, {useEffect, useState} from 'react'

axios.defaults.baseURL = process.env.REACT_APP_API

const api_token = sessionStorage.getItem('api_token');

if (api_token) {
  // Set oAuth header when we have one
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + api_token;
  axios.defaults.headers.common['Allyourz-Language'] = sessionStorage.getItem('language')
}

const UserContext = React.createContext({});

const UserContextProvider = ({children}) => {

  const [loaded, setLoaded] = useState(false)
  const [user_name, setUserName] = useState('')
  const [user_roles, setUserRoles] = useState([])
  const [user_fields, setUserFields] = useState({})
  const [entrepreneur_id, setEntrepreneurId] = useState('')
  const [language, setLanguage] = useState('nl')

  const hasRole = (roleName) => {
    return user_roles.includes(roleName)
  }

  const getPremiumRoleName = () => {
    if(user_roles.includes('admin')){
      return 'Admin'
    }
    if(['premium', 'premium_zero'].filter(v => user_roles.includes(v)).length){
      return 'Premium'
    }
    if(['pro', 'pro_zero'].filter(v => user_roles.includes(v)).length){
      return 'Pro'
    }
    if(user_roles.includes('unlimited')){
      return 'Unlimited'
    }
    return 'Basis'
  }

  const isLoggedIn = () => {
    return !!sessionStorage.getItem('api_token')
  }

  const changeLanguage = (lang) => {
    axios.defaults.headers.common['Allyourz-Language'] = lang
    sessionStorage.setItem('language', lang)
    setLanguage(lang)
  }

  /**
   * view update is bitwise. 01 = view 10 = edit 11 = both
   * when not found -> {view: false, update: false}
   */
  const field = (name) => {

    const f = user_fields[name];

    if (!f) {
      return {view: false, edit: false}
    }

    return {
      view: (f & 1) === 1,
      edit: (f & 2) === 2,
    }
  }


  const logout = () => {
    sessionStorage.clear()
    axios.defaults.headers.common['Authorization'] = null
    axios.defaults.headers.common['Allyourz-Language'] = null
    setUserName('')
    setEntrepreneurId('')
    setUserRoles([])
    setUserFields([])
  }

  useEffect(() => {
    if (sessionStorage.getItem('api_token')) {
      setEntrepreneurId(sessionStorage.getItem('entrepreneur_id'))
      setUserName(sessionStorage.getItem('user_name'))
      setUserRoles(JSON.parse(sessionStorage.getItem('user_roles')))
      setLanguage(sessionStorage.getItem('language'))
      setUserFields(JSON.parse(sessionStorage.getItem('user_fields')))
      setLoaded(true)
    }
  }, [])


  return <UserContext.Provider value={{
    user_name,
    user_roles,
    user_fields,
    entrepreneur_id,
    language,
    loaded,
    field,
    hasRole,
    logout,
    changeLanguage,
    isLoggedIn,
    getPremiumRoleName
  }}>
    {children}
  </UserContext.Provider>
}

export {UserContext, UserContextProvider}
