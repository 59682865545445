import {useContext, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import Menu from "../../components/Menu";
import {TabView, TabPanel} from 'primereact/tabview'
import MediaItems from "../../components/MediaItems"
import Facilities from "../../components/facilities/Facilities"
import Faq from "../../components/Faq"
import BookableObjects from "../../components/bookable/BookableObjects";
import AdministrationType from "../../components/AdministrationType";
import Quote from "../../components/Quote";
import PolicyRules from "../../components/PolicyRules";
import BreadCrumbs from "../../components/BreadCrumbs";
import Bookings from "../../components/bookings/Bookings";
import Extras from "../../components/Extras";
import axios from "axios";
import {UserContext} from "../../contexts/UserContext";
import {Toast} from "primereact/toast";
import {BreadcrumbContext} from "../../contexts/BreadcrumbContext";
import Activities from "../../components/activity/Activities";
import {Button} from "primereact/button";
import Tips from "../../components/Tips"


export default function PageAdministrationType() {
  const toast = useRef()
  const user = useContext(UserContext)
  const breadcrumbs = useContext(BreadcrumbContext)

  const params = useParams()
  const [data, setData] = useState({})
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    axios.get('/allyourz/administration-by-id', {params: {id: params.id}}).then(res => {
      // Sets the data
      setData(res.data)

      const at = res.data.at
      // Set breadCrumbs
      breadcrumbs.setAdministration(
        at.entrepreneur_id, at.entrepreneur_name,
        at.id, at.name
      )
    })
    // eslint-disable-next-line
  }, [user.language, params.id])

  /**
   * Save
   */
  const save = (data) => {

    axios.put('/allyourz/administration-by-id', data).then(res => {
      setData(res.data)
      toast.current.show({
        life: 2000,
        severity: 'success',
        summary: data.name,
        detail: 'is succesvol bijgewerkt!'
      });
    }).catch(e => {
      toast.current.show({
        life: 5000,
        severity: 'error',
        summary: 'Invoer fout',
        detail: e.response.data.message
      });
    })
  }
  /**
   * Cancel
   */
  const cancelOrReload = () => {
    axios.get('/allyourz/administration-by-id', {params: {id: params.id}}).then(res => {
      setData(res.data)
    })
  }

  if (!Object.keys(data).length) return null

  return <>
    <Toast ref={toast} position="top-left"/>
    <div className="page administration-type">
      <Menu/>
      <BreadCrumbs/>
      <TabView style={{marginTop: '0.5rem'}}
               activeIndex={activeIndex}
               onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel contentStyle={{padding: '0.5rem'}} header={data.at.name}>
          <AdministrationType
            administrationData={data}
            save={save}
            cancelOrReload={cancelOrReload}
          />
        </TabPanel>
        <TabPanel header="Extra's"
                  style={{
                    marginLeft: '1rem',
                    display: user.field('sys.show_extra').view ? 'block' : 'none'
                  }}>
          <Extras administrationData={data.at} save={save} cancelOrReload={cancelOrReload}/>
        </TabPanel>
        {user.field('adm.tips').view &&
          <TabPanel header="Tips en Meer">
            <div style={{width: '70%'}}>
              <Tips administration_id={params.id}/>
            </div>
          </TabPanel>}

        <TabPanel header="Rules">
          <PolicyRules administrationData={data} administration_id={params.id}/>
        </TabPanel>
        <TabPanel header="Media">
          <MediaItems entityId={params.id} administration_id={params.id}/>
        </TabPanel>
        <TabPanel contentStyle={{padding: '0.5rem'}} header="Faciliteiten">
          <Facilities entityId={params.id} type="administration"/>
        </TabPanel>
        <TabPanel contentStyle={{padding: '0.5rem'}} header="FAQ">
          <Faq administration_id={params.id}/>
        </TabPanel>
        <TabPanel contentStyle={{padding: '0.5rem'}} header="Quote">
          <Quote administration_id={params.id}/>
        </TabPanel>
        {data.at.sbi_slug === 'stay' &&
          <TabPanel className="admin-bookable-tab" contentStyle={{padding: '0.5rem'}} header="Bookables">
            <BookableObjects administration_id={params.id} administration={data} save_administration={save}/>
          </TabPanel>
        }
        {data.at.sbi_slug === 'activity' &&
          <TabPanel header="Activiteiten" contentStyle={{padding: '0.5rem'}}>
            <Activities administration_id={params.id} administration={data} save_administration={save}/>
          </TabPanel>
        }
        {['stay'].includes(data.at.sbi_slug) &&
          <TabPanel header="Boekingen" style={{marginLeft: '1rem'}}>
            <Bookings entrepreneur_id={null} administration_id={params.id}/>
          </TabPanel>
        }
        {/*<TabPanel className="admin-bookable-tab" style={{marginLeft: '1rem'}} leftIcon="pi pi-building"/>*/}

        {user.field('sys.show_debug').view &&
          <TabPanel className="admin-bookable-tab"
                    style={{marginLeft: '1rem'}}

                    headerTemplate={<Button
                      className="p-button-secondary"
                      title="Naar de acceptatie omgeving"
                      onClick={() => {
                        window.open(`${process.env.REACT_APP_ALLYOURZ_NL}/verblijf/${encodeURIComponent(data.at.slug)}`, '_blank', 'noopener,noreferrer');
                      }}
                      style={{height: '2.5rem', width: '2.5rem'}}
                      icon="pi pi-comments"></Button>}
          />}
        <TabPanel className="admin-bookable-tab"
                  style={{marginLeft: '1rem'}}
                  headerTemplate={<Button
                    title="Naar de preview omgeving"
                    onClick={() => {
                      window.open(`${process.env.REACT_APP_ALLYOURZ_NL}/api/preview?secret=6E6oTrb733asoiVeAh&redirectUrl=/verblijf/${encodeURIComponent(data.at.slug)}`, '_blank', 'noopener,noreferrer');
                    }}
                    className="p-button-secondary"
                    style={{height: '2.5rem', width: '2.5rem'}}
                    icon="pi pi-globe"></Button>}
        />
      </TabView>
      <div style={{height: '0.5rem'}}/>


    </div>
  </>
}
