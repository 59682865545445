import {Toast} from "primereact/toast";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Fieldset} from "primereact/fieldset";

export default function Ical({id}) {

  const toast = useRef()

  const [link, setLink] = useState('')
  const [external_links, seExternalLinks] = useState([])

  const [copied, setCopied] = useState(false)
  const [working, setWorking] = useState(false)
  const [fresh, setFresh] = useState(false)

  const [newLink, setnewLink] = useState('')
  const [newLinkName, setnewLinkName] = useState('')

  const load = (id) => {
    axios.get('/allyourz/bookable-get-ical-links', {params: {id}}).then(res => {
      setLink(res.data.link)
      seExternalLinks(res.data.external_links)
    })
  }

  const remove = (link) => {
    axios.post('/allyourz/bookable-delete-ical-link', {id,link}).then(res => load(id))
  }
  const save = () => {
    setWorking(true)
    axios.post('/allyourz/bookable-save-ical-link', {
      id,
      newLink,
      newLinkName
    }).then(res => {
      load(id)
      setWorking(false)
      setFresh(false)
      toast.current.show({
        life: 1000,
        severity: 'success',
        summary: 'Updated successfully!',
        detail: 'OK'
      });
    }).catch((e) => {
      setWorking(false)

      toast.current.show({
        life: 5000,
        severity: 'error',
        summary: 'Er is een fout opgetreden',
        detail: e.response.data.message
      });

    })
  }

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  useEffect(() => {
    load(id)
  }, [id])

  const externalLinks = () => {
    const links = external_links.map((e, i) => {
      return <div key={i} className="grid mb-1">
        <div className="col-9">
          <InputText readOnly={true} value={e.link}/>
        </div>
        <div className="col-2 mt-2">{e.name}</div>
        <div className="col-1">
          <Button icon="pi pi-times" className="p-button-danger" onClick={()=>{
            remove(e.link)
          }}/>
        </div>
      </div>
    })
    return links.length ? <>
      <div className="grid mt-5">
        <div className="col font-bold">
          Bestaande Links
        </div>
      </div>
      {links}
    </> : null

  }

  const externalLinkForm = () => {
    return <>
      <div className="grid mt-4" >
        <div className="col-9">Nieuwe iCal Link</div>
        <div className="col-2">Nieuwe Naam</div>
        <div className="col-1"></div>
      </div>

      <div className="grid mb-4">
        <div className="col-9">
          <InputText value={newLink} onChange={e => setnewLink(e.target.value)}/>
        </div>
        <div className="col-2">
          <InputText value={newLinkName} onChange={e => setnewLinkName(e.target.value)}/>
        </div>
        <div className="col-1">
          <Button className="w-full"
                  label="Save"
                  disabled={working}
                  icon={working && 'pi pi-spinner pi-spin'}
                  onClick={() => {
                    save()
                  }}/>
        </div>
      </div>
    </>
  }


  return <>
    <Toast ref={toast} position="top-left"/>

    <Fieldset legend="Externe Ical koppelingen">
      <Button className="mt-0" label={fresh ? 'Cancel' : 'Nieuwe koppeling met extern systeem toevoegen'} onClick={e => {
        setnewLinkName('')
        setnewLink('')
        setFresh(s => !s)
      }}/>
      {fresh && externalLinkForm()}
      {externalLinks()}
    </Fieldset>


    <Fieldset legend="Koppel Link">
      <div className="grid">
        <div className="col-12">
          <div className="p-inputgroup">
            <Button
              icon="pi pi-copy"
              className="p-button-secondary"
              onClick={() => {
                copyTextToClipboard(link).then(() => {
                  // If successful, update the isCopied state value
                  setCopied(true)
                  setTimeout(() => {
                    setCopied(false)
                  }, 3000)
                })
              }}
            />
            <InputText value={link} readOnly/>
          </div>
        </div>
        {copied && <div className="col-2 mt-2 text-center">Copied to clipboard!</div>}
      </div>
    </Fieldset>
  </>

}
