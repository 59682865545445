import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {Button} from "primereact/button";
import {UserContext} from "../../contexts/UserContext";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import {Checkbox} from "primereact/checkbox";
import {InputTextarea} from "primereact/inputtextarea";

import {classNames} from "primereact/utils";
import {confirmDialog} from "primereact/confirmdialog";
import {Dropdown} from "primereact/dropdown";
import mapToOptions from "../../services/mapToOptions";


export default function Facilities({entityId, type}) {
  const user = useContext(UserContext)

  const [copyMapOptions, setCopyMapOptions] = useState([])
  const [copyFrom, setCopyFrom] = useState(null)

  const [pageData, setPageData] = useState([]) // The data of all the current facilities
  const [pageTree, setPageTree] = useState([]) // The data of all the unused facilities in the MultiSelect
  const [pageTreeData, setPageTreeData] = useState([]) // The multi selcted data
  const [usps, setUsps] = useState([]) // The usps

  // Dialog Flags //
  const [editFacility, setEditFacility] = useState(false)
  const [addFacilities, setAddFacilities] = useState(false)


  // some refs //
  const descriptionRef = useRef();
  const toast = useRef();


  const updatePageData = (res) => {
    setPageData(res.data.facilities)
    setCopyMapOptions(mapToOptions(res.data.copy_from))
    setPageTree(res.data.tree)
    setPageTreeData([])
    setUsps(res.data.usps)

  }

  useEffect(() => {
    axios.get(`/allyourz/facilities`, {params: {entityId, type}}).then(res => {
      updatePageData(res)
    })
  }, [entityId, user.language, type])


  const save = (entityId, type) => {
    axios.post(`/allyourz/facilities`, {entityId, type, selectedItems: pageTreeData}
    ).then(res => {
      setAddFacilities(false)
      updatePageData(res)

      toast.current.show({
        life: 1000,
        severity: 'success',
        summary: 'Updated',
        detail: 'successfully'
      });
    })
  }

  const runCopyFrom = (from) => {


    // Get From name
    const f = copyMapOptions.find(e => e.value === from).label.split(': ')
    const t = copyMapOptions.find(e => e.value === entityId).label.split(': ')
    const fromName = f[1]
    const toName = t[1]
    const fromType = f[0] === 'boo' ? 'bookable' : (f[0] === 'akt' ? 'activity' : 'administration')
    const toType = t[0] === 'boo' ? 'bookable' : (t[0] === 'akt' ? 'activity' : 'administration')

    confirmDialog({
      header: 'Facilities Kopieren',
      message: <>
        Deze actie gooit de de huidige faciliteiten van <strong>{toName}</strong> ({toType}) weg en vervangt deze <br/>
        door de faciliteiten van <strong>{fromName}</strong> ({fromType})<br/><br/>
        Nadat je op <strong>Ja</strong> geklikt hebt is deze actie <strong>DEFINITIEF</strong> en niet meer ongedaan te
        maken.<br/><br/>
        Weet je dit zeker?
      </>,
      acceptLabel: 'Ja',
      rejectLabel: 'Nee',
      accept: () => {
        axios.post(`/allyourz/copy-facilies`, {from, to: entityId, type: toType}).then(res => {
          updatePageData(res)
        })
        setCopyFrom(null)
      },
      reject: () => {
        setCopyFrom(null)
      }

    });

    if (from === entityId) {

    }

  }

  const uspMove = (newusps) => {

    axios.post(`/allyourz/facility-usp-order`, {entityId, type, newusps}).then(res => {
      updatePageData(res)
    });
  }

  const removeFacility = (id) => {
    confirmDialog({
      header: 'Facility verwijderen?',
      message: <>De omschrijving en USP settings zullen permanent verwijderd worden.<br/><br/>Weet je dit zeker?</>,
      acceptLabel: 'Ja',
      rejectLabel: 'Nee',
      accept: () => {
        axios.post(`/allyourz/remove-facility`, {entityId, type, id}).then(res => {
          updatePageData(res)
        })
      }
    });
  }
  const copyFacilityTemplate = (e) => {

    if (!e) return 'Of kopieer van'; // ?? placeholder
    const data = e.label.split(': ')
    let icon;
    switch (data[0]) {
      case 'boo':
        icon = 'box'
        break;
      case 'akt':
        icon = 'camera'
        break;
      default:
        icon = 'home'
    }

    return <> <i className={'pi pi-' + icon}/> {data[1]} </>

  }

  const updateFacility = (facility) => {

    axios.post(`/allyourz/update-facility`, {entityId, facility, type}).then(res => {
      updatePageData(res)
      setEditFacility(false)
    })

  }

  const cancelSaveButts = () => {
    return <div className="col-12">
      <Button
        disabled={!pageTreeData.length}
        label="Opslaan"
        onClick={() => {
          save(entityId, type)
        }}
      />
      <Button
        style={{float: 'right'}}
        label="Cancel"
        severity="warning"
        onClick={() => {
          setAddFacilities(false)
          setPageTreeData([])
        }}
      />

    </div>
  }

  return <>
    <Toast ref={toast} position="top-left"/>

    {/* DIALOG ADD FACILITIES */}
    <Dialog header="Voeg Faciliteiten Toe"
            position={"top"}
            visible={!!addFacilities}
            style={{width: '70vw'}}
            onShow={() => {
            }}
            onHide={() => setAddFacilities(false)}>
      <>
        <div className="grid">
          {cancelSaveButts()}
          {pageTree.map((e, i) => {
            return <div key={i} className="col-3">
              <div className="bg-gray-200 p-1">{e.label}</div>
              <div className="mt-1">{e.items.map((f, j) => {
                return <div className="facility-hover" style={{padding: '2px'}}>
                  <Checkbox
                    checked={pageTreeData.includes(f.value)}
                    onChange={e => {
                      if (e.checked) {
                        // add
                        setPageTreeData([...pageTreeData, f.value])
                      } else {
                        // remove
                        setPageTreeData([...pageTreeData.filter(e => e !== f.value)])
                      }
                    }}
                    inputId={`cb_${i}_${j}`}
                  />
                  <label className="ml-2 pointer" htmlFor={`cb_${i}_${j}`}>{f.label}</label>
                </div>
              })}</div>
            </div>
          })}
          {cancelSaveButts()}
        </div>
      </>
    </Dialog>
    {/* THIS IS THE DIALOG */}
    <Dialog header={editFacility.name}
            position={"top"}
            visible={!!editFacility}
            style={{width: '30vw'}}
            onShow={() => {
              descriptionRef.current.focus()
            }}
            onHide={() => setEditFacility(false)}>
      <div className="card">
        <div className="grid">
          <div className="col-3">
            <label htmlFor="description" style={{width: '30%'}}>Omschrijving</label>
          </div>
          <div className="col-9">
            <InputTextarea
              id="description"
              rows={5}
              ref={descriptionRef}
              onChange={e => setEditFacility({...editFacility, description: e.target.value})}
              value={editFacility.description || ''}
              style={{width: '100%'}}/>
          </div>
        </div>
        {type === 'administration' && <div className="grid">
          <div className="col-3">
            Set as USP
          </div>
          <div className="col-9">
            <Checkbox
              onChange={(e) => {
                // .. should be but at end when not set @todo fix this when we have the data
                setEditFacility({...editFacility, usp: (e.checked ? (editFacility.usp_backup || 100) : null)})
              }}
              checked={!!editFacility.usp}/>
          </div>
        </div>}
        <div className="field">
          <Button label="Save"
                  className="p-button-success"
                  onClick={() => updateFacility(editFacility)}
          />
        </div>
      </div>
    </Dialog>
    {/* /THIS IS THE DIALOG */}
    <div className="grid facilities">
      <div className="col-3">
        <div className="card mt-4">
          <div className="field">
            <Button className="w100" label="Voeg Faciliteiten Toe" disabled={addFacilities} onClick={() => {
              setAddFacilities(true)
            }}/>
          </div>
        </div>
        {type === 'administration' &&
          <>
            <h3 style={{marginTop: '2rem'}}>USP volgorde</h3>
            <div>
              {usps.map((item, j) => {
                const clone = Object.assign({}, usps)
                return <div key={item.id}>
                  {/* THE NAME */}
                  <div style={{display: 'flex'}}>
                    <Button disabled={j === 0}
                            className="butt"
                            onClick={() => {
                              clone[j].usp = clone[j].usp - 10
                              clone[j - 1].usp = clone[j - 1].usp + 10
                              uspMove(clone)
                            }}
                            icon="pi pi-angle-up"/>
                    <Button disabled={j > usps.length - 2}
                            className="butt"
                            onClick={() => {
                              clone[j].usp = clone[j].usp + 10
                              clone[j + 1].usp = clone[j + 1].usp - 10
                              uspMove(clone)
                            }}
                            icon="pi pi-angle-down"/>
                    <div className="facility-item usp"
                         onClick={() => {
                           // Move in backup for when radio is unchecked and checked back again
                           setEditFacility({...item, usp_backup: item.usp})
                         }}>
                      {item.name}
                    </div>
                  </div>
                  {/* THE DESCRIPTION */}
                  {item.description && <div className="facility-description">
                    {item.description}
                  </div>}
                </div>
              })}
            </div>
          </>}

        {/*---------------------------------------------*/}
        {user.field('sys.copy_facilities').view && <div className="field mt-8">
          <Dropdown
            placeholder="Of kopieer van"
            itemTemplate={(e) => {
              return copyFacilityTemplate(e)
            }}
            valueTemplate={(e) => {
              return copyFacilityTemplate(e)
            }}
            options={copyMapOptions}
            value={copyFrom}
            onChange={(e) => {
              setCopyFrom(e.value)
            }}
          />
          <div className="field mt-3">
            <Button label="Kopieer"
                    className="p-button-success"
                    disabled={!copyFrom}
                    onClick={() => runCopyFrom(copyFrom)}
            />
            <Button label="Cancel"
                    disabled={!copyFrom}
                    className="p-button-secondary"
                    onClick={() => {
                      setCopyFrom(null)
                    }}
                    style={{float: 'right'}}/>
          </div>

        </div>}

        {/*----------------------------------------------------*/}

      </div>

      {/* Result below */}
      <div className="col-9">
        <div className="grid">

          {pageData.map((group, i) => {

            return <div key={i} className="col-4">
              <div style={{fontWeight: 'bold', marginBottom: '0.5rem'}}>
                {group.groupname}
              </div>

              {group.items.map((item, j) => {
                return <div key={item.id}>
                  {/* THE NAME */}
                  <div style={{display: 'flex'}}>
                    <Button className="butt p-button-secondary"
                            onClick={() => {
                              removeFacility(item.id)
                            }}
                            icon="pi pi-times"/>

                    <div className={classNames('facility-item', {'usp': !!item.usp})}
                         onClick={() => {
                           // Move in backup for when radio is unchecked and checked back again
                           setEditFacility({...item, usp_backup: item.usp})
                         }}>
                      {item.name}
                    </div>
                  </div>
                  {/* THE DESCRIPTION */}
                  {item.description && <div className="facility-description">
                    {item.description}
                  </div>}
                </div>
              })}
            </div>
          })}
        </div>

      </div>
    </div>
  </>
}
