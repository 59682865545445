import axios from "axios";
import {saveAs} from 'file-saver';
import {useState} from "react";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Image} from "primereact/image";

export default function BookableWithAvailability() {
  const [loading, setLoading] = useState(false)
  const download = () => {
    setLoading(true)
    axios.get('allyourz/download-bookables-with-availability', {responseType: "blob"}).then(res => {
      saveAs(res.data, 'bookables-met-beschikbaarheid.xls');
      setLoading(false)
    })
  }
  return <Card>
    <div className="grid">
      <div className="col-offset-2 col-2 mt-2 font-bold"></div>
      <div className="col-4">
        <Button
          style={{width: '14rem'}}
          loading={loading}
          label="Download"
          iconPos="right"
          icon={<Image width={'25px'} height={'25px'} src="/media/icons/excel-logo.svg"/>}
          onClick={() => download()}
        />
      </div>
    </div>
  </Card>
}
