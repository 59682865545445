import Menu from "../components/Menu";
import {Fieldset} from "primereact/fieldset";
import {Messages} from "primereact/messages";
import {useEffect, useRef, useState} from "react";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import axios from "axios";

import {Password} from "primereact/password";

export default function PageResetPassword() {

  const messages = useRef(null)

  const [email, setEmail] = useState('')
  const [token, setToken] = useState('')
  const [password, setPassword] = useState('')

  useEffect(()=>{
    const t = document.location.search.split('?')[1].split('&')
    setEmail(t[0].split('=')[1])
    setToken(t[1].split('=')[1])
  },[])

  const submit = () => {
    messages.current.clear()
    axios.post('/auth/reset', {
      email,
      token,
      password
    }).then(res => {
      messages.current.show({
        severity: 'success',
        detail: <>Je wachtwoord is succesvol aangepast<br/><br/>
          Klik <a href="/">hier...</a> om opnieuw in te loggen
        </>,
        closable: false,
        sticky: true,
      });
    }).catch(e => {
      messages.current.show({
        severity: 'error',
        closable: true,
        sticky: true,
        detail: Object.entries(e.response.data.errors).map((e, i) => <div key={i} dangerouslySetInnerHTML={{__html:e[1][0]}}/>),
      });
    })
  }

  return (
    <div className="page login">
      <Menu/>
      <Fieldset legend={<>Reset password for&nbsp;<strong>{email}</strong></>} style={{fontSize: '1.3rem'}}>

        <div className="Login grid">
          <div className="xl:col-4"/>
          <div className="xl:col-4">
            <Card footer={<Button
              label="Submit"
              onClick={() => submit()}
              style={{margin: '-0.5rem .5rem 0.5rem'}}/>}>
              <div className="grid">
                <div className="col-12" style={{padding:'0.5rem', fontSize:'1rem'}}>
                  Het wachtwoord moet hoofletters, kleine letters en cijfers of leestekens bestaan
                </div>

                <div className="col-12">

                  <div className="p-inputgroup">
                    <div className="col-12">
                      <div className="p-inputgroup">
                        <Password value={password}
                                  toggleMask={true}
                                  placeholder="New Password..."
                                  feedback={false}
                                  onChange={(e) => {
                                    setPassword(e.target.value)
                                  }}
                                  style={{width: '100%'}}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <Messages ref={messages}/>
            <div style={{padding:'0.5rem',marginTop:'1rem', fontSize:'0.8rem'}}>
              We testen het door jou opgegeven wachtwoord op sterkte.
              Je kunt een melding krijgen dat we je wachtwoord niet sterk genoeg vinden.
              Geen paniek! Je kunt zo vaak proberen als je wilt.
            </div>

          </div>
          <div className="xl:col-4"/>
        </div>
      </Fieldset>
    </div>
  )

}
