import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {Calendar} from "primereact/calendar";
import {classNames} from "primereact/utils";
import getYmd from "../../services/getYmd";
import {UserContext} from "../../contexts/UserContext";
import DayRate from "./DayRate";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";


export default function Preview({bookable_id, initialViewDate, parentOnViewDateChange, min_persons, max_persons}) {

  const user = useContext(UserContext)

  const [guests, setGuests] = useState(2)
  const [selectGuests, setSelectGuests] = useState([])

  // The frontend Avaiability call
  // Thes have the date as yyyy-mm-dd
  const [serverAvailability, setServerAvailability] = useState([])
  const [workAvailability, setWorkAvailability] = useState([])

  const [ux, setUx] = useState('get_start_date')

  // This one gets filled when you click your first date (yyyy-mm-dd)
  const [stayUntil, setStayUntil] = useState([])

  // For the Calendar
  const [selectedDates, setSelectedDates] = useState([])
  const [disabledDates, setDisabledDates] = useState(null)
  const [maxDate, setMaxDate] = useState(new Date())

  // For reloading
  const [reload, setReload] = useState(false)

  // When we select a range set the pricing
  const [pricing, setPricing] = useState(null)

  // Got from parent
  const [viewDate, setViewDate] = useState(initialViewDate)

  useEffect(() => {
    const arr = []
    for (let i = min_persons; i <= max_persons; i++) {
      arr.push({label: i, value: i})
    }
    setSelectGuests(arr)
  }, [min_persons,max_persons])

  /**
   * Here the data is loaded
   */
  useEffect(() => {
    let a = [], dis = [], m = '2000-01-01'

    axios.post('/v1/booking/availability', {bookable_id}).then(res => {
      res.data.forEach(e => {
        a[e.from] = e.till
        const t = e.till[e.till.length - 1]
        if (t > m) {
          m = t
        }
      })

      /**
       * Disable the non used dates
       */
      let loopDate = new Date(m)
      loopDate.setDate(loopDate.getDate() + 1)
      for (let d = new Date(); d <= loopDate; d.setDate(d.getDate() + 1)) {
        if (!a[getYmd(d)]) {
          dis.push(new Date(d.getTime()))
        }
      }

      /**
       * Now set them states
       */
      setDisabledDates(dis)
      setServerAvailability(a)
      setWorkAvailability(a)
      setMaxDate(new Date(m))

    })
  }, [bookable_id, reload])

  /**
   * Here the pricing call is made
   */
  const doPricing = (checkIn, checkOut) => {
    axios.post('/v1/booking/price', {
      locale: user.language,
      bookable_id,
      guests,
      quantity: 1,
      checkIn,
      checkOut,
      pets: 0,
      options: []
    }).then(res => {
      setPricing(res.data || null)
    })
  }

  const reset = () => {

    // prevents flickering of dates
    const d = new Date()
    d.setDate(d.getDate() - 1)
    setMaxDate(d)

    // This is the real reset part
    setSelectedDates([])
    setDisabledDates([])
    setStayUntil([])
    setPricing(null)
    setReload(val => !val) // triggers a reload
    setUx('get_start_date')

  }
  /**
   * Handles the calendar UX
   */
  const onDateChanged = e => {

    let loopDate, dis
    switch (ux) {
      case 'next_click_will_reset':
        reset()
        break
      case 'get_end_date':
        /**
         * Here the end date is clicked
         */
        const start = e.value[0], end = e.value[1], sd = []
        dis = []
        loopDate = maxDate
        loopDate.setDate(loopDate.getDate() + 1)

        for (let d = new Date(); d <= loopDate; d.setDate(d.getDate() + 1)) {
          if (getYmd(d) >= getYmd(start) && getYmd(d) <= getYmd(end)) {
            sd.push(new Date(d.getTime()))
          } else {
            dis.push(new Date(d.getTime()))
          }
        }

        doPricing(getYmd(start), getYmd(end))

        setDisabledDates([])
        setStayUntil([])
        setSelectedDates(sd)
        setDisabledDates(dis)
        setUx('next_click_will_reset')
        break;
      case 'get_start_date':
        let sa = serverAvailability[getYmd(e.value[0])]
        loopDate = maxDate
        loopDate.setDate(loopDate.getDate() + 1)
        const su = []
        dis = []
        // Loop for the disabled dates
        for (let d = new Date(); d <= loopDate; d.setDate(d.getDate() + 1)) {
          if (
            getYmd(d) !== getYmd(e.value[0]) &&
            !sa.includes(getYmd(d))
          ) {
            dis.push(new Date(d.getTime()))
          } else {
            if (getYmd(d) !== getYmd(e.value[0])) {
              su[getYmd(d)] = true
            }
          }
        }

        setSelectedDates(e.value)
        setWorkAvailability([])
        setStayUntil(su)
        setDisabledDates(dis)
        setUx('get_end_date')
        break
      default:
    }
  }

  /**
   * The template
   */
  const dateTemplate = (d) => {
    return <div className={classNames('datefield', {
        avail: workAvailability[getYmd(d)],
        'stay-til': stayUntil[getYmd(d)]
      }
    )}>
      <div className="day">{d.day}</div>
    </div>
  }

  useEffect(() => {
    if (selectedDates.length > 1) {
      doPricing(
        getYmd(selectedDates[0]),
        getYmd(selectedDates[selectedDates.length - 1])
      )
    }
    // eslint-disable-next-line
  }, [guests])

  return <>
    <div className="col-8">
      <Calendar value={selectedDates}
                selectionMode="multiple"
                inline={true}
                viewDate={viewDate}
                onViewDateChange={e => {
                  setViewDate(e.value)
                  if (parentOnViewDateChange) {
                    parentOnViewDateChange(e.value)  // change the parent
                  }
                }}
                disabledDates={disabledDates}
                minDate={new Date()}
                maxDate={maxDate}
                onChange={onDateChanged}
                dateTemplate={dateTemplate}
                numberOfMonths={2}>
      </Calendar>
    </div>
    <div className="col-4">
      <div className="grid mt-4 mb-4">
        <div className="col-3">
          <div style={{border: 'solid 0px', paddingTop: '0.5rem'}}>Aantal gasten</div>
        </div>
        <div className="col-2">
          <Dropdown
            value={guests}
            options={selectGuests}
            onChange={(e) => setGuests(e.value)}/>
        </div>
        <div className="col-7">
          <Button label="Clear"
                  onClick={e => reset()}
                  style={{float: 'right'}}
                  className="p-button-warning"/>
        </div>
      </div>
      <div className="grid mt-4 mb-4">
        {pricing &&
          <div className="col-12" style={{border: 'solid 1px #c8c8c8', borderRadius: '3px', padding: '1rem'}}>
            <DayRate pricing={pricing}/>
          </div>}
      </div>
    </div>
  </>
}
