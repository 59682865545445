import {useEffect, useState} from "react";
import axios from "axios";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Rating} from 'react-simple-star-rating'
import {Avatar} from 'primereact/avatar'
import shortenText from "../../services/shortenText";
import {Dialog} from "primereact/dialog";
import Review from "./Review";

const map = {
  'new': {icon: 'pi pi-exclamation-triangle', color: 'var(--p-warning)'},
  'accepted': {icon: 'pi pi-check', color: 'var(--p-success)'},
  'rejected': {icon: 'pi pi-times', color: 'var(--p-summer1)'},
}

export default function Reviews({entrepreneur_id, administration_id}) {

  const [data, setData] = useState([])
  const [review, setReview] = useState({})
  const [reload, setReload] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [popVis, setPopVis] = useState(false)
  const [lazy, setLazy] = useState({
    first: 0,
    rows: 10,
    page: 1
  });

  useEffect(() => {
    const loadLazyData = () => {
      if (administration_id) {
        axios.post('/allyourz/get-reviews', {type: 'administration', id: administration_id, lazy}).then(res => {
          setData(res.data.result)
          setTotalRecords(res.data.count)
        })
      } else if (entrepreneur_id) {
        axios.post('/allyourz/get-reviews', {type: 'entrepreneur', id: entrepreneur_id, lazy}).then(res => {
          setData(res.data.result)
          setTotalRecords(res.data.count)
        })
      } else {
        axios.post('/allyourz/get-reviews', {type: 'all', lazy}).then(res => {
          setData(res.data.result)
          setTotalRecords(res.data.count)
        })
      }
    }
    loadLazyData()
  }, [lazy, reload, administration_id, entrepreneur_id])

  const getDialogDataAndOpen = (review) => {
    setReview(review)
    setPopVis(true)
  }

  return <>
    {/*DIALOG*/}
    <Dialog header={review.administration_name + ' [' + review.language?.toUpperCase() + ']'}
            position="top"
            visible={popVis}
            style={{width: '60rem'}}
            onHide={() => {
              setReview({})
              setPopVis(false)
            }}>
      <Review
        review={review}
        setReview={setReview}
        onUpdate={() => {
          setPopVis(false)
          setReload(!reload)
        }
        }/>
    </Dialog>
    {/*/DIALOG*/}

    <DataTable value={data}
               paginator
               lazy
               first={lazy.first}
               rows={lazy.rows}
               totalRecords={totalRecords}
               dataKey="id"
               rowClassName="pointer"
               onPage={e => setLazy(e)}
               onRowClick={e => getDialogDataAndOpen(e.data)}
               rowHover={true}>
      <Column style={{width: '5rem'}}
              className="text-center"
              field="status"
              header="Status"
              body={e => {
                return <Avatar
                  shape="circle"
                  icon={map[e.status].icon}
                  style={{backgroundColor: map[e.status].color}}
                />
              }}
      />
      <Column
        style={{width: '10rem'}}
        field="score"
        header="Score"
        body={(row) => {
          const massaged = Math.round((
            row.score_facilities +
            row.score_hygiene +
            row.score_comfort +
            row.score_staff +
            row.score_price +
            row.score_location
          ) / 6) / 2
          return <div className="text-center">
            <Rating
              readonly
              initialValue={massaged}
              allowFraction
              size={30}/>
          </div>
        }}/>
      <Column style={{width: '45rem'}} field="comment" header={`Original Review`} body={row => {
        return <div>
          <span className="font-bold">[{row.language.toUpperCase()}] {row['oneliner_' + row.language]}</span>
          <br/><span>{shortenText(row['review_' + row.language], 90, true)}</span>
        </div>
      }}/>
      <Column field="administration_name" header="Administratie"/>
      <Column style={{width: '9.8rem'}} className="text-center" field="created_at" header="Geplaatst"/>

    </DataTable>


  </>

}
