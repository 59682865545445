import money from "../../services/money";

export default function DayRate({pricing}) {

  const ci = new Date(pricing.checkIn)
  const co = new Date(pricing.checkOut)

  const diffDays = Math.ceil(Math.abs(ci - co) / (1000 * 60 * 60 * 24));

  return <table style={{width: '100%', fontSize: '0.9rem', marginTop: '0.5rem'}}>
    <tbody>
    <tr>
      <td className="text-left">
        <span className="text-xs">{pricing.checkIn}</span>&nbsp;/&nbsp;
        <strong>{pricing.guests}</strong> gast{pricing.guests > 1 ? 'en' : ''}
        &nbsp;/ <strong>{diffDays}</strong> nacht{diffDays > 1 ? 'en' : ''}
      </td>
      <td className="text-right">
        Dag van de week <strong>{new Date(pricing.checkIn).getDay()}</strong>
      </td>
    </tr>
    <tr>
      <td colSpan="2">&nbsp;</td>
    </tr>
    <tr>
      <th className="text-left">Stay</th>
      <td className="text-right">{money.toEuro(pricing.totals.stay.cost, true)}</td>
    </tr>
    <tr>
      <th className="text-left">Total Fees</th>
      <td className="text-right">{money.toEuro(pricing.totals.sumMandatoryFees, true)}</td>
    </tr>
    <tr>
      <td colSpan="2">
        <hr/>
      </td>
    </tr>
    <tr>
      <th className="text-left">Total</th>
      <td className="text-right">{money.toEuro(pricing.totals.stay.cost + pricing.totals.sumMandatoryFees, true)}</td>
    </tr>
    <tr>
      <td colSpan="2">&nbsp;</td>
    </tr>
    <tr>
      <td colSpan="2" className="font-bold">Fees</td>
    </tr>

    {!pricing.mandatoryFees.length && <tr>
      <td colSpan="2">Geen verplichte fees</td>
    </tr>}

    {pricing.mandatoryFees.map((f, i) => {
      return <tr key={i}>
        <td className="text-left">{f.name}:</td>
        <td className="text-right">{money.toEuro(f.price.total, true)}</td>
      </tr>
    })}
    </tbody>
  </table>

}
