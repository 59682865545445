import {Calendar} from "primereact/calendar";
import {useContext, useEffect, useRef, useState} from "react";
import getYmd from "../../services/getYmd";
import axios from "axios";
import money from "../../services/money";
import {Button} from "primereact/button";
import {classNames} from "primereact/utils";
import {UserContext} from "../../contexts/UserContext";
import {Toast} from "primereact/toast";
import jsonPrettyPrint from "../../services/jsonPrettyPrint";
import {Dialog} from "primereact/dialog";
import DayRate from "./DayRate";
import Preview from "./Preview";
import ucfirst from "../../services/ucfirst";

export default function SiteAvailability({bookable_id, src, max_persons, min_persons}) {
  const toast = useRef()
  const context = useContext(UserContext)
  const [showDialog, setShowDialog] = useState(false)
  const [selectedDates, setSelectedDates] = useState([])
  const [dataAvailable, setDataAvailable] = useState({})
  const [matrix, setMatrix] = useState({})
  const [spinning, setSpinning] = useState(false)
  const [pricing, setPricing] = useState({})
  const [quote, setQuote] = useState({})
  const [fees, setFees] = useState({})
  const [nextpaxPricing, setNextpaxPricing] = useState({})
  const [loadingQuote, setLoadingQuote] = useState(false)
  const [viewDate, setViewDate] = useState(new Date())
  const [preview, setPreview] = useState(false)

  function handleData(res) {
    const dbAvail = {}
    res.data.forEach(e => {
      dbAvail[e.date] = e
    })
    setDataAvailable(dbAvail)
  }

  useEffect(() => {
      axios.get('/allyourz/bookable-get-rates-by-id', {params: {id: bookable_id}}).then(res => {
        handleData(res)
      })
    },
    [bookable_id]
  )

  /**
   * PRICING DETAIL CALL
   */
  const getPricingDetail = (day, guests, e) => {

    // Calc checkin and checkout
    const checkIn = getYmd(selectedDates[0]);
    const checkOutDate = new Date(selectedDates[0])
    checkOutDate.setDate(checkOutDate.getDate() + parseInt(day));
    const checkOut = getYmd(checkOutDate);

    const postData = {
      locale: context.language,
      bookable_id: bookable_id,
      guests: guests,
      pets: 0,
      checkIn: checkIn,
      checkOut: checkOut,
      options: []
    }

    axios.post('/v1/booking/price', postData).then(res => {
      setPricing(res.data)
      setQuote({})
      setShowDialog(true)
    })
  }


  const doMatrix = () => {
    /**
     * ***************************************************************************
     */
    const days = matrix.days
    const persons = matrix.persons

    return <table className="rate-table">
      <tbody>
      {!days
        ? <tr>
          <td>Geen beschikbaarheid</td>
        </tr>
        : <>
          <tr>
            <th className="dayno">Nacht</th>
            {persons.map((p, i) => <th key={i} className="ppp">pers. {p}</th>)}
          </tr>
          {Object.keys(days).map(function (d, i) {
            return days[d] &&
              <tr key={i}>
                <td className="font-bold">{d}</td>
                {persons.map((p, i) => {
                  return <td key={i} className="price"
                             onClick={(e) => {
                               getPricingDetail(d, p, e)
                             }}>
                    {money.toEuro(days[d].rate[p] + days[d].fees[p], true)}
                  </td>
                })}
              </tr>
          })}
        </>
      }
      </tbody>
    </table>


  }

  useEffect(() => {
    const date = getYmd(selectedDates[0])
    if (date) {
      axios.get('/allyourz/bookable-pricing-by-id', {
        params: {
          id: bookable_id,
          date
        }
      }).then(res => setMatrix(res.data))
    }
  }, [selectedDates, bookable_id])

  /**
   *
   */
  const dateTemplate = (d) => {

    if (!d.selectable) {
      // Not in this month
      return <div className="datefield">
        <div className="day">{d.day}</div>
      </div>
    }
    const day = dataAvailable[getYmd(d)];

    const q = day?.quantity || null
    const arr = day?.arr_allowed ? '↓' : null
    const dep = day?.dep_allowed ? '↑' : null
    return <div className={classNames('datefield', {blocked: day?.blocked})}>

      <div className="sm lt">{arr}{dep}</div>
      <div className="sm rt">{q}</div>
      <div className="sm ml">{day?.min_stay}</div>
      <div className="sm lb">{day?.max_stay}</div>
      <div className="sm rb">{day?.rate && money.toEuro(day?.rate)}</div>
      <div className="day">{d.day}</div>
    </div>
  }

  function recalculatePricing() {
    setSpinning(true)
    axios.get('/allyourz/bookable-recalculate-pricing-by-id', {
      params: {
        id: bookable_id
      }
    }).then(res => {
      setSpinning(false)
      handleData(res)
      toast.current.show({
        life: 5000,
        severity: 'success',
        summary: 'OK',
        detail: 'Synchronisatie gelukt'
      })

    }).catch(e => {
      setSpinning(false)
      toast.current.show({
        life: 5000,
        severity: 'error',
        summary: 'Probleem met de synchronisatie',
        detail: 'Contact allyourz support!'
      })
    })
  }

  /**
   * Direct call to NextPax
   */
  function getQuote(pricing) {
    setLoadingQuote(true)
    axios.post('/allyourz/bookable-postman-quote', {
      bookable_id,
      checkIn: pricing.checkIn,
      checkOut: pricing.checkOut,
      guests: pricing.guests
    }).then(res => {
      setLoadingQuote(false)
      setQuote(res.data.quote)
      setNextpaxPricing(res.data.nextpaxPricing)
      setFees(res.data.fees)
    })
  }

  const getOverlayPricingDetail = () => {

    return pricing.totals ? <>
      {/* Pricing as on site */}

      <DayRate pricing={pricing}/>

      <table style={{width: '100%', fontSize: '0.9rem', marginTop: '0.5rem'}}>
        <tbody>

        {(src === 'nextpax') && <>
          <tr>
            <td colSpan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colSpan="2">
              <Button label="Get NextPax Data"
                      icon={classNames({'pi pi pi-spin pi-spinner': loadingQuote})}
                      disabled={loadingQuote}
                      onClick={() => {
                        getQuote(pricing)
                      }}/>
            </td>
          </tr>
        </>}

        </tbody>
      </table>

      {!!Object.keys(quote).length && <div className="grid">
        <div className="col-4">
          <pre>Quote</pre>
          <pre className="json-pretty-print"
               dangerouslySetInnerHTML={{__html: jsonPrettyPrint(quote)}}>
            </pre>
        </div>
        <div className="col-4">
          <pre>Rate</pre>
          <pre className="json-pretty-print"
               dangerouslySetInnerHTML={{__html: jsonPrettyPrint(nextpaxPricing)}}>
            </pre>
        </div>
        <div className="col-4">
          <pre>Fees</pre>
          <pre className="json-pretty-print"
               dangerouslySetInnerHTML={{__html: jsonPrettyPrint(fees)}}>
            </pre>
        </div>

      </div>}
    </> : null

  }
  const previewOnViewDateChange = (e) => {
    setViewDate(e)
  }

  return <>
    <Toast ref={toast} position="top-left"/>
    <Dialog
      visible={showDialog}
      header={<div className="font-normal">
        Van <strong>{pricing.checkIn}</strong> tot <strong>{pricing.checkOut}</strong>
      </div>}
      style={{width: '1024px'}}
      onHide={() => {
        setShowDialog(false)
      }}>
      {getOverlayPricingDetail()}
    </Dialog>
    <div className="comp availability grid">
      <div className="col-offset-8 col-2">
        {(src === 'nextpax' || src === 'qenner') && !preview &&
          <Button disabled={spinning}
                  icon={classNames({'pi pi pi-spin pi-spinner': spinning})}
                  label={'Update ' + ucfirst(src)}
                  onClick={() => recalculatePricing()}/>}
      </div>
      <div className="col-2">
        <Button
          label={preview ? 'Terug naar ' + src + ' data' : 'Preview'}
          onClick={() => setPreview(v => !v)}
          style={{float: 'right'}}
        />

      </div>
    </div>
    {preview && <div className="comp availability grid">
      <Preview bookable_id={bookable_id}
               initialViewDate={viewDate}
               parentOnViewDateChange={previewOnViewDateChange}
               min_persons={min_persons}
               max_persons={max_persons}
      />
    </div>}
    {!preview &&
      <div className="comp availability grid">
        <div className="col-8">
          <Calendar value={selectedDates}
                    selectionMode="multiple"
                    viewDate={viewDate}
                    onViewDateChange={e => setViewDate(e.value)}
                    inline={true}
                    onSelect={d => setSelectedDates([d.value])}
                    dateTemplate={dateTemplate}
                    numberOfMonths={2}>
          </Calendar>
        </div>
        <div className="col-4">
          {doMatrix()}

        </div>
      </div>}
  </>
}
