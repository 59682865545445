import axios from "axios";
import {useEffect, useState} from "react";
import {Card} from "primereact/card";
import {TabPanel, TabView} from "primereact/tabview";
import {Checkbox} from "primereact/checkbox";
import {Button} from "primereact/button";
import {confirmDialog} from 'primereact/confirmdialog';


const path = process.env.REACT_APP_MEDIA

export default function MediaPicker({administration_id, onSave}) {

  const [items, setItems] = useState({})
  const [used, setUsed] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    setUsed([])
    axios.get('/allyourz/' + administration_id + '/media-by-administrationid').then(res => {
      setItems(res.data)
    })
  }, [administration_id])

  const destroy = (media_id) => {
    axios.post('/allyourz/' + administration_id + '/media-destroy', {id: media_id}).then(res => {
      setItems(res.data)
    })
  }

  const save = () => {
    onSave(used)
    setUsed([])
  }

  const check = (index) => {
    if (!used.includes(index)) {
      setUsed([...used, index])
    } else {
      setUsed(used.filter(e => e !== index))
    }
  }

  const imageCards = (media_ids, type) => {
    return media_ids.map((media_id, i) => <div key={i} className="col-3">
      <Card>
        <div className="grid">
          {/* THE IMAGE */}
          <div className="col-12" onClick={() => check(media_id)}>
            <img src={`${path}/${administration_id.toLowerCase()}/thumbs/${media_id}.jpg`}
                 loading="lazy"
                 style={{cursor: 'pointer', width: '100%'}}
                 alt=""/>
          </div>
          {/* THE CHECKBOX */}
          <div className="col-12">
            <Checkbox inputId={`cb${media_id}`}
                      value={media_id}
                      checked={used.includes(media_id)}
                      onChange={(e) => {
                        check(e.value)
                      }}/>
            {/* THE DELETE BUTT */}
            {type === 'unused' && <Button
              onClick={
                () => {
                  confirmDialog({
                    header: 'Afbeelding permanent verwijderen',
                    message: <>Je staat op het punt een afbeelding permanent te verwijderen,<br/><br/>Weet je dit zeker?</>,
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                      destroy(media_id)
                    }
                  });
                }}
              icon="pi pi-times"
              title="Permanent verwijderen"
              style={{float: 'right', height: '1.25rem', width: '1.4rem'}}
              className="p-button-danger"/>}
          </div>
        </div>
      </Card>
    </div>)
  }

  const getBookablesMedia = (data) => {

    return data.map((b, i) => {
      return <div key={i}>
        <div style={{
          border: 'solid 1px #c8c8c8',
          backgroundColor: '#f4f4f4',
          padding: '1rem',
          marginBottom: '0.25rem',
          borderRadius: ' 3px'
        }}>{b.name}</div>
        <div className="grid">
          {imageCards(b.bids)}
        </div>
      </div>
    })


  }

  const getMedia = (type) => {

    switch (type) {
      case 'admin':
      case 'quote':
      case 'unused':
        return <div className="grid">{imageCards(items[type], type)}</div>
      case 'bookable':
        return getBookablesMedia(items[type])
      default:
    }
  }

  if (Object.keys(items).length === 0) return null

  return <>
    <Button label={'OK' + (used.length ? ` (${used.length})` : '')}
            className="p-button-success"
            disabled={!used.length}
            onClick={() => {
              save();
            }}
            style={{float: 'right', zIndex: 1}}/>
    <TabView activeIndex={activeIndex}
             onTabChange={(e) => {
               setUsed([])
               setActiveIndex(e.index)
             }}>
      <TabPanel header="Administratie">
        {getMedia('admin')}
      </TabPanel>
      <TabPanel header="Bookables">

        {getMedia('bookable')}
      </TabPanel>
      <TabPanel header="Quote">
        {getMedia('quote')}
      </TabPanel>
      <TabPanel header="Niet gebruikt">
        {getMedia('unused')}
      </TabPanel>
    </TabView>
  </>
}
