import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Accordion, AccordionTab} from 'primereact/accordion';
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {confirmDialog} from "primereact/confirmdialog";
import {UserContext} from "../contexts/UserContext";

export default function Faq({administration_id}) {
  const toast = useRef();
  const context = useContext(UserContext)
  const [faqs, setFaqs] = useState([])
  const [activeIndex, setActiveIndex] = useState(null)
  const [activeFaq, setActiveFaq] = useState({})
  const [dirty, setDirty] = useState(false)
  const [saving, setSaving] = useState(false)
  useEffect(() => {
    axios.get(`/allyourz/faqs-by-administration-id`, {params: {administration_id}}).then(res => {
      if (activeIndex !== null) {
        setActiveFaq(res.data[activeIndex])
      }
      setFaqs(res.data)
    })
    // eslint-disable-next-line -- should be fine I guess, although the user might click a tab
  }, [administration_id, context.language])

  const save = (data) => {

    axios.post(`/allyourz/faqs-by-administration-id`, {administration_id, faqs: data}).then(res => {
      setFaqs(res.data)
      setDirty(false)
      setSaving(false)
      toast.current.show({
        life: 2000,
        severity: 'success',
        summary: "Alle FAQ's",
        detail: 'zijn succesvol bijgewerkt!'
      });
    })
  }

  const remove = (id) => {
    confirmDialog({
      header: 'Faq verwijderen?',
      message: <>De FAQ zal permanent verwijderd worden.<br/><br/>Weet je dit zeker?</>,
      acceptLabel: 'Ja',
      rejectLabel: 'Nee',
      accept: () => {
        axios.delete(`/allyourz/faqs-by-administration-id`, {params: {administration_id, id}}).then(res => {
          setFaqs(res.data)
          setActiveIndex(null)
          setActiveFaq({})
          setDirty(false)
        })
      }
    })
  }

  const getTab = (faq, index) => {

    return <AccordionTab
      key={index}

      header={<div style={{width: '100%'}}>
        <Button className="p-button-warning"
                icon="pi pi-times"
                onClick={(e) => {
                  e.stopPropagation()
                  remove(faq.id)
                }}
                style={{width: '1rem', height: '1rem',}}
        /> {faq.question}</div>}>

      {activeIndex !== null && <>
        <div className="field grid" style={{marginTop:'0.5rem'}}>
          <div className="col-2">
            <label htmlFor="question" className="col-fixed">Vraag</label>
          </div>
          <div className="col-10">
            <InputText id="question"
                       value={activeFaq.question}
                       onChange={e => {
                         setDirty(true)
                         setActiveFaq({...activeFaq, question: e.target.value})
                       }}/>
          </div>
        </div>
        <div className="field grid">
          <div className="col-2">
            <label htmlFor="answer" className="col-fixed">Antwoord</label>
          </div>
          <div className="col-10">
            <InputTextarea value={activeFaq.answer || ''}
                           rows={5}
                           onChange={e => {
                             setDirty(true)
                             setActiveFaq({...activeFaq, answer: e.target.value})
                           }}/>
          </div>
        </div>
      </>}
    </AccordionTab>
  }


  return <>
    <Toast ref={toast} position="top-left"/>
    <Accordion activeIndex={activeIndex} onTabChange={e => {
      if (activeIndex !== null) {
        const clone = [...faqs]
        clone[activeIndex] = activeFaq
        setFaqs(clone)
      }
      setActiveFaq(faqs[e.index] || {})
      setActiveIndex(e.index)
    }
    }>
      {faqs.map((faq, index) => {
        return getTab(faq, index)
      })}
    </Accordion>


    <div className="grid" style={{marginTop: '2rem'}}>
      <div className="col-2">
        <Button label="Nieuwe FAQ"
                onClick={() => {
                  const clone = [...faqs]
                  const newFaq = {id: null, question: 'Nieuwe FAQ ' + (faqs.length + 1), answer: null}
                  clone.push(newFaq)
                  setFaqs(clone)
                  setActiveFaq(newFaq)
                  setActiveIndex(clone.length - 1)
                }}
                className="p-button-warning"/>
      </div>
      <div className="col-2">
        <Button label="Save"
                onClick={() => {
                  setSaving(true)
                  const clone = [...faqs]
                  clone[activeIndex] = activeFaq
                  save(clone)
                }}
                disabled={saving || !(dirty || faqs.filter(e => !e.id).length)}
        />
      </div>
    </div>

  </>
}
